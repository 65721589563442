import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Flex,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  Image,
  Button,
  Stack,
} from "@chakra-ui/react";
import CloseIcon from "../../../assets/images/employee/closeIcon.svg";
import {
  InputControl,
  SelectControl,
} from "../../../components/form/formControls";
import { useForm } from "react-hook-form";
import { useCategories } from "../../../hooks/useCategories";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

const schema = Yup.object().shape({
  commissionRate: Yup.number()
    .required("Vui lòng nhập số")
    .transform((value, originalValue) => {
      if (typeof originalValue === "string" && originalValue.trim() === "") {
        return null;
      }
      return Number(originalValue);
    })
    .typeError("Vui lòng nhập số")
    .min(0, "Tỉ lệ phải lớn hơn hoặc bằng 0")
    .max(100, "Tỉ lệ phải nhỏ hơn hoặc bằng 100"),
  jobPositionId: Yup.string().required("Vui lòng chọn chức vụ"),
  businessCategoryId: Yup.string().required("Vui lòng chọn phân loại"),
  // saleBlockId: Yup.string().required("Vui lòng chọn khối"),
  // saleAreaId: Yup.string().required("Vui lòng chọn vùng"),
});

const FormJobTitleEdit = ({
  isOpen,
  onClose,
  dataEdit,
  defaultValues,
  onSubmit,
}) => {
  const {
    handleSubmit,
    setValue,
    watch,
    reset,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const { constantData } = useCategories(watch("saleAreaId"));

  const formatEntriesForSelect = (entries) =>
    Object.entries(entries || {}).map(([key, value]) => ({
      value: key,
      label: value,
    }));

  useEffect(() => {
    if (isOpen) {
      reset(defaultValues);
    }
  }, [isOpen, defaultValues, reset]);

  const jobPositionSupportBussines = useMemo(
    () => formatEntriesForSelect(constantData?.jobPositionSupportBussines),
    [constantData?.jobPositionSupportBussines]
  );
  const jobPositionBussines = useMemo(
    () => formatEntriesForSelect(constantData?.jobPositionBussines),
    [constantData?.jobPositionBussines]
  );
  const businessCategorys = useMemo(
    () => formatEntriesForSelect(constantData?.businessCategorys),
    [constantData?.businessCategorys]
  );
  const saleAreas = useMemo(
    () => formatEntriesForSelect(constantData?.saleAreas),
    [constantData?.saleAreas]
  );
  const saleBlocks = useMemo(
    () => formatEntriesForSelect(constantData?.saleBlocks),
    [constantData?.saleBlocks]
  );
  const supportDepartments = useMemo(
    () => formatEntriesForSelect(constantData?.supportDepartments),
    [constantData?.supportDepartments]
  );

  const [focusStates, setFocusStates] = useState({});

  const handleFocus = (field) => {
    setFocusStates((prev) => ({ ...prev, [field]: true }));
  };

  const handleBlur = (field) => {
    setFocusStates((prev) => ({ ...prev, [field]: false }));
  };

  const onchangeSaleAreas = (option) => {
    setValue("saleAreaId", option);
  };
  const onchangeSaleBlocks = (option) => {
    setValue("saleBlockId", option);
  };
  const onchangeJobPosition = (option) => {
    setValue("jobPositionId", option);
  };
  const onchangeBusinessCategorys = (option) => {
    setValue("businessCategoryId", option);
  };
  const onchangeSupportDepartment = (option) => {
    setValue("supportDepartmentId", option);
  };

  const submitHandler = (data) => {
    onSubmit(data);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        backgroundColor="var(--color-main)"
        borderRadius={"16px"}
        h={"86.5vh"}
        maxH={"625px"}
        w={"100%"}
        maxW={"625px"}
        overflowY={"scroll"}
        sx={{
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-thumb": {
            width: "4px",
            borderRadius: "50px",
            background: "var(--color-background)",
          },
          "&::-moz-scrollbar": {
            width: "4px",
          },
          "&::-moz-scrollbar-thumb": {
            width: "4px",
            borderRadius: "50px",
            background: "var(--color-background)",
          },
        }}
      >
        <form onSubmit={handleSubmit(submitHandler)}>
          <Flex flexDirection={"column"} h={"100%"}>
            <Flex
              justifyContent={"space-between"}
              alignItems={"center"}
              gap={"10px"}
              padding={"25px 24px"}
              borderBottom={"1px solid var(--bg-line-employee)"}
            >
              <Text
                color="var(--color-header-employee)"
                fontSize={"18px"}
                fontWeight={"600"}
              >
                Chỉnh sửa xác nhận công và chức danh
              </Text>
              <Box cursor={"pointer"} onClick={onClose}>
                <Image src={CloseIcon} />
              </Box>
            </Flex>
            <Flex flex={"1"} p={"24px"} flexDirection={"column"} h={"100%"}>
              <Flex alignItems={"center"} justifyContent={"space-between"}>
                <Text
                  color={"var(--color-header-employee)"}
                  fontWeight={"600"}
                  fontSize={"16px"}
                >
                  {dataEdit?.fullName || ""}
                </Text>
                <Flex alignItems={"center"} gap={"12px"}>
                  <Text
                    color={"var(--color-label-employee)"}
                    fontWeight={"400"}
                    fontSize={"16px"}
                  >
                    Tổng số công:
                  </Text>
                  <Text
                    color={"var(--color-option-employee-hover)"}
                    fontWeight={"600"}
                    fontSize={"16px"}
                  >
                    {dataEdit?.workingDays || ""}
                  </Text>
                </Flex>
              </Flex>
              <Box flex={1} w={"100%"} maxW={"545px"} minH={"375px"} m={"24px auto"}>
                <Flex
                  justifyContent={"space-between"}
                  alignItems={"flex-start"}
                  gap={"27px"}
                  mb={"12px"}
                >
                  <SelectControl
                    label="Phân loại"
                    dataOption={businessCategorys}
                    selectedMain={watch("businessCategoryId")}
                    placeholder="Chọn phân loại"
                    isSearchable={true}
                    onChange={onchangeBusinessCategorys}
                    onFocus={() => handleFocus("businessCategoryId")}
                    onBlur={() => handleBlur("businessCategoryId")}
                    state={focusStates["businessCategoryId"]}
                    errorMessage={errors.businessCategoryId}
                  />
                  <SelectControl
                    label="Chức vụ"
                    dataOption={
                      dataEdit?.department
                        ? jobPositionSupportBussines
                        : jobPositionBussines
                    }
                    isSearchable={true}
                    selectedMain={watch("jobPositionId")}
                    placeholder="Chọn chức vụ"
                    onChange={onchangeJobPosition}
                    onFocus={() => handleFocus("jobPositionId")}
                    onBlur={() => handleBlur("jobPositionId")}
                    state={focusStates["jobPositionId"]}
                    errorMessage={errors.jobPositionId}
                  />
                </Flex>
                <Flex
                  justifyContent={"space-between"}
                  alignItems={"flex-start"}
                  gap={"27px"}
                  mb={"12px"}
                >
                  <InputControl
                    label="Tỷ lệ hoa hồng"
                    placeholder="Nhập tỷ lệ hoa hồng"
                    errors={errors.commissionRate}
                    register={register("commissionRate")}
                    onFocus={() => handleFocus("commissionRate")}
                    onBlur={() => handleBlur("commissionRate")}
                    state={focusStates["commissionRate"]}
                    mwidth={"100%"}
                    errorMessage={errors.commissionRate}
                  />
                  {dataEdit?.department===0 && (
                    <SelectControl
                      label="Phòng ban"
                      dataOption={supportDepartments}
                      selectedMain={watch("supportDepartmentId")}
                      placeholder="Chọn phòng ban"
                      isSearchable={true}
                      onChange={onchangeSupportDepartment}
                      onFocus={() => handleFocus("supportDepartmentId")}
                      onBlur={() => handleBlur("supportDepartmentId")}
                      state={focusStates["supportDepartmentId"]}
                      registerProps={register("supportDepartmentId")}
                      errorMessage={errors.supportDepartmentId}
                    />
                  )}
                </Flex>
                {dataEdit?.department===1 && (
                  <Flex
                    justifyContent={"space-between"}
                    alignItems={"flex-start"}
                    gap={"27px"}
                    mb={"12px"}
                  >
                    <SelectControl
                      label="Vùng"
                      dataOption={saleAreas}
                      selectedMain={watch("saleAreaId")}
                      placeholder="Chọn vùng"
                      isSearchable={true}
                      onChange={onchangeSaleAreas}
                      onFocus={() => handleFocus("saleAreaId")}
                      onBlur={() => handleBlur("saleAreaId")}
                      state={focusStates["saleAreaId"]}
                      registerProps={register("saleAreaId")}
                      errorMessage={errors.saleAreaId}
                    />
                    <SelectControl
                      label="Khối"
                      selectedMain={watch("saleBlockId")}
                      dataOption={saleBlocks}
                      isSearchable={true}
                      placeholder="Chọn khối"
                      errorMessage={errors.saleBlockId}
                      onChange={onchangeSaleBlocks}
                      onFocus={() => handleFocus("saleBlockId")}
                      onBlur={() => handleBlur("saleBlockId")}
                      state={focusStates["saleBlockId"]}
                      registerProps={register("saleBlockId")}
                    />
                  </Flex>
                )}
              </Box>
              <Stack
                direction="row"
                spacing={4}
                align="center"
                justify={"flex-end"}
              >
                <Button
                  fontSize={"15px"}
                  fontWeight={"600"}
                  bg="var(--color-main)"
                  w={"112px"}
                  h={"44px"}
                  border={"1px solid var(--color-option-employee-hover)"}
                  color={"var(--color-option-employee-hover)"}
                  _hover={{
                    filter: "brightness(90%)",
                  }}
                  onClick={onClose}
                >
                  Hủy
                </Button>
                <Button
                  type="submit"
                  fontSize={"15px"}
                  fontWeight={"600"}
                  color={"var(--color-main)"}
                  bg="var(--linear-gradient-employee)"
                  w={"112px"}
                  h={"44px"}
                  _hover={{
                    filter: "brightness(90%)",
                  }}
                >
                  Xong
                </Button>
              </Stack>
            </Flex>
          </Flex>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default FormJobTitleEdit;
