import {
  InputRightElement,
  Box,
  Flex,
  Input,
  Text,
  InputGroup,
  FormControl,
  FormLabel,
  RadioGroup,
  Stack,
  Radio,
  Menu,
  MenuButton,
  Button,
  MenuList,
  Spinner,
  MenuItem,
  Checkbox,
} from "@chakra-ui/react";
import React, { forwardRef, useEffect, useMemo, useState } from "react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import useDebounce from "../../hooks/useDebounce";
import { CiSearch } from "react-icons/ci";
import { IoCheckmarkOutline } from "react-icons/io5";

export const InputControl = forwardRef(
  (
    {
      label,
      placeholder,
      type = "text",
      state,
      value,
      valueMain,
      onChange,
      onFocus,
      onBlur,
      errors,
      register,
      defaultValues,
      mwidth,
      bdTop,
      colorIp,
      disabled,
      readonly = false,
      step,
    },
    ref
  ) => {
    const handleChange = (e) => {
      if (register && register.onChange) {
        register.onChange(e);
      }
      if (onChange) {
        onChange(e);
      }
    };

    return (
      <FormControl
        flex={"1"}
        maxW={mwidth ? "100%" : "259px"}
        display={"flex"}
        flexDirection={"column"}
        gap={bdTop ? "8px" : "0px"}
        h={bdTop ? "auto" : "max-content"}
      >
        <FormLabel
          lineHeight={"15px"}
          m="0"
          fontSize={{ base: "12px" }}
          mb={bdTop ? "0" : "6px"}
          fontWeight={400}
          color={
            state
              ? "var(--color-option-employee-hover)"
              : bdTop
              ? "var(--color-black-label)"
              : "var(--color-label-employee)"
          }
        >
          {label}
        </FormLabel>

        <Input
          ref={ref} // Gán ref vào Input nếu cần
          h={bdTop ? "max-content" : "auto"}
          fontSize={{ base: "14px" }}
          lineHeight={"17px"}
          fontWeight={"500"}
          padding={bdTop ? "4px 0" : "13px 12px 14px 12px"}
          borderRadius={bdTop ? "0px" : "8px"}
          border={bdTop ? "none" : "0.5px solid var(--bg-line-employee)"}
          placeholder={placeholder}
          display={type === "date" ? "block" : "flex"}
          color={colorIp ? colorIp : "inherit"}
          maxH={"44px"}
          disabled={disabled}
          sx={{
            "&::placeholder": {
              color: colorIp
                ? colorIp
                : true
                ? "var(--text-gray-form)"
                : "var(--color-placeHolder-employee)",
            },
          }}
          _focus={{
            boxShadow: "none",
            borderColor: "var(--color-option-employee-hover)",
            borderBottom: bdTop
              ? "1px solid var(--color-boder)"
              : "1px solid var(--color-option-employee-hover)",
          }}
          {...register}
          onChange={handleChange}
          onFocus={onFocus}
          onBlur={onBlur}
          type={type}
          autoComplete="new-password"
          value={value}
          borderBottom={
            bdTop
              ? state
                ? "1px solid var(--color-option-employee-hover)"
                : "1px solid var(--color-boder)"
              : "1px solid var(--color-boder"
          }
          readOnly={readonly}
          step={step}
        />

        {errors && (
          <Text fontSize={{ base: "12px" }} color={"red"} lineHeight={"15px"}>
            {errors.message}
          </Text>
        )}
      </FormControl>
    );
  }
);

export function SelectControl({
  label,
  dataOption,
  placeholder,
  selectedMain,
  onChange,
  errorMessage,
  registerProps,
  isSearchable,
  onFocus,
  onBlur,
  state,
  bdTop,
  mwidth,
}) {
  const [searchTerm, setSearchTerm] = useState("");
  const [zIndex, setZIndex] = useState(false);
  const [loading, setLoading] = useState(false);

  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  useEffect(() => {
    if (debouncedSearchTerm) {
      setLoading(true);
      const timer = setTimeout(() => {
        setLoading(false);
      }, 500);
      return () => clearTimeout(timer);
    } else {
      setLoading(false);
    }
  }, [debouncedSearchTerm]);

  const filteredOptions = useMemo(() => {
    return dataOption.filter((option) =>
      option.label.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
    );
  }, [debouncedSearchTerm, dataOption]);

  const onChangeAction = (value) => {
    onChange(value);
  };

  const check = dataOption.find((option) => option.value === selectedMain);

  const handleFocusBlur = (type, callback) => {
    setZIndex(type);
    if (callback) {
      callback();
    }
  };

  return (
    <Box
      w={"100%"}
      borderRadius={bdTop ? "0px" : "12px"}
      maxW={mwidth ? "100%" : "259px"}
      display={"flex"}
      flexDirection={"column"}
      gap={bdTop ? "8px" : "0px"}
      h={bdTop ? "auto" : "max-content"}
      zIndex={zIndex ? 4 : 1}
    >
      {label && (
        <Text
          fontSize={{ base: "12px" }}
          fontWeight={400}
          lineHeight={"15px"}
          mb={bdTop ? "0" : "6px"}
          color={
            state
              ? "var(--color-option-employee-hover)"
              : bdTop
              ? "var(--color-black-label)"
              : "var(--color-label-employee)"
          }
        >
          {label}
        </Text>
      )}

      <Menu matchWidth>
        <MenuButton
          as={Button}
          _active={{
            border: bdTop
              ? "none"
              : "1px solid var(--color-option-employee-hover)",
            borderBottom: bdTop
              ? "1px solid var(--color-boder)"
              : "1px solid var(--color-option-employee-hover)",
          }}
          _hover={{
            boxShadow: "none",
          }}
          rightIcon={<ChevronDownIcon color="#000" />}
          w="100%"
          backgroundColor="#FFF"
          border={bdTop ? "none" : "1px solid var(--bg-line-employee)"}
          fontSize="14px"
          fontWeight={500}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
          textAlign={"left"}
          p={bdTop ? "0" : "16px"}
          h={bdTop ? "22px" : "auto"}
          maxH={"44px"}
          padding={bdTop ? "4px 0" : "13px 12px 14px 12px"}
          color={
            selectedMain !== undefined &&
            selectedMain !== null &&
            selectedMain !== ""
              ? "var(--color-info-employee)"
              : bdTop
              ? "var(--text-gray-form)"
              : "var(--color-placeHolder-employee)"
          }
          {...registerProps}
          borderRadius={bdTop ? "0px" : "8px"}
          borderBottom={
            bdTop
              ? "1px solid var(--color-boder)"
              : "1px solid var(--bg-line-employee)"
          }
          onFocus={() => handleFocusBlur(true, onFocus)}
          onBlur={() => handleFocusBlur(false, onBlur)}
        >
          {selectedMain !== undefined &&
          selectedMain !== null &&
          selectedMain !== "" &&
          check
            ? check.label
            : placeholder}
        </MenuButton>

        <MenuList
          borderRadius="12px"
          minW="275px"
          maxH={"300px"}
          overflow={"auto"}
          onFocus={() => handleFocusBlur(true, onFocus)}
          onBlur={() => handleFocusBlur(false, onBlur)}
          sx={{
            "&::-webkit-scrollbar": {
              width: "4px",
            },
            "&::-webkit-scrollbar-thumb": {
              width: "4px",
              borderRadius: "50px",
              background: "var(--color-background)",
            },
            "&::-moz-scrollbar": {
              width: "4px",
            },
            "&::-moz-scrollbar-thumb": {
              width: "4px",
              borderRadius: "50px",
              background: "var(--color-background)",
            },
          }}
        >
          {isSearchable && (
            <Flex
              alignItems="center"
              gap="12px"
              padding="7px 16px"
              borderBottom="1px solid var(--bg-line-employee)"
            >
              <CiSearch color="var(--Text-color-Disable)" fontSize="20px" />
              <Input
                placeholder="Tìm kiếm..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                fontSize="14px"
                fontWeight={500}
                border="none"
                _focus={{ boxShadow: "none" }}
                _placeholder={{ color: "var(--color-placeHolder-employee)" }}
              />
              {loading && <Spinner size="sm" />}
            </Flex>
          )}

          {!loading && filteredOptions.length > 0 ? (
            filteredOptions.map((option) => (
              <MenuItem
                key={option.value}
                onClick={() => onChangeAction(option.value)}
                backgroundColor={
                  selectedMain !== undefined &&
                  selectedMain !== null &&
                  selectedMain !== "" &&
                  selectedMain === option.value
                    ? "#F9FAFD"
                    : "#FFF"
                }
                color={
                  selectedMain !== undefined &&
                  selectedMain !== null &&
                  selectedMain !== "" &&
                  selectedMain === option.value
                    ? "#1A9ED0"
                    : "#000"
                }
              >
                {option.label}
                {selectedMain !== undefined &&
                  selectedMain !== null &&
                  selectedMain !== "" &&
                  selectedMain === option.value && (
                    <IoCheckmarkOutline style={{ marginLeft: "auto" }} />
                  )}
              </MenuItem>
            ))
          ) : (
            <Box fontSize="14px" p="12px" textAlign="center">
              Không tìm thấy kết quả
            </Box>
          )}
        </MenuList>
      </Menu>

      {(selectedMain === undefined ||
        selectedMain === null ||
        selectedMain === "") &&
        errorMessage && (
          <Text fontSize="12px" color="red" lineHeight={"15px"}>
            {errorMessage.message}
          </Text>
        )}
    </Box>
  );
}

export function SelectRadioControl({
  label,
  dataOption,
  placeholder,
  selectedMain,
  onChange,
  errorMessage,
  registerProps,
  isSearchable,
  onFocus,
  onBlur,
  state,
  bdTop,
  mwidth,
  type = "single",
}) {
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(
    type === "multiple" ? [] : selectedMain
  );

  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  useEffect(() => {
    if (debouncedSearchTerm) {
      setLoading(true);
      const timer = setTimeout(() => {
        setLoading(false);
      }, 500);
      return () => clearTimeout(timer);
    } else {
      setLoading(false);
    }
  }, [debouncedSearchTerm]);

  const filteredOptions = useMemo(() => {
    return dataOption.filter((option) =>
      option.label.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
    );
  }, [debouncedSearchTerm, dataOption]);

  const onChangeAction = (value) => {
    if (type === "multiple") {
      const newSelectedOptions = selectedOptions.includes(value)
        ? selectedOptions.filter((item) => item !== value)
        : [...selectedOptions, value];
      setSelectedOptions(newSelectedOptions);
      onChange(newSelectedOptions);
    } else {
      setSelectedOptions(value);
      onChange(value);
    }
  };

  const isSelected = (value) => {
    return type === "multiple"
      ? selectedOptions.includes(value)
      : selectedOptions === value;
  };

  return (
    <Box
      w={"100%"}
      borderRadius={bdTop ? "0px" : "12px"}
      maxW={mwidth ? "100%" : "259px"}
      display={"flex"}
      flexDirection={"column"}
      gap={bdTop ? "8px" : "0px"}
      h={bdTop ? "auto" : "max-content"}
    >
      {label && (
        <Text
          fontSize={{ base: "12px" }}
          fontWeight={400}
          lineHeight={"15px"}
          mb={bdTop ? "0" : "6px"}
          color={
            state
              ? "var(--color-option-employee-hover)"
              : bdTop
              ? "var(--color-black-label)"
              : "var(--color-label-employee)"
          }
        >
          {label}
        </Text>
      )}

      <Menu matchWidth>
        <MenuButton
          as={Button}
          rightIcon={<ChevronDownIcon color="#000" />}
          w="100%"
          _active={{
            border: bdTop
              ? "none"
              : "1px solid var(--color-option-employee-hover)",
            borderBottom: bdTop
              ? "1px solid var(--color-boder)"
              : "1px solid var(--color-option-employee-hover)",
          }}
          _hover={{
            boxShadow: "none",
          }}
          border={bdTop ? "none" : "1px solid var(--bg-line-employee)"}
          backgroundColor="#FFF"
          fontSize="14px"
          fontWeight={500}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
          textAlign={"left"}
          p={bdTop ? "0" : "16px"}
          h={bdTop ? "22px" : "auto"}
          maxH={"44px"}
          padding={bdTop ? "4px 0" : "13px 12px 14px 12px"}
          color={
            selectedOptions && selectedOptions.length > 0
              ? "var(--color-info-employee)"
              : bdTop
              ? "var(--text-gray-form)"
              : "var(--color-placeHolder-employee)"
          }
          {...registerProps}
          borderRadius={bdTop ? "0px" : "8px"}
          borderBottom={
            bdTop
              ? "1px solid var(--color-boder)"
              : "1px solid var(--bg-line-employee)"
          }
        >
          {type === "multiple"
            ? selectedOptions.length > 0
              ? `${selectedOptions.length} selected`
              : placeholder
            : selectedOptions
            ? dataOption.find((option) => option.value === selectedOptions)
                .label
            : placeholder}
        </MenuButton>

        <MenuList
          borderRadius="12px"
          minW="275px"
          onFocus={onFocus}
          onBlur={onBlur}
        >
          {isSearchable && (
            <Flex
              alignItems="center"
              gap="12px"
              padding="7px 16px"
              borderBottom="1px solid var(--bg-line-employee)"
            >
              <CiSearch color="var(--Text-color-Disable)" fontSize="20px" />
              <Input
                placeholder="Tìm kiếm..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                fontSize="14px"
                fontWeight={500}
                border="none"
                _focus={{ boxShadow: "none" }}
                _placeholder={{ color: "var(--color-placeHolder-employee)" }}
              />
              {loading && <Spinner size="sm" />}
            </Flex>
          )}

          {!loading && filteredOptions.length > 0 ? (
            filteredOptions.map((option) => (
              <MenuItem
                key={option.value}
                onClick={() => onChangeAction(option.value)}
                backgroundColor={isSelected(option.value) ? "#F9FAFD" : "#FFF"}
                color={isSelected(option.value) ? "#1A9ED0" : "#000"}
              >
                <Checkbox
                  isChecked={isSelected(option.value)}
                  onChange={() => onChangeAction(option.value)}
                  colorScheme="blue"
                  mr={2}
                />
                {option.label}
              </MenuItem>
            ))
          ) : (
            <Box fontSize="14px" p="12px" textAlign="center">
              Không tìm thấy kết quả
            </Box>
          )}
        </MenuList>
      </Menu>

      {(!selectedOptions || selectedOptions.length === 0) && errorMessage && (
        <Text fontSize="12px" color="red" lineHeight={"15px"}>
          {errorMessage.message}
        </Text>
      )}
    </Box>
  );
}

export function InputDateControl({ label, placeholder, padding }) {
  return (
    <FormControl>
      {label && (
        <FormLabel display={"block"} mb={"6px"}>
          {label}
        </FormLabel>
      )}
      <InputGroup padding={padding ? padding : "13px 12px 14px 12px"}>
        <Input
          placeholder={placeholder ? placeholder : "Select Date and Time"}
          type="datetime-local"
        />
        <InputRightElement pointerEvents="none">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M12.6667 2.66675H3.33333C2.59695 2.66675 2 3.2637 2 4.00008V13.3334C2 14.0698 2.59695 14.6667 3.33333 14.6667H12.6667C13.403 14.6667 14 14.0698 14 13.3334V4.00008C14 3.2637 13.403 2.66675 12.6667 2.66675Z"
              stroke="#293755"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10.6667 1.33325V3.99992"
              stroke="#293755"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.33325 1.33325V3.99992"
              stroke="#293755"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M2 6.66675H14"
              stroke="#293755"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </InputRightElement>
      </InputGroup>
    </FormControl>
  );
}

export function RadioControl({
  options,
  selectedValue,
  onChange,
  errorMessage,
}) {
  return (
    <Box>
      <RadioGroup
        onChange={onChange}
        value={Number(selectedValue) || 0}
        mb={"16px"}
      >
        <Stack spacing={"36px"} direction="row">
          {options.map((option, index) => (
            <Radio
              border={"2px solid var(--border-input-employee)"}
              value={option?.value}
              key={index}
            >
              {option?.label}
            </Radio>
          ))}
        </Stack>
      </RadioGroup>
      {errorMessage && (
        <Text fontSize={{ base: "12px" }} color="red">
          {errorMessage}
        </Text>
      )}
    </Box>
  );
}
