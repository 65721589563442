import React, { useState } from "react";
import { Box, Button, Flex, Image, Text } from "@chakra-ui/react";
import iconExcel from "../../assets/images/employee/iconExcel.svg";
import TableOnLeave from "./tableOnLeave";
import { useOnLeave } from "../../hooks/useOnLeave";
import { exportFile } from "../../utils/tools";
import toast from "react-hot-toast";
import { leaveRequestCall } from "../../api/leaveRequest";

export default function Onleave() {
  const { entries, onLeaveData } = useOnLeave();
  const [paramExport, setParamExport] = useState(null);

  const handleExport = async () => {
    const checkInColumnMapping = {
      requestDate: "Thời gian tạo",
      codeEmployee: "Mã nhân viên",
      fullNamEmployee: "Tên nhân viên",
      jobPositionEmployee: "Chức vụ nhân viên",
      startDate: "Ngày bắt đầu nghỉ",
      daysRequested: "Số ngày nghỉ",
      leaveType: "Phân loại",
      reason: "Lí do nghỉ",
      fullNameApproved: "Tên người duyệt",
      jobPositionApproved: "Chức vụ người duyệt",
      approvedDate: "Ngày duyệt",
    };
    if (onLeaveData?.length) {
      const data = await leaveRequestCall.export(entries,paramExport);
      if (data) {
        exportFile(data, checkInColumnMapping, "bang_quan_ly_nghi_phep.xlsx");
        toast.success("Export thành công");
      } else {
        toast.error("Không có dữ liệu");
      }
    } else toast.error("Không có dữ liệu");
  };

  return (
    <Box
      w={{ base: "100%" }}
      backgroundColor="var(--color-backgroundmain)"
      p={{ base: "24px 16px 16px" }}
    >
      <Flex alignItems={"center"} justifyContent={"space-between"}>
        <Flex alignItems={"center"} gap={{ base: "74px" }}>
          <Text fontSize={{ base: "20px" }} fontWeight={600}>
            Quản lý nghỉ phép
          </Text>
        </Flex>
        <Button
          display={"flex"}
          alignItems={"center"}
          p={"10px 16px 10px 12px"}
          gap={"8px"}
          backgroundImage="var(--linear-gradient-excel)"
          _hover={{
            filter: "brightness(90%)",
          }}
          onClick={handleExport}
        >
          <Image src={iconExcel} />
          <Text
            fontSize={{ base: "15px" }}
            fontWeight={600}
            color="var(--color-main)"
          >
            Xuất excel
          </Text>
        </Button>
      </Flex>
      <Box mt={{ base: "23px" }}>
        <TableOnLeave paramData={setParamExport} />
      </Box>
    </Box>
  );
}
