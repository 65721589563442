import { useQuery } from "@tanstack/react-query";
import { clientAPI } from "../api/client";

async function fetchAllData() {
  const [division, projectType, apartmentType, project] = await Promise.all([
    clientAPI("get", `/division?page=1&limit=9999`),
    clientAPI("get", `/projectType?page=1&limit=9999`),
    clientAPI("get", `/apartmentType?page=1&limit=9999`),
    clientAPI("get", `/project?page=1&limit=9999`),
  ]);

  return {
    division: division?.items?.map(item => ({ 
      id: item._id,
      code: item?.code || '',
      name: item?.name || '',
      project: item?.project?.name || '',
      creator: item?.creator?.fullName || '',
      edit: {
        id: item._id,
        code: item?.code || '',
        name: item?.name || '',
        projectId: item?.projectId || '',
      }
    })) || [],
    projectType: projectType?.items?.map(item => ({ 
      id: item._id,
      code: item.code,
      name: item.name ,
    })) || [],
    apartmentType: apartmentType?.items?.map(item => ({ 
      id: item._id,
      code: item.code,
      name: item.name ,
    })) || [],
    projectSelect: project?.items?.map(item => ({ 
      value: item._id,
      label: item.name,
  })) || [],
  };
}

export function useProjectCategoriesProject() {
  const { data, isLoading, isRefetching, error, refetch } = useQuery({
    queryKey: ["allData"],
    queryFn: fetchAllData,
    refetchOnWindowFocus: false
  });

  return {
    division: data?.division || {}, 
    projectType: data?.projectType || {},
    apartmentType: data?.apartmentType || {},
    projectSelect: data?.projectSelect || {},
    isLoading,
    isRefetching,
    error,
    refetch, 
  };
}
