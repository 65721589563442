import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Flex,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  Image,
  Button,
} from "@chakra-ui/react";
import CloseIconBlack from "../../../assets/images/product/closeIconBlack.svg";
import {
  InputControl,
  SelectControl,
} from "../../../components/form/formControls";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import useDebounce from "../../../hooks/useDebounce";
import { useConditionalLists } from "../../../hooks/useCustomer";
import SearchBox from "../searchBox";
import SearchCheckBox from "../searchCheckBox";

const schema = Yup.object().shape({
  name: Yup.string().required("Vui lòng nhập tên khách hàng"),
  phone: Yup.string()
    .matches(/^[0-9]+$/, "Số điện thoại không hợp lệ")
    .min(10, "Số điện thoại phải có ít nhất 10 chữ số")
    .max(11, "Số điện thoại không được vượt quá 11 chữ số")
    .required("Vui lòng nhập số điện thoại"),
  email: Yup.string()
    .email("Email không hợp lệ")
    .required("Vui lòng nhập email"),
  identificationNumber: Yup.string()
    .matches(/^\d{9}$|^\d{12}$/, "Số CCCD/CMND không hợp lệ(9 hoặc 12 số).")
    .required("Vui lòng nhập số CCCD/CMND"),
  dateOfIdentification: Yup.string().required(
    "Vui lòng nhập ngày cấp CCCD/CMND"
  ),
  placeOfIdentification: Yup.string().required(
    "Vui lòng nhập nơi cấp CCCD/CMND"
  ),
  permanentAddress: Yup.string().required("Vui lòng nhập địa chỉ thường trú"),
  currentAddress: Yup.string().required("Vui lòng nhập địa chỉ hiện tại"),
  // jobId: Yup.string().required("Vui lòng chọn nghề nghiệp"),
  // customerTypeId: Yup.string().required("Vui lòng chọn phân loại"),
  // wishlist: Yup.string().required("Vui lòng chọn dự án"),
});

const FormNew = ({
  isOpen,
  onClose,
  defaultValues,
  onSubmit,
  isEdit = false,
}) => {
  const {
    register,
    getValues,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValues || {},
  });

  const [inputColors, setInputColors] = useState({
    dateOfIdentification: "var(--color-text-unclick)",
  });
  const [focusStates, setFocusStates] = useState({});

  useEffect(() => {
    if (isOpen) {
      reset(defaultValues);
      setInputColors({
        dateOfIdentification: "var(--text-gray-form)",
      });
    }
  }, [isOpen, defaultValues, reset]);

  const handleFocus = (field) => {
    setFocusStates((prev) => ({ ...prev, [field]: true }));
  };

  const handleBlur = (field) => {
    setFocusStates((prev) => ({ ...prev, [field]: false }));
  };

  const handleDateChange = (e, inputName) => {
    const selectedDate = e.target.value;
    setInputColors((prevColors) => ({
      ...prevColors,
      [inputName]: selectedDate
        ? "var(--color-info-employee)"
        : "var(--color-text-unclick)",
    }));
  };

  const onchangeCareer = (option) => {
    setValue("jobId", option);
  };

  const onchangeType = (option) => {
    setValue("customerTypeId", option);
  };

  const onchangeProject = (option) => {
    setValue("wishlist", option);
  };

  const submitHandler = (data) => {
    onSubmit(data);
  };

  const [searchTermJob, setSearchTermJob] = useState("");
  const debouncedSearchTermJob = useDebounce(searchTermJob, 500);
  const [searchTermCustomerType, setSearchTermCustomerType] = useState("");
  const debouncedSearchTermCustomerType = useDebounce(
    searchTermCustomerType,
    500
  );
  const [searchTermProject, setSearchTermProject] = useState("");
  const debouncedSearchTermProject = useDebounce(searchTermProject, 500);
  const { jobList, isLoading: jobIsLoading } = useConditionalLists(
    debouncedSearchTermJob,
    { job: true }
  );
  const { customerTypeList, isLoading: customerTypeIsLoading } =
    useConditionalLists(debouncedSearchTermCustomerType, {
      customerType: true,
    });
  const { projectList, isLoading: projectIsLoading } = useConditionalLists(
    debouncedSearchTermProject,
    { project: true }
  );
  const formatEntriesForSelect = (entries) =>
    Object.entries(entries || {}).map(([key, value]) => ({
      value: key,
      label: value,
    }));
  const jobs = useMemo(() => {
    if (!jobList) return [];
    return formatEntriesForSelect(jobList);
  }, [jobList]);
  const customerTypes = useMemo(() => {
    if (!customerTypeList) return [];
    return formatEntriesForSelect(customerTypeList);
  }, [customerTypeList]);
  const projects = useMemo(() => {
    if (!projectList) return [];
    return formatEntriesForSelect(projectList);
  }, [projectList]);

  const initJob = jobs?.find((type) => type.value === watch("jobId"));
  const initType = customerTypes?.find(
    (job) => job.value === watch("customerTypeId")
  );
  const initProject = customerTypes?.filter(
    (job) => job.value === watch("wishlist")
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        backgroundColor="var(--color-main)"
        borderRadius={"16px"}
        w={"100%"}
        maxW={"625px"}
        overflowY={"auto"}
        sx={{
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-thumb": {
            width: "4px",
            borderRadius: "50px",
            background: "var(--color-background)",
          },
          "&::-moz-scrollbar": {
            width: "4px",
          },
          "&::-moz-scrollbar-thumb": {
            width: "4px",
            borderRadius: "50px",
            background: "var(--color-background)",
          },
        }}
      >
        <form onSubmit={handleSubmit(submitHandler)}>
          <Flex minH={"625px"} flexDirection={"column"}>
            <Flex
              justifyContent={"space-between"}
              alignItems={"center"}
              gap={"10px"}
              padding={"25px 24px"}
              borderBottom={"1px solid var(--bg-line-employee)"}
            >
              <Text
                color="var(--color-header-employee)"
                fontSize={"18px"}
                fontWeight={"600"}
              >
                {isEdit ? "Chỉnh sửa khách hàng" : "Khách hàng mới"}
              </Text>
              <Box cursor={"pointer"} onClick={onClose}>
                <Image src={CloseIconBlack} />
              </Box>
            </Flex>
            <Box flex={1} w={"545px"} m={"24px auto"}>
              <Flex
                justifyContent={"space-between"}
                alignItems={"flex-start"}
                gap={"27px"}
                mb={"16px"}
              >
                <InputControl
                  label="Tên khách hàng"
                  placeholder="Nhập tên khách hàng"
                  onFocus={() => handleFocus("name")}
                  onBlur={() => handleBlur("name")}
                  state={focusStates["name"]}
                  register={register("name")}
                  errors={errors.name}
                />
                <InputControl
                  type={"number"}
                  label="SĐT khách hàng"
                  placeholder="Nhập SĐT khách hàng"
                  register={register("phone")}
                  errors={errors.phone}
                  onFocus={() => handleFocus("phone")}
                  onBlur={() => handleBlur("phone")}
                  state={focusStates["phone"]}
                />
              </Flex>
              <Flex
                justifyContent={"space-between"}
                alignItems={"flex-start"}
                gap={"27px"}
                mb={"16px"}
              >
                <InputControl
                  type={"email"}
                  label="Email"
                  placeholder="Nhập email"
                  errors={errors.email}
                  onFocus={() => handleFocus("email")}
                  onBlur={() => handleBlur("email")}
                  register={register("email")}
                  state={focusStates["email"]}
                />
                <InputControl
                  type={"number"}
                  label="Số CCCD"
                  placeholder="Nhập số CCCD"
                  errors={errors.identificationNumber}
                  onFocus={() => handleFocus("identificationNumber")}
                  onBlur={() => handleBlur("identificationNumber")}
                  register={register("identificationNumber")}
                  state={focusStates["identificationNumber"]}
                />
              </Flex>
              <Flex
                justifyContent={"space-between"}
                alignItems={"flex-start"}
                gap={"27px"}
                mb={"16px"}
              >
                <InputControl
                  type={"date"}
                  label="Ngày cấp"
                  placeholder="dd/mm/yy"
                  colorIp={
                    watch("dateOfIdentification")
                      ? "var(--color-info-employee)"
                      : inputColors.dateOfIdentification
                  }
                  onChange={(e) => handleDateChange(e, "dateOfIdentification")}
                  errors={errors.dateOfIdentification}
                  onFocus={() => handleFocus("dateOfIdentification")}
                  onBlur={() => handleBlur("dateOfIdentification")}
                  register={register("dateOfIdentification")}
                  state={focusStates["dateOfIdentification"]}
                />
                <InputControl
                  label="Nơi cấp"
                  placeholder="Nhập nơi cấp"
                  errors={errors.placeOfIdentification}
                  onFocus={() => handleFocus("placeOfIdentification")}
                  onBlur={() => handleBlur("placeOfIdentification")}
                  register={register("placeOfIdentification")}
                  state={focusStates["placeOfIdentification"]}
                />
              </Flex>
              <Flex
                justifyContent={"space-between"}
                alignItems={"flex-start"}
                gap={"27px"}
                mb={"16px"}
              >
                <InputControl
                  label="Địa chỉ thường trú"
                  placeholder="Nhập địa chỉ thường trú"
                  errors={errors.permanentAddress}
                  onFocus={() => handleFocus("permanentAddress")}
                  onBlur={() => handleBlur("permanentAddress")}
                  register={register("permanentAddress")}
                  state={focusStates["permanentAddress"]}
                  mwidth={"100%"}
                />
              </Flex>
              <Flex
                justifyContent={"space-between"}
                alignItems={"flex-start"}
                gap={"27px"}
                mb={"16px"}
              >
                <InputControl
                  label="Chỗ ở hiện tại"
                  placeholder="Nhập chỗ ở hiện tại"
                  errors={errors.currentAddress}
                  onFocus={() => handleFocus("currentAddress")}
                  onBlur={() => handleBlur("currentAddress")}
                  state={focusStates["currentAddress"]}
                  register={register("currentAddress")}
                  mwidth={"100%"}
                />
              </Flex>
              <Flex
                justifyContent={"space-between"}
                alignItems={"flex-start"}
                gap={"27px"}
                mb={"16px"}
              >
                <SearchBox
                  label={"Nghề nghiệp"}
                  isSearchable={true}
                  placeholder="Chọn nghề nghiệp"
                  selectedMain={watch("jobId")}
                  initialSelectedEmp={initJob}
                  onChange={onchangeCareer}
                  data={jobs}
                  isLoading={jobIsLoading}
                  searchTerm={searchTermJob}
                  setSearchTerm={setSearchTermJob}
                  errorMessage={errors.jobId}
                  onFocus={() => handleFocus("jobId")}
                  onBlur={() => handleBlur("jobId")}
                  state={focusStates["jobId"]}
                />

                <SearchBox
                  label={"Phân loại"}
                  isSearchable={true}
                  placeholder="Chọn phân loại"
                  selectedMain={watch("customerTypeId")}
                  initialSelectedEmp={initType}
                  onChange={onchangeType}
                  data={customerTypes}
                  isLoading={customerTypeIsLoading}
                  searchTerm={searchTermCustomerType}
                  setSearchTerm={setSearchTermCustomerType}
                  errorMessage={errors.customerTypeId}
                  onFocus={() => handleFocus("customerTypeId")}
                  onBlur={() => handleBlur("customerTypeId")}
                  state={focusStates["customerTypeId"]}
                />
              </Flex>
              <Flex
                justifyContent={"space-between"}
                alignItems={"flex-start"}
                gap={"27px"}
                mb={"16px"}
              >
                <SearchCheckBox
                  label={"Dự án quan tâm"}
                  isSearchable={true}
                  placeholder="Chọn dự án quan tâm"
                  selectedMain={watch("wishlist")}
                  initialSelectedEmp={initProject}
                  onChange={onchangeProject}
                  data={projects}
                  isLoading={projectIsLoading}
                  searchTerm={searchTermProject}
                  setSearchTerm={setSearchTermProject}
                  errorMessage={errors.wishlist}
                />
              </Flex>
            </Box>
            <Flex p={"24px"} gap={"16px"} justifyContent={"flex-end"}>
              {isEdit ? (
                <>
                  <Button
                    onClick={onClose}
                    w={"112px"}
                    h={"44px"}
                    color={"var(--color-option-employee-hover)"}
                    border={"1px solid var(--color-option-employee-hover)"}
                    bg={"var(--color-main)"}
                    _hover={{
                      filter: "brightness(80%)",
                    }}
                  >
                    Hủy
                  </Button>
                  <Button
                    type="submit"
                    w={"112px"}
                    h={"44px"}
                    color={"var(--color-main)"}
                    bg={"var(--color-button)"}
                    _hover={{
                      filter: "brightness(80%)",
                    }}
                  >
                    Lưu
                  </Button>
                </>
              ) : (
                <Button
                  type="submit"
                  w={"164px"}
                  h={"44px"}
                  color={"var(--color-main)"}
                  bg={"var(--color-button)"}
                  _hover={{
                    filter: "brightness(80%)",
                  }}
                >
                  Tạo khách hàng
                </Button>
              )}
            </Flex>
          </Flex>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default FormNew;