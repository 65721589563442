import {
  Box,
  Button,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import iconPlus from "../../../assets/images/personnelCategories/plus.svg";
import iconEdit from "../../../assets/images/employee/edit.svg";
import icontrash from "../../../assets/images/product/trash.svg";
import { AiOutlineMore } from "react-icons/ai";
import { formatTableValue } from "./formatTableValue";
import DeleteDialog from "../../../components/dialog/delete";
import FormCustom from "./formCustom";
import toast from "react-hot-toast";
import { guaranteeTypeCall } from "../../../api/guaranteeType";
import { businessCategoryCall } from "../../../api/businessCategory";
import { leaveTypeCall } from "../../../api/leaveType";
import { holidayCall } from "../../../api/holiday";

const FormFeature = ({
  title,
  button,
  type,
  isLoading,
  refetch,
  isRefetching,
  textDelete,
  textAdd,
  textButtonAdd,
  textTitleEdit,
  formTable,
  data,
  showButton = true,
}) => {
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();
  const [isDelete, setIsDelete] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [dataEdit, setDataEdit] = useState({});

  const onPopupIsEdit = (type, data) => {
    setIsEdit(type);
    setIsDelete(false);
    type ? setDataEdit(data) : setDataEdit({});
    onOpen();
  };

  const onPopupIsDelete = (data) => {
    setIsDelete(true);
    setDataEdit(data);
    onOpen();
  };

  const handleSubmitAdd = async (data) => {
    const toastCreate = toast.loading("Đang thêm dữ liệu ...");
    try {
      if (type === 0) {
        await holidayCall.create(data);
        await refetch();
        onToggle();
        toast.success("Thêm thành công");
      } else if (type === 1) {
        await leaveTypeCall.create(data);
        await refetch();
        onToggle();
        toast.success("Thêm thành công");
      } else if (type === 2) {
        await businessCategoryCall.create(data);
        await refetch();
        onToggle();
        toast.success("Thêm thành công");
      } else if (type === 3) {
        console.log("3");
      } else {
        await guaranteeTypeCall.create(data);
        await refetch();
        onToggle();
        toast.success("Thêm thành công");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Có lỗi xảy ra khi thêm");
    }
    toast.dismiss(toastCreate);
  };

  const handleSubmitEdit = async (data) => {
    const toastEdit = toast.loading("Đang sửa dữ liệu ...");
    const { id, ...dataEdit } = data;
    try {
      if (type === 0) {
        await holidayCall.update(id, dataEdit);
        await refetch();
        onToggle();
        toast.success("Sửa thành công");
      } else if (type === 1) {
        await leaveTypeCall.update(id, dataEdit);
        await refetch();
        onToggle();
        toast.success("Sửa thành công");
      } else if (type === 2) {
        await businessCategoryCall.update(id, dataEdit);
        await refetch();
        onToggle();
        toast.success("Sửa thành công");
      } else if (type === 3) {
        console.log("3");
      } else {
        await guaranteeTypeCall.update(id, dataEdit);
        await refetch();
        onToggle();
        toast.success("Sửa thành công");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Có lỗi xảy ra khi sửa");
    }
    toast.dismiss(toastEdit);
  };

  const handleSubmitDelete = async () => {
    const toastEdit = toast.loading("Đang xóa dữ liệu ...");
    const { id } = dataEdit;
    try {
      if (type === 0) {
        await holidayCall.delete(id);
        await refetch();
        onToggle();
        toast.success("Xóa thành công");
      } else if (type === 1) {
        await leaveTypeCall.delete(id);
        await refetch();
        onToggle();
        toast.success("Xóa thành công");
      } else if (type === 2) {
        await businessCategoryCall.delete(id);
        await refetch();
        onToggle();
        toast.success("Xóa thành công");
      } else if (type === 3) {
        console.log("3");
      } else {
        await guaranteeTypeCall.delete(id);
        await refetch();
        onToggle();
        toast.success("Xóa thành công");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Có lỗi xảy ra khi xóa");
    }
    toast.dismiss(toastEdit);
  };

  return (
    <Box>
      <Flex justifyContent={"space-between"} mb={"16px"}>
        <Text
          fontSize={{ base: "16px" }}
          lineHeight={"16px"}
          fontWeight={600}
          color="var(--color-header-employee)"
        >
          {title}
        </Text>
        {showButton && (
          <Button
            display={"flex"}
            alignItems={"center"}
            p={"0px 12px"}
            gap={"10px"}
            borderRadius={"8px"}
            border={"1px solid var(--color-option-employee-hover)"}
            backgroundImage="var(--color-main)"
            bg={"var(--color-main)"}
            padding={"8px 12px"}
            h={"32px"}
            _hover={{
              filter: "brightness(95%)",
            }}
            onClick={() => onPopupIsEdit(false)}
          >
            <Image src={iconPlus} />
            <Text
              fontSize={{ base: "13px" }}
              lineHeight={"16px"}
              fontWeight={600}
              color="var(--color-option-employee-hover)"
            >
              {button}
            </Text>
          </Button>
        )}
      </Flex>
      <Skeleton isLoaded={!isLoading && !isRefetching}>
        <TableContainer width={"100%"} pb={"16px"}>
          <Table w={"100%"} variant="unstyled">
            <Thead
              w={"100%"}
              h={"41px"}
              color="white"
              fontWeight="400"
              backgroundColor="var(--fill-avatar)"
            >
              <Tr h={{ base: "41px" }}>
                {data.headers.map((e, index) => {
                  let width;
                  let textAlign;
                  if (e.key === "") {
                    width = "2%";
                    textAlign = "center";
                  } else if (e.key === "type") {
                    width = "15%";
                    textAlign = "start";
                  } else if (e.key === "name") {
                    width = "15%";
                    textAlign = "start";
                  } else if (e.key === "totalDaysAllocated") {
                    width = "35%";
                    textAlign = "center";
                  } else if (formTable && e.key === "name") {
                    width = "40%";
                    textAlign = "start";
                  } else {
                    width = "auto";
                    textAlign = "end";
                  }
                  return (
                    <Td
                      p={
                        e.key === ""
                          ? "16px 16px 8px 0px"
                          : "16px 16px 8px 16px"
                      }
                      key={index}
                      border={"none"}
                      color={"#51607B"}
                      fontSize={{ base: "14px" }}
                      w={width}
                    >
                      <Box textAlign={textAlign}>{e.label}</Box>
                    </Td>
                  );
                })}
              </Tr>
            </Thead>
            <Tbody w={"100%"} h={"100%"}>
              {data.data?.length > 0 ? (
                data.data?.map((e, rowIndex) => {
                  const items = { ...e };
                  delete items.id;
                  delete items.code;
                  const keyValues = Object.keys(items);
                  return (
                    <Tr
                      w={"100%"}
                      key={rowIndex}
                      h={"44px"}
                      backgroundColor={
                        rowIndex % 2 === 0
                          ? "#transparent"
                          : "var(--fill-avatar)"
                      }
                    >
                      <Td p={"16px 16px 8px 0px"} w={"2%"}>
                        <Menu>
                          <MenuButton
                            _hover={{ backgroundColor: "none" }}
                            _active={{ backgroundColor: "none" }}
                            background="none"
                            as={Button}
                          >
                            <AiOutlineMore fontSize={"20px"} />
                          </MenuButton>
                          {showButton && <MenuList
                            fontSize={{ base: "14px" }}
                            fontWeight={500}
                            lineHeight={"140%"}
                            color={"var(--color-info-employee)"}
                          >
                            <MenuItem
                              gap={"16px"}
                              onClick={() => onPopupIsEdit(true, e)}
                            >
                              <Image src={iconEdit} /> Chỉnh sửa
                            </MenuItem>
                            <MenuItem
                              gap={"16px"}
                              onClick={() => onPopupIsDelete(e)}
                            >
                              <Image src={icontrash} /> Xóa
                            </MenuItem>
                          </MenuList>}
                        </Menu>
                      </Td>
                      <td
                        style={{
                          textAlign: "center",
                          padding: "16px 16px 8px 0px",
                        }}
                      >
                        {rowIndex + 1}
                      </td>
                      {keyValues.map((keyvalue, index) => {
                        let width = "auto";
                        let widthText = "100%";
                        let textAlign = "auto";
                        let whiteSpace = "inherit";
                        if (keyvalue === "") {
                          width = "2%";
                          textAlign = "center";
                        } else if (formTable && keyvalue === "name") {
                          width = "40%";
                          textAlign = "start";
                        } else if (keyvalue === "type") {
                          width = "15%";
                          textAlign = "start";
                        } else if (keyvalue === "name") {
                          width = "15%";
                          textAlign = "start";
                          whiteSpace = "normal";
                          widthText = "200px";
                        } else if (keyvalue === "totalDaysAllocated") {
                          width = "35%";
                          textAlign = "center";
                        } else {
                          width = "auto";
                          textAlign = "end";
                        }
                        return (
                          <Td p={"16px 16px 8px 16px"} w={width} key={index}>
                            <Box
                              fontSize={{ base: "14px" }}
                              lineHeight={"19.6px"}
                              textAlign={textAlign}
                              fontWeight={500}
                              color={"#293755"}
                              w={widthText}
                              whiteSpace={whiteSpace}
                              overflow="hidden"
                              textOverflow="ellipsis"
                              display="-webkit-box"
                              sx={{
                                WebkitLineClamp: "3",
                                WebkitBoxOrient: "vertical",
                              }}
                            >
                              {formatTableValue(items[keyvalue], keyvalue)}
                            </Box>
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })
              ) : (
                <Tr>
                  <Td
                    colSpan={data.headers.length}
                    textAlign="center"
                    padding={"70px 0"}
                  >
                    Không có dữ liệu
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Skeleton>

      {!isDelete ? (
        isEdit ? (
          <FormCustom
            isOpen={isOpen}
            onClose={onClose}
            isEdit={true}
            type={type}
            defaultValues={dataEdit}
            onSubmit={handleSubmitEdit}
            textTitleEdit={textTitleEdit}
          />
        ) : (
          <FormCustom
            isOpen={isOpen}
            type={type}
            onClose={onClose}
            isEdit={false}
            onSubmit={handleSubmitAdd}
            textAdd={textAdd}
            textButtonAdd={textButtonAdd}
          />
        )
      ) : (
        <DeleteDialog
          isOpen={isOpen}
          onClose={onClose}
          action={handleSubmitDelete}
          title={"Xóa " + textDelete}
          description={
            <Flex flexWrap={"wrap"} display={"inline"}>
              "Bạn có chắc chắn muốn xóa {textDelete}{" "}
              <Text fontWeight={"600"} display={"inline"}>
                {dataEdit.name}
              </Text>{" "}
              này không?"
            </Flex>
          }
        />
      )}
    </Box>
  );
};

export default FormFeature;
