import { Text } from "@chakra-ui/react";

export const formatTableValue = (value, key) => {
  switch (key) {
    case "contact":
      return (
        <>
          <Text>{value.phone}</Text>
          <Text>{value.email}</Text>
        </>
      );
    case "identificationInfo":
      return (
        <>
          <Text>{value.dateOfIdentification}</Text>
          <Text>{value.placeOfIdentification}</Text>
        </>
      );
    case "project":
      return (
        <>
          {value && value.length > 0
            ? value.map((project, index) => <Text key={index}>{project}</Text>)
            : "-"}
        </>
      );
    default:
      return <Text>{value}</Text>;
  }
};
