import {
  Box,
  Flex,
  Input,
  Text,
  Menu,
  MenuButton,
  Button,
  MenuList,
  Spinner,
  MenuItem,
  Checkbox,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { CiSearch } from "react-icons/ci";
import { MdOutlineClear } from "react-icons/md";

export default function SearchCheckBox({
  label,
  placeholder,
  selectedMain = [],
  onChange,
  errorMessage,
  registerProps,
  isSearchable,
  onFocus,
  onBlur,
  state,
  bdTop,
  mwidth,
  initialSelectedEmp = [],
  data,
  isLoading,
  searchTerm,
  setSearchTerm,
}) {
  const [zIndex, setZIndex] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(initialSelectedEmp);

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (selectedMain?.length > 0) {
      setSelectedOptions(selectedMain);
    } else if (initialSelectedEmp?.length > 0) {
      setSelectedOptions(initialSelectedEmp);
    } else {
      setSelectedOptions([]);
    }
  }, [initialSelectedEmp, selectedMain]);

  const handleCheckboxChange = (value) => {
    const isSelected = selectedOptions.includes(value);
    let updatedOptions;

    if (isSelected) {
      updatedOptions = selectedOptions.filter((option) => option !== value);
    } else {
      updatedOptions = [...selectedOptions, value];
    }

    setSelectedOptions(updatedOptions);
    onChange(updatedOptions);
  };

  const handleFocusBlur = (type, callback) => {
    setZIndex(type);
    if (callback) {
      callback();
    }
  };

  const handleClearSearch = () => {
    setSearchTerm("");
    setSelectedOptions([]);
    onChange([]);
  };
  return (
    <Box
      w={"100%"}
      borderRadius={bdTop ? "0px" : "12px"}
      maxW={mwidth ? "100%" : "259px"}
      display={"flex"}
      flexDirection={"column"}
      gap={bdTop ? "8px" : "0px"}
      h={bdTop ? "auto" : "max-content"}
      zIndex={zIndex ? 3 : 1}
      minW={"200px"}
    >
      {label && (
        <Text
          fontSize={{ base: "12px" }}
          fontWeight={400}
          lineHeight={"15px"}
          mb={bdTop ? "0" : "6px"}
          color={
            state
              ? "var(--color-option-employee-hover)"
              : bdTop
              ? "var(--color-black-label)"
              : "var(--color-label-employee)"
          }
        >
          {label}
        </Text>
      )}

      <Menu matchWidth closeOnSelect={false}>
        <MenuButton
          as={Button}
          _active={{
            border: bdTop
              ? "none"
              : "1px solid var(--color-option-employee-hover)",
            borderBottom: bdTop
              ? "1px solid var(--color-boder)"
              : "1px solid var(--color-option-employee-hover)",
          }}
          _hover={{
            boxShadow: "none",
          }}
          rightIcon={<ChevronDownIcon color="#000" />}
          w="100%"
          backgroundColor="#FFF"
          border={bdTop ? "none" : "1px solid var(--bg-line-employee)"}
          fontSize="14px"
          fontWeight={500}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
          textAlign={"left"}
          p={bdTop ? "0" : "16px"}
          h={bdTop ? "22px" : "auto"}
          maxH={"44px"}
          padding={bdTop ? "4px 0" : "13px 12px 14px 12px"}
          color={
            selectedOptions.length > 0
              ? "var(--color-info-employee)"
              : bdTop
              ? "var(--text-gray-form)"
              : "var(--color-placeHolder-employee)"
          }
          {...registerProps}
          borderRadius={bdTop ? "0px" : "8px"}
          borderBottom={
            bdTop
              ? "1px solid var(--color-boder)"
              : "1px solid var(--bg-line-employee)"
          }
          onFocus={() => handleFocusBlur(true, onFocus)}
          onBlur={() => handleFocusBlur(false, onBlur)}
        >
          {selectedOptions.length > 0
            ? `${selectedOptions.length} dự án được chọn`
            : placeholder}
        </MenuButton>

        <MenuList
          borderRadius="12px"
          minW="275px"
          onFocus={() => handleFocusBlur(true, onFocus)}
          onBlur={() => handleFocusBlur(false, onBlur)}
        >
          {isSearchable && (
            <Flex
              alignItems="center"
              gap="12px"
              padding="0px 12px 0px 12px"
              borderBottom="1px solid var(--bg-line-employee)"
            >
              <CiSearch color="var(--Text-color-Disable)" fontSize="20px" />
              <Input
                placeholder="Tìm kiếm..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                fontSize="14px"
                fontWeight={500}
                padding={0}
                border="none"
                _focus={{ boxShadow: "none" }}
                _placeholder={{ color: "var(--color-placeHolder-employee)" }}
                flex="1"
              />
              {selectedMain?.length > 0 && (
                <MdOutlineClear
                  cursor="pointer"
                  onClick={handleClearSearch}
                  color="var(--color-info-employee)"
                  fontSize="20px"
                />
              )}
              {loading && <Spinner size="sm" />}
            </Flex>
          )}

          {!loading && data?.length > 0 ? (
            data.map((option) => (
              <MenuItem
                key={option.value}
                backgroundColor="#FFF"
                color="#000"
                onClick={(e) => e.stopPropagation()}
              >
                <Checkbox
                  isChecked={selectedOptions.includes(option.value)}
                  onChange={() => handleCheckboxChange(option.value)}
                >
                  {option.label}
                </Checkbox>
              </MenuItem>
            ))
          ) : (
            <Box fontSize="14px" p="12px" textAlign="center">
              Không tìm thấy kết quả
            </Box>
          )}
        </MenuList>
      </Menu>

      {selectedOptions.length === 0 && errorMessage && (
        <Text fontSize="12px" color="red" lineHeight={"15px"}>
          {errorMessage.message}
        </Text>
      )}
    </Box>
  );
}
