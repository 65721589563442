import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  Image,
  Button,
} from "@chakra-ui/react";
import CloseIconBlack from "../../../assets/images/product/closeIconBlack.svg";
import {
  InputControl,
  SelectControl,
} from "../../../components/form/formControls";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

const schema = Yup.object().shape({
  codeApartment: Yup.string().required("Tên căn hộ là bắt buộc"),
  nameApartment: Yup.string().required("Tên căn hộ là bắt buộc"),
  landArea: Yup.string().required("DT đất là bắt buộc"),
  constructionArea: Yup.string().required("Dữ liệu là bắt buộc"),
  height: Yup.number()
    .nullable()
    .transform((value, originalValue) => {
      return originalValue.trim() === "" ? null : Number(originalValue);
    })
    .required("Chiều dài là bắt buộc"),
  width: Yup.number()
    .nullable()
    .transform((value, originalValue) => {
      return originalValue.trim() === "" ? null : Number(originalValue);
    })
    .required("Chiều rộng là bắt buộc"),
  bedroom: Yup.number()
    .nullable()
    .transform((value, originalValue) => {
      return originalValue.trim() === "" ? null : Number(originalValue);
    })
    .required("Dữ liệu là bắt buộc"),
  toilet: Yup.number()
    .nullable()
    .transform((value, originalValue) => {
      return originalValue.trim() === "" ? null : Number(originalValue);
    })
    .required("Số phòng VS là bắt buộc"),
  price: Yup.number()
    .nullable()
    .transform((value, originalValue) => {
      return originalValue.trim() === "" ? null : Number(originalValue);
    })
    .required("Giá trị là bắt buộc"),
  priceHH: Yup.number()
    .nullable()
    .transform((value, originalValue) => {
      return originalValue.trim() === "" ? null : Number(originalValue);
    })
    .required("Giá tính HH là bắt buộc"),
});

const FormNew = ({ isOpen, onClose, data, onSubmit, defaultValues }) => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValues || {},
  });

  const typeApartments = [
    {
      label: "a",
      value: "0",
    },
    {
      label: "b",
      value: "1",
    },
  ];

  const [focusStates, setFocusStates] = useState({});

  useEffect(() => {
    if (isOpen) {
      reset(defaultValues);
    }
  }, [isOpen, defaultValues, reset]);

  const handleFocus = (field) => {
    setFocusStates((prev) => ({ ...prev, [field]: true }));
  };

  const handleBlur = (field) => {
    setFocusStates((prev) => ({ ...prev, [field]: false }));
  };

  const onchangeTypeApartment = (option) => {
    setValue("typeApartment", option);
  };
  const onchangeDirection = (option) => {
    setValue("direction", option);
  };
  const onchangeNature = (option) => {
    setValue("nature", option);
  };
  const onchangeProject = (option) => {
    setValue("project", option);
  };
  const onchangeSubdivision = (option) => {
    setValue("subdivision", option);
  };

  const submitHandler = (data) => {
    onSubmit(data);
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        backgroundColor="var(--color-main)"
        borderRadius={"16px"}
        h={"86.5vh"}
        w={"100%"}
        maxW={"625px"}
        overflowY={"auto"}
        sx={{
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-thumb": {
            width: "4px",
            borderRadius: "50px",
            background: "var(--color-background)",
          },
          "&::-moz-scrollbar": {
            width: "4px",
          },
          "&::-moz-scrollbar-thumb": {
            width: "4px",
            borderRadius: "50px",
            background: "var(--color-background)",
          },
        }}
      >
        <form onSubmit={handleSubmit(submitHandler)}>
          <Flex minH={"800px"} flexDirection={"column"}>
            <Flex
              justifyContent={"space-between"}
              alignItems={"center"}
              gap={"10px"}
              padding={"25px 24px"}
              borderBottom={"1px solid var(--bg-line-employee)"}
            >
              <Text
                color="var(--color-header-employee)"
                fontSize={"18px"}
                fontWeight={"600"}
              >
                Tạo căn hộ mới
              </Text>
              <Box cursor={"pointer"} onClick={onClose}>
                <Image src={CloseIconBlack} />
              </Box>
            </Flex>
            <Box flex={1} w={"545px"} m={"24px auto"}>
              <Flex
                justifyContent={"space-between"}
                alignItems={"flex-start"}
                gap={"27px"}
                mb={"16px"}
              >
                <InputControl
                  label="Mã căn hộ"
                  placeholder="Nhập mã căn hộ"
                  errors={errors.codeApartment}
                  register={register("codeApartment")}
                  onFocus={() => handleFocus("codeApartment")}
                  onBlur={() => handleBlur("codeApartment")}
                  state={focusStates["codeApartment"]}
                />
                <InputControl
                  label="Tên căn hộ"
                  placeholder="Nhập tên căn hộ"
                  errors={errors.nameApartment}
                  register={register("nameApartment")}
                  onFocus={() => handleFocus("nameApartment")}
                  onBlur={() => handleBlur("nameApartment")}
                  state={focusStates["nameApartment"]}
                />
              </Flex>
              <Flex
                justifyContent={"space-between"}
                alignItems={"flex-start"}
                gap={"27px"}
                mb={"16px"}
              >
                <SelectControl
                  label="Loại hình"
                  dataOption={typeApartments}
                  placeholder="Chọn loại hình căn hộ"
                  selectedMain={watch("typeApartment")}
                  onChange={onchangeTypeApartment}
                  onFocus={() => handleFocus("typeApartment")}
                  onBlur={() => handleBlur("typeApartment")}
                  state={focusStates["typeApartment"]}
                />
                <SelectControl
                  label="Hướng"
                  dataOption={typeApartments}
                  placeholder="Chọn hướng căn hộ"
                  selectedMain={watch("direction")}
                  onChange={onchangeDirection}
                  onFocus={() => handleFocus("direction")}
                  onBlur={() => handleBlur("direction")}
                  state={focusStates["direction"]}
                />
              </Flex>
              <Flex
                justifyContent={"space-between"}
                alignItems={"flex-start"}
                gap={"27px"}
                mb={"16px"}
              >
                <SelectControl
                  label="Tính chất"
                  dataOption={typeApartments}
                  placeholder="Chọn tính chất căn hộ"
                  selectedMain={watch("nature")}
                  onChange={onchangeNature}
                  onFocus={() => handleFocus("nature")}
                  onBlur={() => handleBlur("nature")}
                  state={focusStates["nature"]}
                />
                <SelectControl
                  label="Dự án"
                  dataOption={typeApartments}
                  placeholder="Chọn dự án"
                  selectedMain={watch("project")}
                  onChange={onchangeProject}
                  onFocus={() => handleFocus("project")}
                  onBlur={() => handleBlur("project")}
                  state={focusStates["project"]}
                />
              </Flex>
              <Flex
                justifyContent={"space-between"}
                alignItems={"flex-start"}
                gap={"27px"}
                mb={"16px"}
              >
                <SelectControl
                  label="Phân khu"
                  dataOption={typeApartments}
                  placeholder="Chọn phân khu"
                  selectedMain={watch("subdivision")}
                  onChange={onchangeSubdivision}
                  onFocus={() => handleFocus("subdivision")}
                  onBlur={() => handleBlur("subdivision")}
                  state={focusStates["subdivision"]}
                />
                <Flex
                  justifyContent={"space-between"}
                  alignItems={"flex-start"}
                  gap={"17px"}
                >
                  <InputControl
                    type="number"
                    label="DT đất (m2)"
                    placeholder="ví dụ:120m2"
                    onFocus={() => handleFocus("landArea")}
                    onBlur={() => handleBlur("landArea")}
                    state={focusStates["landArea"]}
                    errors={errors.landArea}
                    register={register("landArea")}
                  />
                  <InputControl
                    type="number"
                    label="DT xây dựng (m2)"
                    placeholder="ví dụ:120m2"
                    onFocus={() => handleFocus("constructionArea")}
                    onBlur={() => handleBlur("constructionArea")}
                    state={focusStates["constructionArea"]}
                    errors={errors.constructionArea}
                    register={register("constructionArea")}
                  />
                </Flex>
              </Flex>
              <Flex
                justifyContent={"space-between"}
                alignItems={"flex-start"}
                gap={"27px"}
                mb={"16px"}
              >
                <Flex
                  justifyContent={"space-between"}
                  alignItems={"flex-start"}
                  gap={"17px"}
                >
                  <InputControl
                    type="number"
                    label="Chiều dài (m)"
                    placeholder="ví dụ:10m"
                    onFocus={() => handleFocus("height")}
                    onBlur={() => handleBlur("height")}
                    state={focusStates["height"]}
                    errors={errors.height}
                    register={register("height")}
                  />
                  <InputControl
                    type="number"
                    label="Chiều rộng (m)"
                    placeholder="ví dụ:15m"
                    onFocus={() => handleFocus("width")}
                    onBlur={() => handleBlur("width")}
                    state={focusStates["width"]}
                    errors={errors.width}
                    register={register("width")}
                  />
                </Flex>
                <Flex
                  justifyContent={"space-between"}
                  alignItems={"flex-start"}
                  gap={"17px"}
                  maxW={"259px"}
                >
                  <InputControl
                    type="number"
                    label="Phòng ngủ"
                    placeholder="Số phòng ngủ"
                    onFocus={() => handleFocus("bedroom")}
                    onBlur={() => handleBlur("bedroom")}
                    state={focusStates["bedroom"]}
                    errors={errors.bedroom}
                    register={register("bedroom")}
                  />
                  <InputControl
                    type="number"
                    label="Phòng VS"
                    placeholder="Số phòng VS"
                    onFocus={() => handleFocus("toilet")}
                    onBlur={() => handleBlur("toilet")}
                    state={focusStates["toilet"]}
                    errors={errors.toilet}
                    register={register("toilet")}
                  />
                </Flex>
              </Flex>
              <Flex
                justifyContent={"space-between"}
                alignItems={"flex-start"}
                gap={"27px"}
                mb={"16px"}
              >
                <InputControl
                  type="number"
                  label="Giá trị"
                  placeholder="Ví dụ: 1 tỷ 950 triệu "
                  onFocus={() => handleFocus("price")}
                  onBlur={() => handleBlur("price")}
                  state={focusStates["price"]}
                  errors={errors.price}
                  register={register("price")}
                />
                <InputControl
                  type="number"
                  label="Giá tính hoa hồng"
                  placeholder="Ví dụ: 150 triệu "
                  onFocus={() => handleFocus("priceHH")}
                  onBlur={() => handleBlur("priceHH")}
                  state={focusStates["priceHH"]}
                  errors={errors.priceHH}
                  register={register("priceHH")}
                />
              </Flex>
            </Box>
            <Flex p={"24px"} justifyContent={"flex-end"}>
              <Button
                type="submit"
                w={"164px"}
                h={"44px"}
                color={"var(--color-main)"}
                backgroundImage="var(--color-button)"
                _hover={{
                  filter: "brightness(80%)",
                }}
              >
                Thêm căn hộ
              </Button>
            </Flex>
          </Flex>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default FormNew;
