import SignIn from "../pages/signIn/index";
import Employee from "../pages/employee/index";
import NotFoundPage from "../pages/notFoundPage";
import JobTitle from "../pages/jobTitle/index";
import Apartments from "../pages/apartments/index";
import OnLeave from "../pages/onLeave/index";
import ReceiveSalary from "../pages/receiveSalary/index";
import TimeKeeping from "../pages/timeKeeping";
import Customers from "../pages/customers";
import Booking from "../pages/booking";
import OpenForSale from "../pages/openForSale";
import ProjectManagement from "../pages/projectManagement";
import PersonnelCategories from "../pages/personnelCategories";
import OnLeaveCategories from "../pages/onLeaveCategories";
import ProjectCategories from "../pages/projectCategories";
import AdminManagement from "../pages/adminManagement";
import { ROLES_CONFIG } from "../utils";
import Contract from "../pages/contract";
import bookingManagement from "../pages/bookingManagement";
import Locking from "../pages/locking";

export const routes = [
  {
    path: "/login",
    page: SignIn,
    isShowHeader: false,
    role: ROLES_CONFIG.ROUTES.SignIn,
  },

  {
    path: "*",
    page: NotFoundPage,
    isShowHeader: true,
    role: ROLES_CONFIG.ROUTES.NotFoundPage,
  },
  // menu 1
  {
    path: "/",
    page: Employee,
    isShowHeader: true,
    role: ROLES_CONFIG.ROUTES.Employee,
    comment: "Quản lý nhân viên",
  },
  {
    path: "/timekeeping",
    page: TimeKeeping,
    isShowHeader: true,
    role: ROLES_CONFIG.ROUTES.TimeKeeping,
    comment: "Quản lý chấm công",
  },
  {
    path: "/on-leave",
    page: OnLeave,
    isShowHeader: true,
    role: ROLES_CONFIG.ROUTES.OnLeave,
    comment: "Xin nghỉ phép",
  },
  {
    path: "/job-title",
    page: JobTitle,
    isShowHeader: true,
    role: ROLES_CONFIG.ROUTES.JobTitle,
    comment: "Xác nhận công và chức danh hàng tháng",
  },
  {
    path: "/receive-salary",
    page: ReceiveSalary,
    isShowHeader: true,
    role: ROLES_CONFIG.ROUTES.ReceiveSalary,
    comment: "Xin nhận lương",
  },
  // menu 2
  {
    path: "/project-management",
    page: ProjectManagement,
    isShowHeader: true,
    role: ROLES_CONFIG.ROUTES.ProjectManagement,
    comment: "Quản lý dự án",
  },
  {
    path: "/apartments",
    page: Apartments,
    isShowHeader: true,
    role: ROLES_CONFIG.ROUTES.Apartments,
    comment: "Quản lý sản phẩm và căn hộ",
  },
  {
    path: "/booking",
    page: Booking,
    isShowHeader: true,
    role: ROLES_CONFIG.ROUTES.Booking,
    comment: "Đợt nhận booking",
  },
  {
    path: "/hdmb",
    page: Contract,
    isShowHeader: true,
    role: ROLES_CONFIG.ROUTES.Contract,
    comment: "Đợt nhận contract",
  },
  // menu 3
  {
    path: "/open-for-sale",
    page: OpenForSale,
    isShowHeader: true,
    role: ROLES_CONFIG.ROUTES.OpenForSale,
    comment: "Quản lý đợt mở bán",
  },
  {
    path: "/customer",
    page: Customers,
    isShowHeader: true,
    role: ROLES_CONFIG.ROUTES.Customers,
    comment: "Quản lý khách hàng",
  },
  {
    path: "/bookingManagement",
    page: bookingManagement,
    isShowHeader: true,
    role: ROLES_CONFIG.ROUTES.BookingManagement,
    comment: "Quản lý booking",
  },
  {
    path: "/locking",
    page: Locking,
    isShowHeader: true,
    role: ROLES_CONFIG.ROUTES.Locking,
    comment: "Quản lý locking",
  },
  // menu 4
  {
    path: "/personnel-categories",
    page: PersonnelCategories,
    isShowHeader: true,
    role: ROLES_CONFIG.ROUTES.PersonnelCategories,
    comment: "Nhân sự",
  },
  {
    path: "/on-leave-categories",
    page: OnLeaveCategories,
    isShowHeader: true,
    role: ROLES_CONFIG.ROUTES.OnLeaveCategories,
    comment: "Công và nghỉ phép",
  },
  {
    path: "/project-categories",
    page: ProjectCategories,
    isShowHeader: true,
    role: ROLES_CONFIG.ROUTES.ProjectCategories,
    comment: "Dự án",
  },
  // menu 5
  {
    path: "/admin-management",
    page: AdminManagement,
    isShowHeader: true,
    role: ROLES_CONFIG.ROUTES.AdminManagement,
    comment: "Quản lý admin",
  },
];
