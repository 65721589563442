import React from "react";
import {
  Box,
  Flex,
  Text,
} from "@chakra-ui/react";
import TableReceiveSalary from "./tableReceiveSalary";

export default function ReceiveSalary() {

  return (
    <Box
      w={{ base: "100%" }}
      backgroundColor="var(--color-backgroundmain)"
      p={{ base: "24px 16px 16px" }}
    >
      <Flex alignItems={"center"} justifyContent={"space-between"}>
        <Flex alignItems={"center"} gap={{ base: "74px" }}>
          <Text fontSize={{ base: "20px" }} fontWeight={600}>
            Xin nhận lương
          </Text>
        </Flex>
      </Flex>
      <TableReceiveSalary />
    </Box>
  );
}
