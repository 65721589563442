import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  Image,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import iconButton from "../../assets/images/employee/icon-button.svg";
import TableApartments from "./tableApartments";
import FormNew from "./formApartments/fromNew";
import iconExport from "../../assets/images/product/iconExport.svg";
import iconPlus from "../../assets/images/apartment/plus.svg";
import iconFile from "../../assets/images/apartment/Frame.svg";
import * as XLSX from "xlsx";
import toast from "react-hot-toast";
import PopupImport from "../../components/popup/PopupImport";

export default function Apartment() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [tableData, setTableData] = useState({
    headers: [
      {
        label: "",
        key: "",
      },
      {
        label: "STT",
        key: "",
      },
      {
        label: "Mã nhân viên",
        key: "code",
      },
      {
        label: "Tên căn hộ",
        key: "nameApartment",
      },
      {
        label: "DT đất",
        key: "landArea",
      },
      {
        label: "DT xây dựng",
        key: "constructionArea",
      },
      {
        label: "Chiều rộng",
        key: "width",
      },
      {
        label: "Chiều dài",
        key: "height",
      },
      {
        label: "Phòng ngủ",
        key: "bedroom",
      },
      {
        label: "Phòng VS",
        key: "toilet",
      },
      {
        label: "Hướng",
        key: "direction",
      },
      {
        label: "Loại hình",
        key: "type",
      },
      {
        label: "Tính chất",
        key: "nature",
      },
      {
        label: "Dự án",
        key: "project",
      },
      {
        label: "Phân khu",
        key: "subdivision",
      },
      {
        label: "Giá trị",
        key: "price",
      },
      {
        label: "Giá tính HH",
        key: "priceHH",
      },
    ],
    data: [
      {
        code: "S3-1208",
        nameApartment: "Tên căn hộ",
        landArea: "150m2",
        constructionArea: "150m2",
        width: "10m2",
        height: "10m2",
        bedroom: "3",
        toilet: "2",
        direction: "Tây nam bắc",
        type: "Căn hộ CC",
        nature: "Căn hộ CC",
        project:
          "THE DIAMOND RESIDENCE THE DIAMOND RESIDENCE THE DIAMOND RESIDENCE THE DIAMOND RESIDENCE THE DIAMOND RESIDENCETHE DIAMOND RESIDENCE",
        subdivision: "Phân khu A",
        price: "1 tỷ 950 triệu",
        priceHH: "352 triệu",
      },
      {
        code: "S3-1208",
        nameApartment: "Tên căn hộ",
        landArea: "150m2",
        constructionArea: "150m2",
        width: "10m2",
        height: "10m2",
        bedroom: "3",
        toilet: "2",
        direction: "Tây nam bắc",
        type: "Căn hộ CC",
        nature: "Căn hộ CC",
        project: "THE DIAMOND RESIDENCE",
        subdivision: "Phân khu A",
        price: "1 tỷ 950 triệu",
        priceHH: "352 triệu",
      },
      {
        code: "S3-1208",
        nameApartment: "Tên căn hộ",
        landArea: "150m2",
        constructionArea: "150m2",
        width: "10m2",
        height: "10m2",
        bedroom: "3",
        toilet: "2",
        direction: "Tây nam bắc",
        type: "Căn hộ CC",
        nature: "Căn hộ CC",
        project: "THE DIAMOND RESIDENCE",
        subdivision: "Phân khu A",
        price: "1 tỷ 950 triệu",
        priceHH: "352 triệu",
      },
      {
        code: "S3-1208",
        nameApartment: "Tên căn hộ",
        landArea: "150m2",
        constructionArea: "150m2",
        width: "10m2",
        height: "10m2",
        bedroom: "3",
        toilet: "2",
        direction: "Tây nam bắc",
        type: "Căn hộ CC",
        nature: "Căn hộ CC",
        project: "THE DIAMOND RESIDENCE",
        subdivision: "Phân khu A",
        price: "1 tỷ 950 triệu",
        priceHH: "352 triệu",
      },
      {
        code: "S3-1208",
        nameApartment: "Tên căn hộ",
        landArea: "150m2",
        constructionArea: "150m2",
        width: "10m2",
        height: "10m2",
        bedroom: "3",
        toilet: "2",
        direction: "Tây nam bắc",
        type: "Căn hộ CC",
        nature: "Căn hộ CC",
        project: "THE DIAMOND RESIDENCE",
        subdivision: "Phân khu A",
        price: "1 tỷ 950 triệu",
        priceHH: "352 triệu",
      },
      {
        code: "S3-1208",
        nameApartment: "Tên căn hộ",
        landArea: "150m2",
        constructionArea: "150m2",
        width: "10m2",
        height: "10m2",
        bedroom: "3",
        toilet: "2",
        direction: "Tây nam bắc",
        type: "Căn hộ CC",
        nature: "Căn hộ CC",
        project: "THE DIAMOND RESIDENCE",
        subdivision: "Phân khu A",
        price: "1 tỷ 950 triệu",
        priceHH: "352 triệu",
      },
      {
        code: "S3-1208",
        nameApartment: "Tên căn hộ",
        landArea: "150m2",
        constructionArea: "150m2",
        width: "10m2",
        height: "10m2",
        bedroom: "3",
        toilet: "2",
        direction: "Tây nam bắc",
        type: "Căn hộ CC",
        nature: "Căn hộ CC",
        project: "THE DIAMOND RESIDENCE",
        subdivision: "Phân khu A",
        price: "1 tỷ 950 triệu",
        priceHH: "352 triệu",
      },
      {
        code: "S3-1208",
        nameApartment: "Tên căn hộ",
        landArea: "150m2",
        constructionArea: "150m2",
        width: "10m2",
        height: "10m2",
        bedroom: "3",
        toilet: "2",
        direction: "Tây nam bắc",
        type: "Căn hộ CC",
        nature: "Căn hộ CC",
        project: "THE DIAMOND RESIDENCE",
        subdivision: "Phân khu A",
        price: "1 tỷ 950 triệu",
        priceHH: "352 triệu",
      },
      {
        code: "S3-1208",
        nameApartment: "Tên căn hộ",
        landArea: "150m2",
        constructionArea: "150m2",
        width: "10m2",
        height: "10m2",
        bedroom: "3",
        toilet: "2",
        direction: "Tây nam bắc",
        type: "Căn hộ CC",
        nature: "Căn hộ CC",
        project: "THE DIAMOND RESIDENCE",
        subdivision: "Phân khu A",
        price: "1 tỷ 950 triệu",
        priceHH: "352 triệu",
      },
      {
        code: "S3-1208",
        nameApartment: "Tên căn hộ",
        landArea: "150m2",
        constructionArea: "150m2",
        width: "10m2",
        height: "10m2",
        bedroom: "3",
        toilet: "2",
        direction: "Tây nam bắc",
        type: "Căn hộ CC",
        nature: "Căn hộ CC",
        project: "THE DIAMOND RESIDENCE",
        subdivision: "Phân khu A",
        price: "1 tỷ 950 triệu",
        priceHH: "352 triệu",
      },
      {
        code: "S3-1208",
        nameApartment: "Tên căn hộ",
        landArea: "150m2",
        constructionArea: "150m2",
        width: "10m2",
        height: "10m2",
        bedroom: "3",
        toilet: "2",
        direction: "Tây nam bắc",
        type: "Căn hộ CC",
        nature: "Căn hộ CC",
        project: "THE DIAMOND RESIDENCE",
        subdivision: "Phân khu A",
        price: "1 tỷ 950 triệu",
        priceHH: "352 triệu",
      },
      {
        code: "S3-1208",
        nameApartment: "Tên căn hộ",
        landArea: "150m2",
        constructionArea: "150m2",
        width: "10m2",
        height: "10m2",
        bedroom: "3",
        toilet: "2",
        direction: "Tây nam bắc",
        type: "Căn hộ CC",
        nature: "Căn hộ CC",
        project: "THE DIAMOND RESIDENCE",
        subdivision: "Phân khu A",
        price: "1 tỷ 950 triệu",
        priceHH: "352 triệu",
      },
      {
        code: "S3-1208",
        nameApartment: "Tên căn hộ",
        landArea: "150m2",
        constructionArea: "150m2",
        width: "10m2",
        height: "10m2",
        bedroom: "3",
        toilet: "2",
        direction: "Tây nam bắc",
        type: "Căn hộ CC",
        nature: "Căn hộ CC",
        project: "THE DIAMOND RESIDENCE",
        subdivision: "Phân khu A",
        price: "1 tỷ 950 triệu",
        priceHH: "352 triệu",
      },
    ],
  });

  const [popupVisible, setPopupVisible] = useState(false);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Hiện thông báo tải file
      const loadingToastId = toast.loading("Đang tải file...");

      const reader = new FileReader();
      reader.onload = (e) => {
        const binaryStr = e.target.result;
        const workbook = XLSX.read(binaryStr, { type: "binary" });

        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);

        setTableData((prevState) => ({
          ...prevState,
          data: jsonData,
        }));

        toast.success("Tải file thành công!");
        toast.dismiss(loadingToastId);
        event.target.value = null;
      };

      reader.onerror = () => {
        toast.error("Đã xảy ra lỗi khi tải file.");
        toast.dismiss(loadingToastId);
      };

      reader.readAsBinaryString(file);
    } else {
      toast.error("Vui lòng chọn một file.");
    }
  };

  //excel
  const handleDownload = (data) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");

    XLSX.writeFile(workbook, "data.csv");
  };

  return (
    <Box
      w={{ base: "100%" }}
      backgroundColor="var(--color-backgroundmain)"
      p={{ base: "24px 16px 16px" }}
    >
      <Flex alignItems={"center"} justifyContent={"space-between"}>
        <Flex alignItems={"center"} gap={{ base: "74px" }}>
          <Text fontSize={{ base: "20px" }} fontWeight={600}>
            Quản lý sản phẩm
          </Text>
        </Flex>
        <Flex alignItems={"center"} gap={"16px"}>
          <Button
            w={"176px"}
            h={"44px"}
            display={"flex"}
            alignItems={"center"}
            p={"10px 16px 10px 12px"}
            gap={"8px"}
            backgroundImage="var(--linear-gradient-excel)"
            _hover={{
              filter: "brightness(90%)",
            }}
            onClick={() => handleDownload(tableData.data)}
          >
            <Image src={iconExport} />
            <Text
              fontSize={{ base: "15px" }}
              fontWeight={600}
              color="var(--color-main)"
            >
              Xuất exel
            </Text>
          </Button>
          <Menu>
            <MenuButton
              w={"180px"}
              h={"44px"}
              as={Button}
              backgroundImage="var(--color-button)"
              _hover={{
                filter: "brightness(90%)",
              }}
              _active={{
                filter: "brightness(90%)",
              }}
            >
              <Flex alignItems={"center"} p={"10px 0px 10px 0px"} gap={"8px"}>
                <Image src={iconButton} />
                <Text
                  fontSize={{ base: "15px" }}
                  fontWeight={600}
                  color="var(--color-main)"
                >
                  Sản phẩm mới
                </Text>
              </Flex>
            </MenuButton>
            <MenuList>
              <MenuItem
                onClick={() => {
                  onOpen();
                }}
                gap={"16px"}
                p={"10px 16px"}
                borderBottom={"0.5px solid var(--border-input-employee)"}
                _hover={{
                  filter: "brightness(96%)",
                }}
              >
                <Image src={iconPlus} /> Tạo sản phẩm mới
              </MenuItem>
              <MenuItem
                onClick={() => setPopupVisible(true)}
                gap={"16px"}
                p={"10px 16px"}
                _hover={{
                  filter: "brightness(96%)",
                }}
              >
                <Image src={iconFile} /> Tải file lên
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>
      <Box mt={{ base: "23px" }}>
        <TableApartments tableData={tableData} />
      </Box>
      <FormNew isOpen={isOpen} onClose={onClose} />
      {popupVisible && (
        <PopupImport
          isOpen={popupVisible}
          onClose={() => setPopupVisible(false)}
          refetchCheckInData={tableData.data}
        />
      )}
    </Box>
  );
}
