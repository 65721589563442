import toast from "react-hot-toast";

const { clientAPI } = require("../client");
export const investorCall = {
  create: async (data) => {
    try {
      const response = await clientAPI("post", "/investor", data);
      return response;
    } catch (error) {
      console.error("Create error:", error);
      const errorMessages = error?.response?.data?.errors?.map(
        (err) => err.message
      ) || [
        error?.response?.data?.message ||
          error?.response?.statusText ||
          error?.message ||
          "An unexpected error occurred.",
      ];

      errorMessages.forEach((message) => {
        toast.error(`${message}`);
      });
      throw error;
    }
  },
  update: async (id,data) => {
    try {
      const response = await clientAPI("put", `/investor/${id}`, data);
      return response;
    } catch (error) {
      console.error("Update error:", error);
      const errorMessages = error?.response?.data?.errors?.map(
        (err) => err.message
      ) || [
        error?.response?.data?.message ||
          error?.response?.statusText ||
          error?.message ||
          "An unexpected error occurred.",
      ];

      errorMessages.forEach((message) => {
        toast.error(`${message}`);
      });
      throw error;
    }
  },
  delete: async (id) => {
    try {
      const response = await clientAPI("delete", `/investor/${id}`);
      return response;
    } catch (error) {
      console.error("Delete error:", error);
      const errorMessages = error?.response?.data?.errors?.map(
        (err) => err.message
      ) || [
        error?.response?.data?.message ||
          error?.response?.statusText ||
          error?.message ||
          "An unexpected error occurred.",
      ];

      errorMessages.forEach((message) => {
        toast.error(`${message}`);
      });
      throw error;
    }
  },
  updateHiddenStatus: async (id) => {
    try {
      const response = await clientAPI("put", `/investor/updateHiddenStatus/${id}`);
      return response;
    } catch (error) {
      console.error("Delete error:", error);
      const errorMessages = error?.response?.data?.errors?.map(
        (err) => err.message
      ) || [
        error?.response?.data?.message ||
          error?.response?.statusText ||
          error?.message ||
          "An unexpected error occurred.",
      ];

      errorMessages.forEach((message) => {
        toast.error(`${message}`);
      });
      throw error;
    }
  },
};