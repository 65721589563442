import toast from "react-hot-toast";

const { clientAPI } = require("../client");
export const commonCall = {
  uploadAvatar: async (file, uploadFolder) => {
    try {
      const formData = new FormData();
      if (file) formData.append("file", file);
      if (uploadFolder) formData.append("uploadFolder", uploadFolder);
      const response = await clientAPI(
        "post",
        "/common/upload/image",
        formData
      );
      return response;
    } catch (error) {
      console.error("Upload error:", error);
      const errorMessages = error?.response?.data?.errors?.map(
        (err) => err.message
      ) || [
        error?.response?.data?.message ||
          error?.response?.statusText ||
          error?.message ||
          "An unexpected error occurred.",
      ];

      errorMessages.forEach((message) => {
        toast.error(`${message}`);
      });
      throw error;
    }
  },
  uploadFile: async (file, uploadFolder, dataFile) => {
    try {
      const formData = new FormData();
      if (file) formData.append("file", file);
      if (uploadFolder) formData.append("uploadFolder", uploadFolder);
      if (dataFile && Object.keys(dataFile).length > 0) {
        Object.entries(dataFile).forEach(([key, value]) => {
          formData.append(key, value);
        });
      }
      const response = await clientAPI(
        "post",
        "/common/upload/file",
        formData
      );
      return response;
    } catch (error) {
      console.error("Upload error:", error);
      const errorMessages = error?.response?.data?.errors?.map(
        (err) => err.message
      ) || [
        error?.response?.data?.message ||
          error?.response?.statusText ||
          error?.message ||
          "An unexpected error occurred.",
      ];

      errorMessages.forEach((message) => {
        toast.error(`${message}`);
      });
      throw error;
    }
  },
  reNameFile: async (dataFile) => {
    try {
      const response = await clientAPI(
        "post",
        "/common/rename/file",
        dataFile
      );
      return response;
    } catch (error) {
      console.error("Upload error:", error);
      const errorMessages = error?.response?.data?.errors?.map(
        (err) => err.message
      ) || [
        error?.response?.data?.message ||
          error?.response?.statusText ||
          error?.message ||
          "An unexpected error occurred.",
      ];

      errorMessages.forEach((message) => {
        toast.error(`${message}`);
      });
      throw error;
    }
  },
};
