import iconEmployee from "../assets/images/sider/employee.svg";
import iconSourceOfGoods from "../assets/images/sider/sourceofgoods.svg";
import iconSell from "../assets/images/sider/sell.svg";
import iconSales from "../assets/images/sider/sales.svg";
import iconCategory from "../assets/images/sider/category.svg";
import iconAdmin from "../assets/images/sider/Frame.svg";
import { ROLES_CONFIG } from ".";
export const enumMenu = [
  {
    path: "#",
    name: "Quản lý nhân sự",
    icon: iconEmployee,
    role: ROLES_CONFIG.MENU.MENU_QLNS.role,
    children: [
      {
        path: "/",
        name: "Quản lý nhân viên",
        role: ROLES_CONFIG.MENU.MENU_QLNS.CHILDREN.CHILDREN_QLNV.role,
      },
      {
        path: "/timekeeping",
        name: "Quản lý chấm công",
        role: ROLES_CONFIG.MENU.MENU_QLNS.CHILDREN.CHILDREN_QLCC.role,
      },
      {
        path: "/on-leave",
        name: "Xin nghỉ phép",
        role: ROLES_CONFIG.MENU.MENU_QLNS.CHILDREN.CHILDREN_XNP.role,
      },
      {
        path: "/job-title",
        name: "Xác nhận công và chức danh hàng tháng",
        role: ROLES_CONFIG.MENU.MENU_QLNS.CHILDREN.CHILDREN_XNC_AND_CDHT.role,
      },
      {
        path: "/receive-salary",
        name: "Xin nhận lương",
        role: ROLES_CONFIG.MENU.MENU_QLNS.CHILDREN.CHILDREN_XNL.role,
      },
    ],
  },
  {
    path: "#",
    name: "Quản lý nguồn hàng",
    icon: iconSourceOfGoods,
    role: ROLES_CONFIG.MENU.MENU_QLNH.role,
    children: [
      {
        path: "/project-management",
        name: "Quản lý dự án",
        role: ROLES_CONFIG.MENU.MENU_QLNH.CHILDREN.CHILDREN_QLDA.role,
      },
      {
        path: "/apartments",
        name: "Quản lý sản phẩm và căn hộ",
        role: ROLES_CONFIG.MENU.MENU_QLNH.CHILDREN.CHILDREN_QLSP_AND_CH.role,
      },
      {
        path: "/booking",
        name: "Đợt nhận booking",
        role: ROLES_CONFIG.MENU.MENU_QLNH.CHILDREN.CHILDREN_DN_BOOKING.role,
      },
    ],
  },
  {
    path: "#",
    name: "Quản lý bán hàng",
    icon: iconSell,
    role: ROLES_CONFIG.MENU.MENU_QLBH.role,
    children: [
      {
        path: "/open-for-sale",
        name: "Quản lý đợt mở bán",
        role: ROLES_CONFIG.MENU.MENU_QLBH.CHILDREN.CHILDREN_QLDMB.role,
      },
      {
        path: "/customer",
        name: "Quản lý khách hàng",
        role: ROLES_CONFIG.MENU.MENU_QLBH.CHILDREN.CHILDREN_QLKH.role,
      },
      {
        path: "/bookingManagement",
        name: "Quản lý booking",
        role: ROLES_CONFIG.MENU.MENU_QLBH.CHILDREN.CHILDREN_QL_BOOKING.role,
      },
      {
        path: "/locking",
        name: "Quản lý locking",
        role: ROLES_CONFIG.MENU.MENU_QLBH.CHILDREN.CHILDREN_QL_LOCKING.role,
      },
      {
        path: "/hdmb",
        name: "Xác nhận HĐMB",
        role: ROLES_CONFIG.MENU.MENU_QLBH.CHILDREN.CHILDREN_XNHDMB.role,
      },
    ],
  },
  {
    path: "#",
    name: "Quản lý danh mục",
    icon: iconCategory,
    role: ROLES_CONFIG.MENU.MENU_QLDM.role,
    children: [
      {
        path: "/personnel-categories",
        name: "Nhân sự",
        role: ROLES_CONFIG.MENU.MENU_QLDM.CHILDREN.CHILDREN_NS.role,
      },
      {
        path: "/on-leave-categories",
        name: "Công và nghỉ phép",
        role: ROLES_CONFIG.MENU.MENU_QLDM.CHILDREN.CHILDREN_C_AND_NP.role,
      },
      {
        path: "/project-categories",
        name: "Dự án",
        role: ROLES_CONFIG.MENU.MENU_QLDM.CHILDREN.CHILDREN_DA.role,
      },
      {
        path: "/product-categories",
        name: "Sản phẩm",
        role: ROLES_CONFIG.MENU.MENU_QLDM.CHILDREN.CHILDREN_SP.role,
      },
    ],
  },
  {
    path: "#",
    name: "Quản lý doanh số",
    icon: iconSales,
    role: ROLES_CONFIG.MENU.MENU_QLDS.role,
    children: [
      {
        path: "/commission-calculation",
        name: "Tính hoa hồng",
        role: ROLES_CONFIG.MENU.MENU_QLDS.CHILDREN.CHILDREN_THH.role,
      },
      {
        path: "/commission-confirmation",
        name: "Xác nhận hoa hồng",
        role: ROLES_CONFIG.MENU.MENU_QLDS.CHILDREN.CHILDREN_XNHH.role,
      },
      {
        path: "/sales-management",
        name: "Quản lý doanh số",
        role: ROLES_CONFIG.MENU.MENU_QLDS.CHILDREN.CHILDREN_QLDS.role,
      },
    ],
  },
  {
    path: "/admin-management",
    name: "Quản lý admin",
    icon: iconAdmin,
    role: ROLES_CONFIG.MENU.MENU_QL_ADMIN.role,
  },
];
