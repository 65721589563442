import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
  HStack,
  Img,
  Skeleton,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { formatTableValue } from "./formatTable";
import chevronLeft from "../../assets/images/employee/chevron-left.svg";
import chevronRight from "../../assets/images/employee/chevron-right.svg";
import ReactPaginate from "react-paginate";
import { useForm } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import { useReceiveSalary } from "../../hooks/useReceiveSalary";
import useDebounce from "../../hooks/useDebounce";
import toast from "react-hot-toast";
import { receiveSalaryCall } from "../../api/receiveSalary";
import SearchableSelect from "../../components/selectForm";
import { useCategories } from "../../hooks/useCategories";

const TableReceiveSalary = () => {
  const {
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });

  // table
  const [search, setSearch] = useState(false);
  const [saleBlockId, setSaleBlockId] = useState(null);
  const [saleAreaId, setSaleAreaId] = useState(null);
  const debouncedSearchTerm = useDebounce(search, 500);
  const onChangeSearch = useCallback((e) => {
    const { value } = e.target;
    setSearch(value);
  }, []);

  const {
    onReceiveSalary,
    totalPages,
    setCurrentPage,
    refetch,
    isLoading,
    isRefetching,
    currentPage,
    entries,
    startEntry,
    endEntry,
  } = useReceiveSalary(debouncedSearchTerm, saleBlockId, saleAreaId);

  const { constantData } = useCategories();

  useEffect(() => {
    setSearch(debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  const tableData = {
    headers: [
      {
        label: "STT",
        key: "",
      },
      {
        label: "Trạng thái",
        key: "status",
      },
      {
        label: "Mã nhân viên",
        key: "codeEmployee",
      },
      {
        label: "Tên nhân viên",
        key: "nameEmployee",
      },
      {
        label: "Vùng",
        key: "saleArea",
      },
      {
        label: "Khối",
        key: "saleBlock",
      },
      {
        label: "Thời gian yêu cầu",
        key: "createdAt",
      },
    ],
    data: onReceiveSalary,
  };

  const formatEntries = (entries) =>
    Object.entries(entries || {}).map(([key, value]) => ({ key, value }));

  const saleAreas = useMemo(
    () => formatEntries(constantData?.saleAreas),
    [constantData?.saleAreas]
  );
  const saleBlocks = useMemo(
    () => formatEntries(constantData?.saleBlocks),
    [constantData?.saleBlocks]
  );

  const handleEdit = async (data,id) => {
    const toastEdit = toast.loading("Đang cập nhập trạng thái ...");
    const dataFormat = {
        status: data,
    }

    try {
      await receiveSalaryCall.update(id, dataFormat);
      await refetch();
      toast.success("Cập nhập trạng thái thành công");
    } catch (error) {
      console.error("Error:", error);
      toast.error("Có lỗi xảy ra khi cập nhập trạng thái");
    }
    toast.dismiss(toastEdit);
  };

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
    refetch();
  };

  return (
    <>
      <Flex flexDirection={"column"} gap={{ base: "16px" }} mt={"16px"} >
        <Flex
          backgroundColor="var(--color-main)"
          borderRadius={{ base: "12px" }}
          flexDirection={"column"}
        >
          <Flex
            w={{ base: "90%" }}
            alignItems={"center"}
            gap={{ base: "32px" }}
            p={"16px 0px 12px 16px"}
          >
            <InputGroup
              maxW="506px"
              borderRadius={{ base: "8px" }}
              w={{ base: "40%" }}
            >
              <Input
                placeholder="Nhập MVN, họ tên, sđt..."
                type="text"
                borderRadius={{ base: "8px" }}
                border={{ base: "1px solid var(--color-secondary)" }}
                onChange={onChangeSearch}
              />
              <InputRightElement
                borderTopRightRadius={"8px"}
                borderBottomRightRadius={"8px"}
                pointerEvents="none"
                backgroundImage="var(--color-button)"
              >
                <SearchIcon color="var(--color-main)" />
              </InputRightElement>
            </InputGroup>

            <Flex alignItems={"center"} gap={{ base: "12px" }}>
              <SearchableSelect
                isShowSearch={false}
                options={saleAreas}
                initialSelectedOption={{ key: 0, value: "Chọn vùng" }}
                setValue={setSaleAreaId}
              />
              <SearchableSelect
                isShowSearch={false}
                options={saleBlocks}
                initialSelectedOption={{ key: 0, value: "Chọn khối" }}
                setValue={setSaleBlockId}
              />
            </Flex>
          </Flex>
          <Box width={"100%"} height={"100%"}>
            <Skeleton isLoaded={!isLoading && !isRefetching}>
              <TableContainer w={"100%"}>
                <Table w={"100%"} variant="unstyled">
                  <Thead
                    w={"100%"}
                    h={"41px"}
                    color="white"
                    fontWeight="400"
                    backgroundColor="#F9FAFD"
                  >
                    <Tr h={{ base: "41px" }}>
                      {tableData.headers.map((e, index) => {
                        let width = "auto";
                        let textAlign = "auto";
                        if (e.key === "") {
                          width = "2%";
                          textAlign = "center";
                        } else if (
                          e.key === "status" ||
                          e.key === "codeEmployee" ||
                          e.key === "nameEmployee" ||
                          e.key === "saleArea" ||
                          e.key === "saleBlock"
                        ) {
                          width = "15%";
                          textAlign = "start";
                        } else {
                          width = "15%";
                          textAlign = "end";
                        }
                        return (
                          <Td
                            p={"16px 16px 8px 16px"}
                            key={index}
                            border={"none"}
                            color={"#51607B"}
                            fontSize={{ base: "14px" }}
                            w={width}
                          >
                            <Box textAlign={textAlign}>{e.label}</Box>
                          </Td>
                        );
                      })}
                    </Tr>
                  </Thead>
                  <Tbody w={"100%"} h={"100%"}>
                    {tableData.data?.length > 0 ? (
                      tableData.data?.map((e, rowIndex) => {
                        const items = { ...e };
                        delete items.id;
                        const keyValues = Object.keys(items);
                        return (
                          <Tr key={rowIndex} h={"52px"}>
                            <Td
                              w={"2%"}
                              fontSize={"14px"}
                              lineHeight={"20px"}
                              fontWeight={500}
                              p={"16px 16px 8px 16px"}
                              bg={
                                rowIndex % 2 === 0
                                  ? "var(--color-main)"
                                  : "var(--fill-avatar)"
                              }
                            >
                              {rowIndex+1}
                            </Td>
                            {keyValues.map((keyvalue, index) => {
                              let width = "auto";
                              let widthText = "100%";
                              let textAlign = "auto";
                              let whiteSpace = "inherit";
                              if (keyvalue === "") {
                                width = "2%";
                                textAlign = "center";
                              } else if (
                                keyvalue === "status" ||
                                keyvalue === "codeEmployee" ||
                                keyvalue === "nameEmployee" ||
                                keyvalue === "saleArea" ||
                                keyvalue === "saleBlock"
                              ) {
                                width = "15%";
                                textAlign = "start";
                              } else {
                                width = "15%";
                                textAlign = "end";
                              }
                              return (
                                <Td
                                  p={"16px 16px 8px 16px"}
                                  w={width}
                                  key={index}
                                  bg={
                                    rowIndex % 2 === 0
                                      ? "var(--color-main)"
                                      : "var(--fill-avatar)"
                                  }
                                >
                                  <Box
                                    fontSize={{ base: "14px" }}
                                    lineHeight={"19.6px"}
                                    textAlign={textAlign}
                                    fontWeight={500}
                                    color={"#293755"}
                                    w={widthText}
                                    whiteSpace={whiteSpace}
                                    overflow="hidden"
                                    textOverflow="ellipsis"
                                    display="-webkit-box"
                                    sx={{
                                      WebkitLineClamp: "2",
                                      WebkitBoxOrient: "vertical",
                                    }}
                                  >
                                    {keyvalue === "status"
                                      ? formatTableValue(e[keyvalue], keyvalue, handleEdit, e.id)
                                      : formatTableValue(e[keyvalue], keyvalue)}
                                  </Box>
                                </Td>
                              );
                            })}
                          </Tr>
                        );
                      })
                    ) : (
                      <Tr>
                        <Td
                          colSpan={tableData.headers.length}
                          textAlign="center"
                          padding={"40px 0"}
                        >
                          Không có dữ liệu
                        </Td>
                      </Tr>
                    )}
                  </Tbody>
                </Table>
              </TableContainer>
            </Skeleton>
            <Box mt={10} mr={5} mb={5} ml={5}>
              <Flex alignItems={"center"} justifyContent={"space-between"}>
                <Text
                  fontSize={"14px"}
                  fontWeight={500}
                  lineHeight={"20px"}
                  color={"var(--text-color-Subtittle)"}
                >
                  Showing {startEntry} to {endEntry} of {entries} entries
                </Text>
                <HStack spacing={2} justify="flex-end">
                  <ReactPaginate
                    className="pagination-custom"
                    pageCount={totalPages}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={1}
                    onPageChange={handlePageChange}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                    breakClassName={"ellipsis"}
                    breakLabel={"..."}
                    previousLabel={
                      <Img
                        w={"16px"}
                        minW={"16px"}
                        h={"16px"}
                        src={chevronLeft}
                      />
                    }
                    nextLabel={
                      <Img
                        w={"16px"}
                        minW={"16px"}
                        h={"16px"}
                        src={chevronRight}
                      />
                    }
                    renderOnZeroPageCount={null}
                    initialPage={currentPage - 1}
                  />
                </HStack>
              </Flex>
            </Box>
          </Box>
        </Flex>
      </Flex>
    </>
  );
};
export default TableReceiveSalary;
