import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  Image,
  Button,
} from "@chakra-ui/react";
import CloseIconBlack from "../../../../assets/images/product/closeIconBlack.svg";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { InputControl } from "../../../../components/form/formControls";
import { generateCodeFromName, removeAccents } from "../../../../utils/utils";
import { cityCall } from "../../../../api/city";
import { directionCall } from "../../../../api/direction";
import { propertyCall } from "../../../../api/property";

const schemaType1 = Yup.object().shape({
  code: Yup.string()
    .required("Vui lòng nhập mã thành phố")
    .test("is-unique", "Mã đã tồn tại", async function (value) {
      if (!value) return true;

      const { id } = this.parent;
      if (id) {
        return true;
      }

      return await cityCall.checkCodeUnique(value, id || null);
    }),
  name: Yup.string().optional().required("Vui lòng nhập tên thành phố"),
});

const schemaType2 = Yup.object().shape({
  code: Yup.string()
    .required("Vui lòng nhập mã hướng")
    .test("is-unique", "Mã đã tồn tại", async function (value) {
      if (!value) return true;

      const { id } = this.parent;
      if (id) {
        return true;
      }

      return await directionCall.checkCodeUnique(value, id || null);
    }),
  name: Yup.string().optional().required("Vui lòng nhập tên hướng"),
});

const schemaType3 = Yup.object().shape({
  code: Yup.string()
    .required("Vui lòng nhập mã loại tính chất")
    .test("is-unique", "Mã đã tồn tại", async function (value) {
      if (!value) return true;

      const { id } = this.parent;
      if (id) {
        return true;
      }

      return await propertyCall.checkCodeUnique(value, id || null);
    }),
  name: Yup.string().optional().required("Vui lòng nhập tên loại tính chất"),
});

const schemaType4 = Yup.object().shape({
  code: Yup.string()
    .required("Vui lòng nhập mã nghề nghiệp")
    .test("is-unique", "Mã đã tồn tại", async function (value) {
      if (!value) return true;

      const { id } = this.parent;
      if (id) {
        return true;
      }

      return await propertyCall.checkCodeUnique(value, id || null);
    }),
  name: Yup.string().optional().required("Vui lòng nhập tên nghề nghiệp"),
});

const FormCustom = ({
  isOpen,
  onClose,
  type,
  data,
  defaultValues,
  onSubmit,
  isEdit,
  textAdd,
  textButtonAdd,
  textTitleEdit,
  dataSelect,
}) => {
  const [focusStates, setFocusStates] = useState({});
  const [isCodeEdited, setIsCodeEdited] = useState(false);

  if (Array.isArray(dataSelect)) {
    dataSelect = dataSelect.filter((item) => item?.value !== defaultValues?.id);
  }

  let schema = null;
  if (type === 0) {
    schema = schemaType1;
  } else if (type === 1) {
    schema = schemaType2;
  } else if (type === 2) {
    schema = schemaType3;
  } else {
    schema = schemaType4;
  }

  const {
    reset,
    register,
    handleSubmit,
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValues || {},
  });

  useEffect(() => {
    if (isOpen) {
      if (isEdit) {
        reset(defaultValues);
      } else {
        reset({
          code: "",
          name: "",
        });
      }
    }
  }, [isOpen, isEdit, defaultValues, reset]);

  const submitHandler = (data) => {
    onSubmit(data);
  };

  const handleFocus = (field) => {
    setFocusStates((prev) => ({ ...prev, [field]: true }));
    if (field === "code") {
      setIsCodeEdited(true);
    } else if (field === "name") {
      setIsCodeEdited(false);
    }
  };

  useEffect(() => {
    const name = watch("name");
    const nameWithoutAccents = removeAccents(name || "");
    const generatedCode = generateCodeFromName(nameWithoutAccents);

    if (!isCodeEdited && name) {
      setValue("code", generatedCode, {
        shouldValidate: false,
        shouldDirty: false,
      });
      clearErrors("code");
    }
    if (name === "") {
      setValue("code", "", { shouldValidate: false, shouldDirty: false });
    }
  }, [watch("name"), isCodeEdited, setValue]);

  const handleCodeChange = (e) => {
    setValue("code", e.target.value, {
      shouldValidate: false,
      shouldDirty: true,
    });
  };

  const handleBlur = (field) => {
    setFocusStates((prev) => ({ ...prev, [field]: false }));
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        backgroundColor="var(--color-main)"
        borderRadius={"16px"}
        h={"max-content"}
        w={"100%"}
        maxW={"625px"}
        overflowY={"auto"}
        sx={{
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-thumb": {
            width: "4px",
            borderRadius: "50px",
            background: "var(--color-background)",
          },
          "&::-moz-scrollbar": {
            width: "4px",
          },
          "&::-moz-scrollbar-thumb": {
            width: "4px",
            borderRadius: "50px",
            background: "var(--color-background)",
          },
        }}
      >
        <form onSubmit={handleSubmit(submitHandler)}>
          <Flex flexDirection={"column"}>
            <Flex
              justifyContent={"space-between"}
              alignItems={"center"}
              gap={"10px"}
              padding={"25px 24px"}
              borderBottom={"1px solid var(--bg-line-employee)"}
            >
              <Text
                color="var(--color-header-employee)"
                fontSize={"18px"}
                fontWeight={"600"}
              >
                {isEdit ? textTitleEdit : textAdd}
              </Text>
              <Box cursor={"pointer"} onClick={onClose}>
                <Image src={CloseIconBlack} />
              </Box>
            </Flex>
            <Box flex={1}>
              <Box
                m={"24px auto 0"}
                w={"545px"}
                borderRadius={"8px"}
                overflow={"hidden"}
              >
                {(() => {
                  switch (type) {
                    case 0:
                      return (
                        <Flex
                          justifyContent={"space-between"}
                          alignItems={"flex-start"}
                          gap={"27px"}
                          mt={"16px"}
                        >
                          <InputControl
                            label="Mã thành phố"
                            placeholder="Nhập mã thành phố"
                            errors={errors.code}
                            register={register("code")}
                            onFocus={() => handleFocus("code")}
                            onBlur={() => handleBlur("code")}
                            state={focusStates["code"]}
                            onChange={handleCodeChange}
                            mwidth="100%"
                          />
                          <InputControl
                            label="Tên thành phố"
                            placeholder="Nhập tên thành phố"
                            errors={errors.name}
                            register={register("name")}
                            onFocus={() => handleFocus("name")}
                            onBlur={() => handleBlur("name")}
                            state={focusStates["name"]}
                            // onChange = {handleGeneCode}
                            mwidth="100%"
                          />
                        </Flex>
                      );
                    case 1:
                      return (
                        <Flex
                          justifyContent={"space-between"}
                          alignItems={"flex-start"}
                          gap={"27px"}
                          mt={"16px"}
                        >
                          <InputControl
                            label="Mã hướng"
                            placeholder="Nhập mã hướng"
                            errors={errors.code}
                            register={register("code")}
                            onFocus={() => handleFocus("code")}
                            onBlur={() => handleBlur("code")}
                            state={focusStates["code"]}
                            onChange={handleCodeChange}
                            mwidth="100%"
                          />
                          <InputControl
                            label="Tên hướng"
                            placeholder="Nhập tên hướng"
                            errors={errors.name}
                            register={register("name")}
                            onFocus={() => handleFocus("name")}
                            onBlur={() => handleBlur("name")}
                            state={focusStates["name"]}
                            // onChange = {handleGeneCode}
                            mwidth="100%"
                          />
                        </Flex>
                      );
                    case 2:
                      return (
                        <Flex
                          justifyContent={"space-between"}
                          alignItems={"flex-start"}
                          gap={"27px"}
                          mt={"16px"}
                        >
                          <InputControl
                            label="Mã loại tính chất"
                            placeholder="Nhập mã loại tính chất"
                            errors={errors.code}
                            register={register("code")}
                            onFocus={() => handleFocus("code")}
                            onBlur={() => handleBlur("code")}
                            state={focusStates["code"]}
                            onChange={handleCodeChange}
                            mwidth="100%"
                          />
                          <InputControl
                            label="Tên loại tính chất"
                            placeholder="Nhập tên loại tính chất"
                            errors={errors.name}
                            register={register("name")}
                            onFocus={() => handleFocus("name")}
                            onBlur={() => handleBlur("name")}
                            state={focusStates["name"]}
                            // onChange = {handleGeneCode}
                            mwidth="100%"
                          />
                        </Flex>
                      );
                    default:
                      return (
                        <Flex
                          justifyContent={"space-between"}
                          alignItems={"flex-start"}
                          gap={"27px"}
                          mt={"16px"}
                        >
                          <InputControl
                            label="Mã nghề nghiệp"
                            placeholder="Nhập mã nghề nghiệp"
                            errors={errors.code}
                            register={register("code")}
                            onFocus={() => handleFocus("code")}
                            onBlur={() => handleBlur("code")}
                            state={focusStates["code"]}
                            onChange={handleCodeChange}
                            mwidth="100%"
                          />
                          <InputControl
                            label="Tên nghề nghiệp"
                            placeholder="Nhập tên nghề nghiệp"
                            errors={errors.name}
                            register={register("name")}
                            onFocus={() => handleFocus("name")}
                            onBlur={() => handleBlur("name")}
                            state={focusStates["name"]}
                            // onChange = {handleGeneCode}
                            mwidth="100%"
                          />
                        </Flex>
                      );
                  }
                })()}
              </Box>
            </Box>
            <Flex p={"24px"} gap={"16px"} justifyContent={"flex-end"}>
              {isEdit ? (
                <>
                  <Button
                    w={"112px"}
                    h={"44px"}
                    lineHeight={"18px"}
                    fontSize={"15px"}
                    fontWeight={600}
                    color={"var(--color-option-employee-hover)"}
                    border={"1px solid var(--color-option-employee-hover)"}
                    bg={"var(--color-main)"}
                    onClick={onClose}
                    _hover={{
                      filter: "brightness(90%)",
                    }}
                  >
                    Hủy
                  </Button>
                  <Button
                    type="submit"
                    w={"112px"}
                    h={"44px"}
                    lineHeight={"18px"}
                    fontSize={"15px"}
                    fontWeight={600}
                    color={"var(--color-main)"}
                    bg={"var(--linear-gradient-employee)"}
                    _hover={{
                      filter: "brightness(90%)",
                    }}
                  >
                    Lưu
                  </Button>
                </>
              ) : (
                <Button
                  type="submit"
                  padding={"13px 20px"}
                  lineHeight={"18px"}
                  fontSize={"15px"}
                  fontWeight={600}
                  h={"44px"}
                  color={"var(--color-main)"}
                  bg={"var(--linear-gradient-employee)"}
                  _hover={{
                    filter: "brightness(90%)",
                  }}
                >
                  {textButtonAdd}
                </Button>
              )}
            </Flex>
          </Flex>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default FormCustom;
