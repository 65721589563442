import {
  Box,
  Flex,
  Text,
  Modal,
  ModalContent,
  ModalOverlay,
  Image,
  Button,
} from "@chakra-ui/react";
import React, { memo } from "react";
import IconX from "../../../assets/images/booking/x-circle.svg";

const FormInfo = memo(({ isOpen, onClose, value }) => {
  const projectName = value?.wishlistDetail
    ? value?.wishlistDetail?.map((item) => item?.name).filter(Boolean)
    : null;
  const projectType = value?.wishlistDetail
    ? value?.wishlistDetail
        ?.map((item) => item?.projectType?.name)
        .filter(Boolean)
    : null;
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        backgroundColor="var(--color-main)"
        borderRadius={"16px"}
        position={"relative"}
        w={"100%"}
        maxW={"625px"}
        h={"max-content"}
      >
        <Flex
          alignItems={"center"}
          p={"25px 24px 24px 24px"}
          position={"relative"}
          borderBottom={"1px solid var(--border-input-employee)"}
        >
          <Text
            color={"var(--color-header-employee)"}
            fontSize={"16px"}
            fontWeight={"600"}
          >
            Chi tết khách hàng
          </Text>
          <Box
            position={"absolute"}
            top={"24px"}
            right={"24px"}
            cursor={"pointer"}
            onClick={onClose}
          >
            <Image src={IconX} />
          </Box>
        </Flex>
        <Box p={"24px"}>
          <Text
            color={"var(--color-header-employee)"}
            fontSize={"16px"}
            fontWeight={"600"}
          >
            {value?.name} ({value?.job ? value?.job : null})
          </Text>
        </Box>
        <Box p={"0px 40px 24px 40px"}>
          <Box margin={"0 auto"}>
            <Flex>
              <Box flex={1}>
                {/*  */}
                {(value?.phone || value?.email) && (
                  <Box>
                    <Text
                      color={"var(--color-header-employee)"}
                      fontSize={"16px"}
                      fontWeight={"600"}
                    >
                      Liên hệ
                    </Text>
                    <Box
                      background={"var(--fill-avatar)"}
                      p={"16px 24px 16px 16px"}
                      mt={"13px"}
                      borderRadius={"8px"}
                    >
                      <Flex gap={"27px"}>
                        {value?.phone && (
                          <Box flex={"1"}>
                            <Text
                              color={"var(--color-label-employee)"}
                              fontSize={"12px"}
                            >
                              Số điện thoại
                            </Text>
                            <Text
                              color={"var(--color-option-employee)"}
                              fontSize={"14px"}
                              fontWeight={"500"}
                              mt={"8px"}
                            >
                              {value?.phone}
                            </Text>
                          </Box>
                        )}
                        {value?.email && (
                          <Box flex={"1"}>
                            <Text
                              color={"var(--color-label-employee)"}
                              fontSize={"12px"}
                            >
                              Email
                            </Text>
                            <Text
                              color={"var(--color-option-employee)"}
                              fontSize={"14px"}
                              fontWeight={"500"}
                              mt={"8px"}
                            >
                              {value?.email}
                            </Text>
                          </Box>
                        )}
                      </Flex>
                    </Box>
                  </Box>
                )}

                {/*  */}
                {(value?.identificationNumber ||
                  value?.dateOfIdentification ||
                  value?.placeOfIdentification ||
                  value?.permanentAddress ||
                  value?.currentAddress) && (
                  <Box mt={"20px"}>
                    <Text
                      color={"var(--color-header-employee)"}
                      fontSize={"16px"}
                      fontWeight={"600"}
                    >
                      Thông tin giấy tờ
                    </Text>
                    <Box
                      background={"var(--fill-avatar)"}
                      p={"16px 24px 16px 16px"}
                      mt={"16px"}
                      borderRadius={"8px"}
                    >
                      <Flex gap={"27px"}>
                        {value?.identificationNumber && (
                          <Box flex={"1"}>
                            <Text
                              color={"var(--color-label-employee)"}
                              fontSize={"12px"}
                            >
                              Số CCCD
                            </Text>
                            <Text
                              color={"var(--color-option-employee)"}
                              fontSize={"14px"}
                              fontWeight={"500"}
                              mt={"8px"}
                            >
                              {value?.identificationNumber}
                            </Text>
                          </Box>
                        )}
                        {value?.dateOfIdentification && (
                          <Box flex={"1"}>
                            <Text
                              color={"var(--color-label-employee)"}
                              fontSize={"12px"}
                            >
                              Ngày cấp
                            </Text>
                            <Text
                              color={"var(--color-option-employee)"}
                              fontSize={"14px"}
                              fontWeight={"500"}
                              mt={"8px"}
                            >
                              {value?.dateOfIdentification}
                            </Text>
                          </Box>
                        )}
                      </Flex>
                      <Flex mt={"16px"} gap={"27px"}>
                        {value?.placeOfIdentification && (
                          <Box flex={"1"}>
                            <Text
                              color={"var(--color-label-employee)"}
                              fontSize={"12px"}
                            >
                              Nơi cấp
                            </Text>
                            <Text
                              color={"var(--color-option-employee)"}
                              fontSize={"14px"}
                              fontWeight={"500"}
                              mt={"8px"}
                            >
                              {value?.placeOfIdentification}
                            </Text>
                          </Box>
                        )}
                        {value?.permanentAddress && (
                          <Box flex={"1"}>
                            <Text
                              color={"var(--color-label-employee)"}
                              fontSize={"12px"}
                            >
                              Địa chỉ thường trú
                            </Text>
                            <Text
                              color={"var(--color-option-employee)"}
                              fontSize={"14px"}
                              fontWeight={"500"}
                              mt={"8px"}
                            >
                              {value?.permanentAddress}
                            </Text>
                          </Box>
                        )}
                      </Flex>
                      <Flex mt={"16px"} gap={"27px"}>
                        {value?.currentAddress && (
                          <Box flex={"1"}>
                            <Text
                              color={"var(--color-label-employee)"}
                              fontSize={"12px"}
                            >
                              Chỗ ở hiện tại
                            </Text>
                            <Text
                              color={"var(--color-option-employee)"}
                              fontSize={"14px"}
                              fontWeight={"500"}
                              mt={"8px"}
                            >
                              {value?.currentAddress}
                            </Text>
                          </Box>
                        )}
                      </Flex>
                    </Box>
                  </Box>
                )}

                {/*  */}
                {(value?.customerType || projectName) && (
                  <Box mt={"20px"}>
                    <Text
                      color={"var(--color-header-employee)"}
                      fontSize={"16px"}
                      fontWeight={"600"}
                    >
                      Phân loại
                    </Text>
                    <Box
                      background={"var(--fill-avatar)"}
                      p={"16px 24px 16px 16px"}
                      mt={"16px"}
                      borderRadius={"8px"}
                    >
                      <Flex gap={"27px"}>
                        {value?.customerType && (
                          <Box flex={"1"}>
                            <Text
                              color={"var(--color-label-employee)"}
                              fontSize={"12px"}
                            >
                              Phân loại Khách hàng
                            </Text>
                            <Text
                              color={"var(--color-option-employee)"}
                              fontSize={"14px"}
                              fontWeight={"500"}
                              mt={"8px"}
                            >
                              {value?.customerType}
                            </Text>
                          </Box>
                        )}
                        {projectName && projectName?.length > 0 && (
                          <Box flex={"1"}>
                            <Text
                              color={"var(--color-label-employee)"}
                              fontSize={"12px"}
                            >
                              Dự án quan tâm
                            </Text>
                            <Text
                              color={"var(--color-option-employee)"}
                              fontSize={"14px"}
                              fontWeight={"500"}
                              mt={"8px"}
                            >
                              {projectName.join(", ")}
                            </Text>
                          </Box>
                        )}
                      </Flex>
                    </Box>
                  </Box>
                )}
              </Box>
            </Flex>
          </Box>
        </Box>
      </ModalContent>
    </Modal>
  );
});

export default FormInfo;
