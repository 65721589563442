import toast from "react-hot-toast";
import { customDateFormat, formatDateToLocal } from "../../utils/utils";

const { clientAPI } = require("../client");
export const leaveRequestCall = {
  update: async (id, data) => {
    try {
      const response = await clientAPI(
        "put",
        `/leaveRequest/${id}`,
        data
      );
      return response;
    } catch (error) {
      console.error("Update error:", error);
      const errorMessages = error?.response?.data?.errors?.map(
        (err) => err.message
      ) || [
        error?.response?.data?.message ||
          error?.response?.statusText ||
          error?.message ||
          "An unexpected error occurred.",
      ];

      errorMessages.forEach((message) => {
        toast.error(`${message}`);
      });
      throw error;
    }
  },
  delete: async (id) => {
    try {
      const response = await clientAPI(
        "delete",
        `/leaveRequest/app/manager/${id}`
      );
      return response;
    } catch (error) {
      console.error("Delete error:", error);
      const errorMessages = error?.response?.data?.errors?.map(
        (err) => err.message
      ) || [
        error?.response?.data?.message ||
          error?.response?.statusText ||
          error?.message ||
          "An unexpected error occurred.",
      ];

      errorMessages.forEach((message) => {
        toast.error(`${message}`);
      });
      throw error;
    }
  },
  get: async (id) => {
    try {
      const response = await clientAPI(
        "get",
        `/leaveRequest/app/manager/${id}`
      );
      return response;
    } catch (error) {
      console.error("update error:", error);
      const errorMessages = error?.response?.data?.errors?.map(
        (err) => err.message
      ) || [
        error?.response?.data?.message ||
          error?.response?.statusText ||
          error?.message ||
          "An unexpected error occurred.",
      ];

      errorMessages.forEach((message) => {
        toast.error(`${message}`);
      });
      throw error;
    }
  },
  export: async (entries,param) => {
    try {
      const options = {
        limit: entries,
        page: 1,
      };

      if (param.search) options.search = param.search;
      if (param.startDate) options.requestDateFrom = formatDateToLocal(param.startDate);
      if (param.endDate) options.requestDateTo = formatDateToLocal(param.endDate);

      const queryParams = new URLSearchParams(options).toString();
      const response = await clientAPI("get", `/leaveRequest?${queryParams}`);
      
      const dataExport = response?.items.map((item, index) => {
        return {
          requestDate: item?.requestDate ? customDateFormat(item?.requestDate) : "",
          codeEmployee: item?.employeeObject?.code || "",
          fullNamEmployee: item?.employeeObject?.fullName || "",
          jobPositionEmployee: item?.employeeObject?.jobPosition?.name || "",
          startDate: item?.startDate ? customDateFormat(item?.startDate) : "",
          daysRequested: item?.daysRequested || "",
          leaveType: item?.leaveType?.name || "",
          reason: item?.reason || "",
          fullNameApproved: item?.approverObject?.fullName || "",
          jobPositionApproved: item?.approverObject?.jobPosition?.name || "",
          approvedDate: item?.approvedDate ? customDateFormat(item?.approvedDate) : "",
        };
      });

      return dataExport;
    } catch (error) {
      console.error("get list error:", error);
      const errorMessages = error?.response?.data?.errors?.map(
        (err) => err.message
      ) || [
        error?.response?.data?.message ||
          error?.response?.statusText ||
          error?.message ||
          "An unexpected error occurred.",
      ];

      errorMessages.forEach((message) => {
        toast.error(`${message}`);
      });
      throw error;
    }
  },
};
