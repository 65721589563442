import { Button, Flex, Image, Text } from "@chakra-ui/react";
import iconApproved from "../../assets/images/receiveSalary/check-circle.svg";
import iconRejected from "../../assets/images/receiveSalary/x.svg";

export const formatTableValue = (value, key, submit, id) => {
  switch (key) {
    case "status":
      let content;
      if (value === "1" || value === 1) {
        content = (
          <>
            <Button
              bg={"var(--linear-gradient-employee)"}
              color={"var(--color-main)"}
              fontSize={"12px"}
              lineHeight={"17px"}
              // p={"6px 27px 5px 28px"}
              fontWeight={"600"}
              _hover={{
                filter: "brightness(90%)",
              }}
              onClick={()=>submit(2,id)}
              h={"28px"}
              minH={"28px"}
              w={"90px"}
            >
              Duyệt
            </Button>
            <Button
              bg={"var(--color-main)"}
              color={"var(--color-option-employee-hover)"}
              border={"1px solid var(--color-option-employee-hover)"}
              fontSize={"12px"}
              lineHeight={"17px"}
              // p={"6px 19px 5px 19px"}
              fontWeight={"600"}
              _hover={{
                filter: "brightness(90%)",
              }}
              onClick={()=>submit(3,id)}
              h={"28px"}
              minH={"28px"}
              w={"90px"}
            >
              Ko duyệt
            </Button>
          </>
        );
      } else if (value === "2" || value === 2) {
        content = (
          <>
            <Image src={iconApproved} />
            <Text
              color={"var(--color-status-employ)"}
            >
              Đã được duyệt
            </Text>
          </>
        );
      } else {
        content = (
          <>
            <Image src={iconRejected} />
            <Text
              color={"var(--text-red-employee)"}
            >
              Không được phê duyệt
            </Text>
          </>
        );
      }

      return <Flex gap={"8px"}>{content}</Flex>;
    default:
      return <Text>{value}</Text>;
  }
};
