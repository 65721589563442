import React from "react";
import {
  Box,
  Flex,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  Image,
} from "@chakra-ui/react";
import CloseIconBlack from "../../../assets/images/product/closeIconBlack.svg";
import { formatDateDb, formatTimeDb } from "../../../utils/tools";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

const FormDetail = ({ isOpen, onClose, dataDetail }) => {
  const baseURL = process.env.REACT_APP_BACKEND_API || "http://localhost:3001";
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        backgroundColor="var(--color-main)"
        borderRadius={"16px"}
        // maxH={"80%"}
        // minH={"625px"}
        w={"100%"}
        maxW={"625px"}
        // overflowY={"auto"}
        // sx={{
        //   "&::-webkit-scrollbar": {
        //     width: "4px",
        //   },
        //   "&::-webkit-scrollbar-thumb": {
        //     width: "4px",
        //     borderRadius: "50px",
        //     background: "var(--color-background)",
        //   },
        //   "&::-moz-scrollbar": {
        //     width: "4px",
        //   },
        //   "&::-moz-scrollbar-thumb": {
        //     width: "4px",
        //     borderRadius: "50px",
        //     background: "var(--color-background)",
        //   },
        // }}
      >
        <form>
          <Flex flexDirection={"column"}>
            <Flex
              justifyContent={"space-between"}
              alignItems={"center"}
              gap={"10px"}
              padding={"25px 24px"}
              borderBottom={"1px solid var(--bg-line-employee)"}
            >
              <Text
                color="var(--color-header-employee)"
                fontSize={"18px"}
                fontWeight={"600"}
              >
                Chi tiết chấm công
              </Text>
              <Box cursor={"pointer"} onClick={onClose}>
                <Image src={CloseIconBlack} />
              </Box>
            </Flex>
            <Box flex={1} padding={"24px"}>
              <Text
                color={"var(--color-header-employee)"}
                fontSize={"16px"}
                fontWeight={"600"}
              >
                Tổng quan
              </Text>
              <Box
                background={"var(--fill-avatar)"}
                p={"16px 24px 16px 16px"}
                mt={"13px"}
                borderRadius={"8px"}
              >
                <Flex
                  justifyContent={"space-between"}
                  m={"24px 16px"}
                  gap={"27px"}
                >
                  <Flex
                    flexWrap={"wrap"}
                    display={"inline"}
                    fontSize={"14px"}
                    lineHeight={"17px"}
                    fontWeight={400}
                    color={"var(--color-label-employee)"}
                    maxW={"252px"}
                    w={"100%"}
                  >
                    Ngày tháng:
                    <Text
                      display={"inline"}
                      ml={"12px"}
                      fontSize={"14px"}
                      lineHeight={"17px"}
                      fontWeight={500}
                      color={"var(--color-option-employee)"}
                    >
                      {dataDetail?.checkinTime
                        ? formatDateDb(dataDetail?.checkinTime)
                        : "-"}
                    </Text>
                  </Flex>
                  <Flex
                    flexWrap={"wrap"}
                    display={"inline"}
                    fontSize={"14px"}
                    lineHeight={"17px"}
                    fontWeight={400}
                    color={"var(--color-label-employee)"}
                    maxW={"252px"}
                    w={"100%"}
                  >
                    Thời gian check in:
                    <Text
                      display={"inline"}
                      ml={"12px"}
                      fontSize={"14px"}
                      lineHeight={"17px"}
                      fontWeight={500}
                      color={"var(--color-option-employee)"}
                    >
                      {dataDetail?.checkinTime
                        ? formatTimeDb(dataDetail?.checkinTime)
                        : ""}
                    </Text>
                  </Flex>
                </Flex>
                <Flex
                  justifyContent={"space-between"}
                  m={"24px 16px"}
                  gap={"27px"}
                >
                  <Flex
                    flexWrap={"wrap"}
                    display={"inline"}
                    fontSize={"14px"}
                    lineHeight={"17px"}
                    fontWeight={400}
                    color={"var(--color-label-employee)"}
                    maxW={"252px"}
                    w={"100%"}
                  >
                    Mã nhân viên:
                    <Text
                      display={"inline"}
                      ml={"12px"}
                      fontSize={"14px"}
                      lineHeight={"17px"}
                      fontWeight={500}
                      color={"var(--color-option-employee)"}
                    >
                      {dataDetail?.employeeObject?.code || ""}
                    </Text>
                  </Flex>
                  <Flex
                    flexWrap={"wrap"}
                    display={"inline"}
                    fontSize={"14px"}
                    lineHeight={"17px"}
                    fontWeight={400}
                    color={"var(--color-label-employee)"}
                    maxW={"252px"}
                    w={"100%"}
                  >
                    Tên nhân viên:
                    <Text
                      display={"inline"}
                      ml={"12px"}
                      fontSize={"14px"}
                      lineHeight={"17px"}
                      fontWeight={500}
                      color={"var(--color-option-employee)"}
                    >
                      {dataDetail?.employeeObject?.fullName || ""}
                    </Text>
                  </Flex>
                </Flex>
                <Flex
                  justifyContent={"space-between"}
                  m={"24px 16px"}
                  gap={"27px"}
                >
                  <Flex
                    flexWrap={"wrap"}
                    display={"inline"}
                    fontSize={"14px"}
                    lineHeight={"17px"}
                    fontWeight={400}
                    color={"var(--color-label-employee)"}
                  >
                    Điểm chấm công:
                    <Text
                      display={"inline"}
                      ml={"12px"}
                      fontSize={"14px"}
                      lineHeight={"17px"}
                      fontWeight={500}
                      color={"var(--color-option-employee)"}
                    >
                      {dataDetail?.checkinPlaceObject?.name || ""}
                    </Text>
                  </Flex>
                </Flex>
                <Flex
                  justifyContent={"space-between"}
                  m={"24px 16px"}
                  gap={"27px"}
                >
                  <Flex
                    flexWrap={"wrap"}
                    display={"inline"}
                    fontSize={"14px"}
                    lineHeight={"17px"}
                    fontWeight={400}
                    color={"var(--color-label-employee)"}
                    maxW={"252px"}
                    w={"100%"}
                  >
                    Lí do:
                    <Text
                      display={"inline"}
                      ml={"12px"}
                      fontSize={"14px"}
                      lineHeight={"17px"}
                      fontWeight={500}
                      color={"var(--color-option-employee)"}
                    >
                      {dataDetail?.comment || ""}
                    </Text>
                  </Flex>
                  <Flex
                    flexWrap={"wrap"}
                    display={"inline"}
                    fontSize={"14px"}
                    lineHeight={"17px"}
                    fontWeight={400}
                    color={"var(--color-label-employee)"}
                    maxW={"252px"}
                    w={"100%"}
                  >
                    Ngày công:
                    <Text
                      display={"inline"}
                      ml={"12px"}
                      fontSize={"14px"}
                      lineHeight={"17px"}
                      fontWeight={500}
                      color={"var(--color-option-employee)"}
                    >
                      {dataDetail?.workingDayRate || ""}
                    </Text>
                  </Flex>
                </Flex>
              </Box>
              {dataDetail?.checkinImage && (
                <>
                  <Text
                    mt={"20px"}
                    color={"var(--color-header-employee)"}
                    fontSize={"16px"}
                    fontWeight={"600"}
                  >
                    Ảnh chấm công
                  </Text>
                  <Box
                    mt={"20px"}
                    background={"var(--fill-avatar)"}
                    p={"16px 24px 16px 16px"}
                    borderRadius={"8px"}
                  >
                    <Flex
                      flexWrap={"wrap"}
                      display={"inline"}
                      fontSize={"14px"}
                      lineHeight={"17px"}
                      fontWeight={400}
                      color={"var(--color-label-employee)"}
                      w={"100%"}
                    >
                      <Flex w={"100%"} justifyContent={"center"}>
                        <a
                          href={`${baseURL}/${dataDetail?.checkinImage}`}
                          target="_blank"
                        >
                          <Image
                            mt={"12px"}
                            src={`${baseURL}/${dataDetail?.checkinImage}`}
                            w={"300px"}
                            h={"300px"}
                            borderRadius={"8px"}
                            objectFit={"cover"}
                          />
                        </a>
                      </Flex>
                    </Flex>
                  </Box>
                </>
              )}
            </Box>
          </Flex>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default FormDetail;
