import {
  Box,
  Flex,
  Text,
  Modal,
  ModalContent,
  ModalOverlay,
  Image,
  Button,
  Input,
} from "@chakra-ui/react";
import React, { memo, useEffect, useState } from "react";
import IconX from "../../../assets/images/booking/x-circle.svg";
import Infident1 from "../../../assets/images/booking/infident1.png";
import Infident2 from "../../../assets/images/booking/infident2.png";
import imageIcon from "../../../assets/images/booking/image.svg";
import Payment from "../../../assets/images/booking/payment.png";
import iconTrash from "../../../assets/images/projectManagement/trash-2.svg";
import iconPlus from "../../../assets/images/projectManagement/plus.svg";
import {
  InputControl,
  SelectControl,
} from "../../../components/form/formControls";
import { useForm } from "react-hook-form";
import { FormBonus } from "../../../components/form/formBonus";

const FormDetail = memo(({ isOpen, onClose, onSubmit, defaultValues, isEdit }) => {
  const {
    reset,
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValues || {},
  });

  const [focusedIndex, setFocusedIndex] = useState(null);
  const [focusStates, setFocusStates] = useState({});
  const [infident1, setInfident1] = useState(Infident1);
  const [infident2, setInfident2] = useState(Infident2);
  const [imagePayment, setImagePayment] = useState(Payment);
  const [inputColors, setInputColors] = useState({
    dateStart: "var(--text-gray-form)",
    dateEnd: "var(--text-gray-form)",
  });
  const [employeeProject, setEmployeeProject] = useState([
    { id: Date.now(), name: "", bonus: "" },
  ]);

  const dataSelect = [
    { value: "0", label: "Đang làm việc" },
    { value: "1", label: "Dừng làm việc" },
  ];

  useEffect(() => {
    reset(defaultValues);
    setEmployeeProject([{ id: Date.now(), name: "", bonus: "" }]);
  }, [isOpen]);

  //Thêm nhân viên
  const addEmployee = () => {
    setEmployeeProject([
      ...employeeProject,
      { id: Date.now(), name: "", bonus: "" },
    ]);
  };

  // Hàm xóa nhân viên
  const deleteEmployee = (id) => {
    setEmployeeProject(employeeProject.filter((emp) => emp.id !== id));
  };

  // Hàm thay đổi dữ liệu
  const handleChange = (id, field, value) => {
    setEmployeeProject(
      employeeProject.map((emp) =>
        emp.id === id ? { ...emp, [field]: value } : emp
      )
    );
  };

  const handleFocus = (field, index) => {
    if (index !== null) {
      setFocusedIndex(index);
    } else {
      setFocusStates((prev) => ({ ...prev, [field]: true }));
    }
  };

  const handleBlur = (field, index) => {
    if (index !== null) {
      setFocusedIndex(index);
    } else {
      setFocusedIndex(null);
    }
    setFocusStates((prev) => ({ ...prev, [field]: false }));
  };

  const handleDateChange = (e, inputName) => {
    const selectedDate = e.target.value;

    setInputColors((prevColors) => ({
      ...prevColors,
      [inputName]: selectedDate
        ? "var(--color-info-employee)"
        : "var(--color-text-unclick)",
    }));
  };

  const onchangeProject = (option) => {
    setValue("projectId", option);
  };

  const onchangeProduct = (option) => {
    setValue("productId", option);
  };

  const onchangeCustomer = (option) => {
    setValue("customerId", option);
  };

  const changeImage = (event, type) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);

      if (type === 1) {
        setInfident1(imageUrl);
      } else if (type === 2) {
        setInfident2(imageUrl);
      } else {
        setImagePayment(imageUrl);
      }
    }
  };

  const submitHandler = (data) => {
    // onSubmit(data);
    console.log(data);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        backgroundColor="var(--color-main)"
        borderRadius={"16px"}
        position={"relative"}
        w={"100%"}
        maxW={"1217px"}
      >
        <form onSubmit={handleSubmit(submitHandler)}>
          <Box
            paddingBottom={"80px"}
            h={"86.5vh"}
            w={"100%"}
            overflowY={"scroll"}
            sx={{
              "&::-webkit-scrollbar": {
                width: "4px",
              },
              "&::-webkit-scrollbar-thumb": {
                width: "4px",
                borderRadius: "50px",
                background: "var(--color-background)",
              },
              "&::-moz-scrollbar": {
                width: "4px",
              },
              "&::-moz-scrollbar-thumb": {
                width: "4px",
                borderRadius: "50px",
                background: "var(--color-background)",
              },
            }}
          >
            <Flex
              alignItems={"center"}
              p={"25px 24px 24px 24px"}
              position={"relative"}
              borderBottom={"1px solid var(--border-input-employee)"}
            >
              <Text
                color={"var(--color-header-employee)"}
                fontSize={"16px"}
                fontWeight={"600"}
              >
                Chỉnh sửa locking
              </Text>
              <Box
                position={"absolute"}
                top={"24px"}
                right={"24px"}
                cursor={"pointer"}
                onClick={onClose}
              >
                <Image src={IconX} />
              </Box>
            </Flex>
            <Box margin={"0 auto"}>
              <Flex>
                <Box flex={1} p={"24px 24px 40px 40px"}>
                  <Box>
                    <Text
                      color={"var(--color-header-employee)"}
                      fontSize={"16px"}
                      fontWeight={"600"}
                    >
                      Thông tin chung
                    </Text>
                    <Box mt={"16px"} borderRadius={"8px"}>
                      <Flex>
                        <InputControl
                          label="Tên đợt locking"
                          placeholder="Nhập tên đợt locking"
                          errors={errors.code}
                          register={register("code")}
                          onFocus={() => handleFocus("code")}
                          onBlur={() => handleBlur("code")}
                          state={focusStates["code"]}
                          mwidth="100%"
                        />
                      </Flex>
                      <Flex mt={"12px"} gap={"27px"}>
                        <Box flex={"1"}>
                          <Text
                            color={"var(--color-label-employee)"}
                            fontSize={"12px"}
                          >
                            Người tạo locking
                          </Text>
                          <Text
                            color={"var(--color-option-employee)"}
                            fontSize={"14px"}
                            fontWeight={"500"}
                            mt={"8px"}
                          >
                            Nguyễn Thục Quyên
                          </Text>
                        </Box>
                        <Box flex={"1"}>
                          <Text
                            color={"var(--color-label-employee)"}
                            fontSize={"12px"}
                          >
                            Ngày tạo locking
                          </Text>
                          <Text
                            color={"var(--color-option-employee)"}
                            fontSize={"14px"}
                            fontWeight={"500"}
                            mt={"8px"}
                          >
                            05/09/2024
                          </Text>
                        </Box>
                      </Flex>
                      <Flex mt={"16px"} gap={"27px"}>
                        <SelectControl
                          label="Dự án"
                          dataOption={dataSelect}
                          placeholder="Chọn dự án"
                          selectedMain={watch("projectId")}
                          onChange={onchangeProject}
                          onFocus={() => handleFocus("projectId")}
                          onBlur={() => handleBlur("projectId")}
                          state={focusStates["projectId"]}
                          registerProps={register("projectId")}
                          errorMessage={errors.projectId}
                          mwidth="100%"
                        />
                        <SelectControl
                          label="Sản phẩm"
                          dataOption={dataSelect}
                          placeholder="Chọn sản phẩm"
                          selectedMain={watch("productId")}
                          onChange={onchangeProduct}
                          onFocus={() => handleFocus("productId")}
                          onBlur={() => handleBlur("productId")}
                          state={focusStates["productId"]}
                          registerProps={register("productId")}
                          errorMessage={errors.productId}
                          mwidth="100%"
                        />
                      </Flex>
                      <Flex mt={"16px"} gap={"27px"}>
                        <InputControl
                          label="Số tiền cọc"
                          placeholder="Ví dụ: 1 tỷ 950 triệu "
                          errors={errors.pricelocking}
                          register={register("pricelocking")}
                          onFocus={() => handleFocus("pricelocking")}
                          onBlur={() => handleBlur("pricelocking")}
                          state={focusStates["pricelocking"]}
                          mwidth={"100%"}
                        />
                        <InputControl
                          label={"TG bắt đầu"}
                          type="date"
                          colorIp={inputColors.dateStart}
                          state={focusStates["dateStart"]}
                          onFocus={() => handleFocus("dateStart")}
                          onBlur={() => handleBlur("dateStart")}
                          onChange={(e) => handleDateChange(e, "dateStart")}
                          valueMain={watch("dateStart")}
                          errors={errors?.dateStart}
                          register={register("dateStart")}
                        />
                      </Flex>
                    </Box>
                  </Box>
                  <Box margin={"24px auto"}>
                    <Flex flexDirection={"column"} gap={"16px"}>
                      <Flex
                        alignItems={"center"}
                        justifyContent={"space-between"}
                      >
                        <Text
                          fontSize={"16px"}
                          fontWeight={600}
                          lineHeight={"normal"}
                          color="var(--color-black)"
                        >
                          Nhân viên quản lý dự án
                        </Text>
                      </Flex>
                      <Flex
                        mt={"12px"}
                        flexDirection={"column"}
                        gap={"12px"}
                        alignItems={"flex-end"}
                      >
                        <Flex
                          justifyContent={"space-between"}
                          alignItems={"flex-start"}
                          gap={"12px"}
                          w={"100%"}
                          flexDirection={"column"}
                        >
                          {employeeProject.map((emp, index) => (
                            <FormBonus
                              key={index}
                              zIndex={index}
                              isIconPlace={true}
                              isIconTime={false}
                              length={"100%"}
                              onChangeName={(e) => {
                                handleChange(emp.id, "name", e);
                              }}
                              onChangeBonus={(e) => {
                                handleChange(emp.id, "bonus", e);
                              }}
                              deleteData={() => deleteEmployee(emp.id)}
                              icon={iconTrash}
                              selectedMain={emp.name}
                              filteredOptions={dataSelect}
                              isFocused={focusedIndex === index}
                              onFocus={() =>
                                handleFocus("managerProject", index)
                              }
                              onBlur={() => handleBlur("managerProject", index)}
                            />
                          ))}
                        </Flex>
                        <Flex
                          gap={"4px"}
                          alignItems={"end"}
                          p="8px 16px"
                          border={
                            "1px solid var(--color-option-employee-hover)"
                          }
                          borderRadius={"8px"}
                          w={"max-content"}
                          mr={"60px"}
                          cursor={"pointer"}
                          bg={"var(--color-main)"}
                          onClick={addEmployee}
                          _hover={{
                            filter: "brightness(90%)",
                          }}
                        >
                          <Image src={iconPlus} />
                          <Text
                            fontSize={"12px"}
                            lineHeight={"15px"}
                            color={"var(--color-option-employee-hover)"}
                            fontWeight={600}
                          >
                            Thêm NV tham gia
                          </Text>
                        </Flex>
                      </Flex>
                    </Flex>
                  </Box>
                </Box>
                <Box w={"0.5px"} bg={"var(--bg-line-employee)"}></Box>
                <Box flex={1} p={"24px 40px 40px 24px"}>
                  <Box>
                    <Text
                      color={"var(--color-header-employee)"}
                      fontSize={"16px"}
                      fontWeight={"600"}
                    >
                      Thông tin khách hàng
                    </Text>
                    <Box
                      background={"var(--fill-avatar)"}
                      p={"16px 24px 16px 16px"}
                      mt={"16px"}
                      borderRadius={"8px"}
                    >
                      <Flex gap={"27px"}>
                        <Box flex={"1"}>
                          <Text
                            color={"var(--color-label-employee)"}
                            fontSize={"12px"}
                          >
                            Tên khách hàng
                          </Text>
                          <Text
                            color={"var(--color-option-employee)"}
                            fontSize={"14px"}
                            fontWeight={"500"}
                            mt={"8px"}
                          >
                            Nguyễn tấn Dũng
                          </Text>
                        </Box>
                      </Flex>
                    </Box>
                  </Box>
                  <Box mt={"16px"}>
                    <Flex>
                      <Box flex={"1"}>
                        <Text
                          color={"var(--color-label-employee)"}
                          fontSize={"12px"}
                        >
                          Ảnh CCCD mặt trước
                        </Text>
                        <Box mt={"8px"}>
                          <Box
                            position={"relative"}
                            overflow={"hidden"}
                            w={"259px"}
                            height={"146px"}
                            display={"block"}
                            border={"0.5px solid var(--bg-line-employee)"}
                            borderRadius={"8px"}
                          >
                            <Image
                              src={infident1}
                              position={"absolute"}
                              top={0}
                              left={0}
                              right={0}
                              bottom={0}
                              objectFit={"cover"}
                              w={"100%"}
                              h={"100%"}
                              borderRadius={"8px"}
                            />
                            <Flex
                              position={"absolute"}
                              right={"8px"}
                              bottom={"8px"}
                            >
                              <Box
                                padding={"8px"}
                                bg={"var(--color-main)"}
                                borderRadius={"50%"}
                                cursor={"pointer"}
                                mr={"8px"}
                                position={"relative"}
                              >
                                <Input
                                  type="file"
                                  accept="image/*"
                                  multiple
                                  opacity={0}
                                  position="absolute"
                                  top={0}
                                  left={0}
                                  width="100%"
                                  height="100%"
                                  cursor="pointer"
                                  onChange={(e) => changeImage(e, 1)}
                                />
                                <Image
                                  src={imageIcon}
                                  _hover={{
                                    filter: "var(--filter-red)",
                                  }}
                                />
                              </Box>
                            </Flex>
                          </Box>
                        </Box>
                      </Box>
                      <Box flex={"1"}>
                        <Text
                          color={"var(--color-label-employee)"}
                          fontSize={"12px"}
                        >
                          Ảnh CCCD mặt trước
                        </Text>
                        <Box mt={"8px"}>
                          <Box
                            position={"relative"}
                            overflow={"hidden"}
                            w={"259px"}
                            height={"146px"}
                            display={"block"}
                            border={"0.5px solid var(--bg-line-employee)"}
                            borderRadius={"8px"}
                          >
                            <Image
                              src={infident2}
                              position={"absolute"}
                              top={0}
                              left={0}
                              right={0}
                              bottom={0}
                              objectFit={"cover"}
                              w={"100%"}
                              h={"100%"}
                              borderRadius={"8px"}
                            />
                            <Flex
                              position={"absolute"}
                              right={"8px"}
                              bottom={"8px"}
                              cursor={"pointer"}
                            >
                              <Box
                                padding={"8px"}
                                bg={"var(--color-main)"}
                                borderRadius={"50%"}
                                mr={"8px"}
                                position={"relative"}
                              >
                                <Input
                                  type="file"
                                  accept="image/*"
                                  multiple
                                  opacity={0}
                                  position="absolute"
                                  top={0}
                                  left={0}
                                  width="100%"
                                  height="100%"
                                  cursor="pointer"
                                  onChange={(e) => changeImage(e, 2)}
                                />
                                <Image
                                  src={imageIcon}
                                  _hover={{
                                    filter: "var(--filter-red)",
                                  }}
                                />
                              </Box>
                            </Flex>
                          </Box>
                        </Box>
                      </Box>
                    </Flex>
                  </Box>
                  <Box mt={"16px"}>
                    <Flex>
                      <Box flex={"1"}>
                        <Text
                          color={"var(--color-label-employee)"}
                          fontSize={"12px"}
                        >
                          Ảnh ủy nhiệm chi
                        </Text>
                        <Flex
                          mt={"8px"}
                          border={"0.5px solid var(--bg-line-employee)"}
                          borderRadius={"8px"}
                          justifyContent={"center"}
                          position={"relative"}
                        >
                          <Box
                            overflow={"hidden"}
                            w={"202px"}
                            h={"300px"}
                            display={"block"}
                            borderRadius={"11px"}
                            position={"relative"}
                          >
                            <Image
                              src={imagePayment}
                              position={"absolute"}
                              top={0}
                              left={0}
                              right={0}
                              bottom={0}
                              objectFit={"cover"}
                              w={"100%"}
                              h={"100%"}
                              borderRadius={"8px"}
                            />
                          </Box>
                          <Flex
                            position={"absolute"}
                            right={"8px"}
                            bottom={"8px"}
                            cursor={"pointer"}
                          >
                            <Box
                              padding={"8px"}
                              bg={"var(--fill-avatar)"}
                              borderRadius={"50%"}
                              mr={"8px"}
                              position={"relative"}
                            >
                              <Input
                                type="file"
                                accept="image/*"
                                multiple
                                opacity={0}
                                position="absolute"
                                top={0}
                                left={0}
                                width="100%"
                                height="100%"
                                cursor="pointer"
                                onChange={(e) => changeImage(e, 3)}
                              />
                              <Image
                                src={imageIcon}
                                _hover={{
                                  filter: "var(--filter-red)",
                                }}
                              />
                            </Box>
                          </Flex>
                        </Flex>
                      </Box>
                    </Flex>
                  </Box>
                </Box>
              </Flex>
            </Box>
            <Box
              position={"fixed"}
              w={"100%"}
              bottom={"0"}
              bg={"var(--color-main)"}
              borderTop={"0.5px solid var(--bg-line-employee)"}
              borderRadius={"0px 0px 16px 16px"}
              zIndex={3}
            >
              <Flex p={"24px"} gap={"16px"} justifyContent={"flex-end"}>
                <Button
                  w={"112px"}
                  p={"13px 20px"}
                  fontSize={"15px"}
                  fontWeight={600}
                  color={"var(--color-option-employee-hover)"}
                  borderRadius={"8px"}
                  border={"1px solid var(--color-option-employee-hover)"}
                  bg={"var(--color-main)"}
                  _hover={{
                    filter: "brightness(90%)",
                  }}
                  onClick={onClose}
                >
                  Hủy
                </Button>
                <Button
                  type="submit"
                  w={"112px"}
                  p={"13px 20px"}
                  fontSize={"15px"}
                  fontWeight={600}
                  color={"var(--color-main)"}
                  borderRadius={"8px"}
                  bg={"var(--color-option-employee-hover)"}
                  _hover={{
                    filter: "brightness(90%)",
                  }}
                >
                  Lưu
                </Button>
              </Flex>
            </Box>
          </Box>
        </form>
      </ModalContent>
    </Modal>
  );
});

export default FormDetail;
