import React from "react";
import { Grid, GridItem } from "@chakra-ui/react";
import FormFeature from "./form/formFeature";
import { ROLES_CONFIG } from "../../../utils";
import { useSelector } from "react-redux";
import { useProjectCategoriesOther } from "../../../hooks/useProjectCategoriesOther";

const Other = () => {
  const { city, direction, property, job, refetch, isLoading, isRefetching } =
    useProjectCategoriesOther();

  const user = useSelector((state) => state.auth.login?.currentUser);
  const roles = user?.roles || [];

  const hasPermission = (requiredRoles) => {
    return (
      requiredRoles.some((role) => roles.includes(role)) ||
      roles.includes("ALL")
    );
  };

  const gridItemsData = [
    {
      title: "Danh sách thành phố",
      button: "Thêm thành phố",
      data: {
        headers: [
          { label: "", key: "" },
          { label: "STT", key: "" },
          { label: "Tên thành phố", key: "name" },
        ],
        data: city,
        role: ROLES_CONFIG.MENU.MENU_QLDM.CHILDREN.CHILDREN_DA.TAB.TAB_K
          .CHILDREN_TAB.CHILDREN_TAB_CITY.role,
      },
      type: 0,
      textDelete: "thành phố",
      textAdd: "Tạo thành phố mới",
      textButtonAdd: "Tạo thành phố mới",
      textTitleEdit: "Chỉnh sửa thành phố",
    },
    {
      title: "Danh sách hướng",
      button: "Thêm hướng",
      data: {
        headers: [
          { label: "", key: "" },
          { label: "STT", key: "" },
          { label: "Tên hướng", key: "name" },
        ],
        data: direction,
        role: ROLES_CONFIG.MENU.MENU_QLDM.CHILDREN.CHILDREN_DA.TAB.TAB_K
          .CHILDREN_TAB.CHILDREN_TAB_DIRECTION.role,
      },
      type: 1,
      textDelete: "hướng",
      textAdd: "Tạo hướng mới",
      textButtonAdd: "Tạo hướng mới",
      textTitleEdit: "Chỉnh sửa hướng",
    },
    {
      title: "Danh sách loại tính chất",
      button: "Thêm loại tính chất",
      data: {
        headers: [
          { label: "", key: "" },
          { label: "STT", key: "" },
          { label: "Tên loại tính chất", key: "name" },
        ],
        data: property,
        role: ROLES_CONFIG.MENU.MENU_QLDM.CHILDREN.CHILDREN_DA.TAB.TAB_K
          .CHILDREN_TAB.CHILDREN_TAB_PROPERTY.role,
      },
      type: 2,
      textDelete: "loại tính chất",
      textAdd: "Tạo loại tính chất mới",
      textButtonAdd: "Tạo loại tính chất mới",
      textTitleEdit: "Chỉnh sửa loại tính chất",
    },
    {
      title: "Danh sách nghề nghiệp",
      button: "Thêm nghề nghiệp",
      data: {
        headers: [
          { label: "", key: "" },
          { label: "STT", key: "" },
          { label: "Tên nghề nghiệp", key: "name" },
        ],
        data: job,
        role: ROLES_CONFIG.MENU.MENU_QLDM.CHILDREN.CHILDREN_DA.TAB.TAB_K
          .CHILDREN_TAB.CHILDREN_TAB_JOB.role,
      },
      type: 3,
      textDelete: "nghề nghiệp",
      textAdd: "Tạo nghề nghiệp mới",
      textButtonAdd: "Tạo nghề nghiệp mới",
      textTitleEdit: "Chỉnh sửa nghề nghiệp",
    },
  ];

  return (
    <Grid minH={"80vh"} templateColumns="repeat(3, 1fr)" gap={4}>
      {gridItemsData.map(
        (item, index) =>
          hasPermission(item.data.role) && (
            <GridItem
              key={index}
              p={"16px"}
              bg={"var(--color-main)"}
              borderRadius={"16px"}
            >
              <FormFeature
                title={item.title}
                button={item.button}
                data={item.data}
                isLoading={isLoading}
                isRefetching={isRefetching}
                refetch={refetch}
                type={item.type}
                textDelete={item?.textDelete || null}
                textAdd={item?.textAdd || null}
                textButtonAdd={item?.textButtonAdd || null}
                textTitleEdit={item?.textTitleEdit || null}
                nameEnd={true}
              />
            </GridItem>
          )
      )}
    </Grid>
  );
};

export default Other;
