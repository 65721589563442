import {
  FormControl,
  FormLabel,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
// import iconMap from "../../assets/images/timeKeeping/map.svg";

export const FormTimeKeeping = ({
  type = "text",
  title,
  place,
  length,
  errors,
  register,
  onInput,
  value,
  step,
  icon,
  handleClick,
  inputTypeFile,
  name,
}) => {
  const [nameFile, setNameFile] = useState("");

  useEffect(()=>{
    if(name)
      setNameFile(name)
  },[name])

  const openFile = (event) => {
    const file = event.target.files[0];
    if (file) {
      setNameFile(file?.name);
    }
    handleClick(event,'fileProject');
  };
  return (
    <FormControl
      display={"flex"}
      flexDirection={"column"}
      gap={{ base: "6px" }}
      maxW={length}
    >
      <FormLabel
        fontSize={"12px"}
        fontWeight={400}
        color="var(--text-color-Subtittle)"
        lineHeight={"normal"}
        m={{ base: "0" }}
      >
        {title}
      </FormLabel>

      <InputGroup
        mt={"0"}
        p={{ base: "14px 12px 13px 12px" }}
        alignItems={"center"}
        h={"44px"}
        border={"0.5px solid var(--Line)"}
        borderRadius={"8px"}
        _focus={{
          boxShadow: "none",
        }}
      >
        {inputTypeFile && (
          <Input
            type="file"
            accept="image/*, .pdf, .doc, .docx"
            multiple={true}
            opacity={0}
            position="absolute"
            top={0}
            left={0}
            width="100%"
            height="100%"
            onChange={openFile}
            w={"100%"}
            h={"100%"}
            zIndex={3}
            p={0}
            cursor={"pointer"}
          />
        )}
        <Input
          step={step}
          color="var(--text-color-Subtittle)"
          fontWeight={500}
          fontSize={"14px"}
          border={"none"}
          p={"0px"}
          placeholder={place}
          type={type}
          _focus={{
            boxShadow: "none",
          }}
          value={inputTypeFile ? nameFile : value}
          onInput={onInput}
          {...register}
        />
        {icon && (
          <InputRightElement>
            <Image
              zIndex={"1"}
              cursor={"pointer"}
              src={icon}
              // onClick={handleClick}
            />
          </InputRightElement>
        )}
      </InputGroup>

      {errors && (
        <Text fontSize={{ base: "12px" }} color={"red"}>
          {errors.message}
        </Text>
      )}
    </FormControl>
  );
};
