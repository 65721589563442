import React, { useCallback, useState } from "react";
import {
  Box,
  Flex,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  Image,
  Button,
  Stack,
  Checkbox,
  InputGroup,
  Input,
  InputRightElement,
} from "@chakra-ui/react";
import CloseIconBlack from "../../../assets/images/booking/x-circle.svg";
import CloseFile from "../../../assets/images/booking/Frame.svg";
import { useForm } from "react-hook-form";
import { SearchIcon } from "@chakra-ui/icons";

const AddProduct = ({ isOpen, onClose, data, defaultValues, onSubmit }) => {
  const products = [
    {
      label: "Tên sản phẩm 1",
      value: "product1",
    },
    {
      label: "Tên sản phẩm 2",
      value: "product1",
    },
    {
      label: "Tên sản phẩm 3",
      value: "product1",
    },
    {
      label: "Tên sản phẩm 4",
      value: "product4",
    },
    {
      label: "Tên sản phẩm 5",
      value: "product5",
    },
    {
      label: "Tên sản phẩm 5",
      value: "product5",
    },
    {
      label: "Tên sản phẩm 5",
      value: "product5",
    },
    {
      label: "Tên sản phẩm 5",
      value: "product5",
    },
    {
      label: "Tên sản phẩm 5",
      value: "product5",
    },
    {
      label: "Tên sản phẩm 5",
      value: "product5",
    },
    {
      label: "Tên sản phẩm 5",
      value: "product5",
    },
    {
      label: "Tên sản phẩm 5",
      value: "product5",
    },
    {
      label: "Tên sản phẩm 5",
      value: "product5",
    },
    {
      label: "Tên sản phẩm 5",
      value: "product5",
    },
    {
      label: "Tên sản phẩm 5",
      value: "product5",
    },
    {
      label: "Tên sản phẩm 5",
      value: "product5",
    },
    {
      label: "Tên sản phẩm 5",
      value: "product5",
    },
    {
      label: "Tên sản phẩm 5",
      value: "product5",
    },
    {
      label: "Tên sản phẩm 5",
      value: "product5",
    },
    {
      label: "Tên sản phẩm 5",
      value: "product5",
    },
    {
      label: "Tên sản phẩm 5",
      value: "product5",
    },
    {
      label: "Tên sản phẩm 5",
      value: "product5",
    },
    {
      label: "Tên sản phẩm 5",
      value: "product5",
    },
    {
      label: "Tên sản phẩm 5",
      value: "product5",
    },
    {
      label: "Tên sản phẩm 5",
      value: "product5",
    },
    {
      label: "Tên sản phẩm 5",
      value: "product5",
    },
    {
      label: "Tên sản phẩm 5",
      value: "product5",
    },
    {
      label: "Tên sản phẩm 5",
      value: "product5",
    },
  ];

  const [search, setSearch] = useState("");
  const [selectedValues, setSelectedValues] = useState([]);

  const onChangeSearch = useCallback((e) => {
    const { value } = e.target;
    setSearch(value);
  });

  const handleCheckboxChange = (value) => {
    setSelectedValues((prev) => {
      if (prev.includes(value)) {
        return prev.filter((item) => item !== value);
      } else {
        return [...prev, value];
      }
    });
  };

  const { handleSubmit } = useForm({
    defaultValues: defaultValues || {},
  });

  const submitHandler = (data) => {
    onSubmit(data);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        maxW={"625px"}
        backgroundColor="var(--color-main)"
        borderRadius={"16px"}
      >
        <form onSubmit={handleSubmit(submitHandler)}>
          <Box
            h={"800px"}
            maxH={"88vh"}
            w={"100%"}
            overflowY={"auto"}
            pb={"92px"}
            sx={{
              "&::-webkit-scrollbar": {
                width: "4px",
              },
              "&::-webkit-scrollbar-thumb": {
                width: "4px",
                borderRadius: "50px",
                background: "var(--color-background)",
              },
              "&::-moz-scrollbar": {
                width: "4px",
              },
              "&::-moz-scrollbar-thumb": {
                width: "4px",
                borderRadius: "50px",
                background: "var(--color-background)",
              },
            }}
          >
            <Flex flexDirection={"column"}>
              <Flex
                justifyContent={"space-between"}
                alignItems={"center"}
                gap={"10px"}
                padding={"25px 24px"}
                borderBottom={"1px solid var(--bg-line-employee)"}
              >
                <Text
                  color="var(--color-header-employee)"
                  fontSize={"18px"}
                  fontWeight={"600"}
                >
                  Thêm sản phẩm vào đợt nhận booking
                </Text>
                <Box cursor={"pointer"} onClick={onClose}>
                  <Image src={CloseIconBlack} />
                </Box>
              </Flex>
              <Box flex={1} w={"578px"} m={"24px auto"}>
                <Text
                  fontSize={"16px"}
                  fontWeight={"600"}
                  lineHeight={"19px"}
                  textTransform={"uppercase"}
                  mb={"12px"}
                >
                  Tên dự án
                </Text>
                <InputGroup borderRadius={{ base: "8px" }} w={{ base: "100%" }}>
                  <Input
                    placeholder="Nhập Mã ĐNBK,tên,..."
                    type="text"
                    borderRadius={{ base: "8px" }}
                    border={{ base: "1px solid var(--color-boder)" }}
                    backgroundColor={"var(--fill-avatar)"}
                    onChange={onChangeSearch}
                  />
                  <InputRightElement
                    borderTopRightRadius={"8px"}
                    borderBottomRightRadius={"8px"}
                    pointerEvents="none"
                    cursor={"pointer"}
                    backgroundImage="var(--color-button)"
                  >
                    <SearchIcon color="var(--color-main)" />
                  </InputRightElement>
                </InputGroup>
                <Box mt={"24px"}>
                  <Stack gap={"12px"} flexWrap={"wrap"} flexDirection={"row"}>
                    {products.map((item, key) => (
                      <Checkbox
                        key={key}
                        p={"16px 20px"}
                        gap={"24px"}
                        fontSize={"14px"}
                        lineHeight={"17px"}
                        fontWeight={"500"}
                        maxW={"283px"}
                        w={"100%"}
                        border={"1px solid var(--color-boder)"}
                        borderRadius={"8px"}
                        onChange={() => handleCheckboxChange(item.value)}
                      >
                        {item.label}
                      </Checkbox>
                    ))}
                  </Stack>
                </Box>
              </Box>
              <Box
                position={"absolute"}
                bottom={"0"}
                w={"100%"}
                borderTop={" 0.5px solid var(--border-input-employee)"}
              >
                <Flex
                  w={"100%"}
                  bg={"var(--color-main)"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  p={"24px"}
                  borderBottomLeftRadius={"8px"}
                  borderBottomRightRadius={"8px"}
                >
                  <Button
                    display="flex"
                    alignItems="center"
                    p="10px 16px"
                    h={"44px"}
                    gap="8px"
                    backgroundImage="var(--color-button)"
                    onClick={() => {
                      console.log("dat");
                    }}
                    _hover={{
                      filter: "brightness(90%)",
                    }}
                  >
                    <Image src={CloseFile} />
                    <Text
                      fontSize="15px"
                      fontWeight={600}
                      color="var(--color-main)"
                    >
                      Tải file lên
                    </Text>
                  </Button>
                  <Flex gap={"16px"} justifyContent={"flex-end"}>
                    <Button
                      w={"112px"}
                      h={"44px"}
                      color={"var(--color-option-employee-hover)"}
                      border={"1px solid var(--color-option-employee-hover)"}
                      bg={"var(--color-main)"}
                      onClick={onClose}
                      _hover={{
                        filter: "brightness(90%)",
                      }}
                    >
                      Hủy
                    </Button>
                    <Button
                      type="submit"
                      w={"112px"}
                      h={"44px"}
                      color={"var(--color-main)"}
                      bg={"var(--color-button)"}
                      _hover={{
                        filter: "brightness(80%)",
                      }}
                    >
                      Xong
                    </Button>
                  </Flex>
                </Flex>
              </Box>
            </Flex>
          </Box>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default AddProduct;
