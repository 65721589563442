import {
  Button,
  Flex,
  HStack,
  Input,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  Box,
  Image,
} from "@chakra-ui/react";
import React, { useState, useRef } from "react";
import toast from "react-hot-toast";
import { exportFile, importFile } from "../../utils/tools";
import { checkinCall } from "../../api/checkin";
import iconExcel from "../../assets/images/timeKeeping/excel.svg";
import iconFile from "../../assets/images/timeKeeping/file.svg";
import iconClose from "../../assets/images/icon-login.png";

const PopupImport = ({ isOpen, onClose, refetchCheckInData }) => {
  const [fileName, setFileName] = useState("");
  const inputFileRef = useRef(null);

  const handleFileChange = (event) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const fileExtension = file.name.split(".").pop().toLowerCase();

      if (fileExtension === "xlsx" || fileExtension === "csv") {
        setFileName(file.name);
      } else {
        alert("Vui lòng chọn file có định dạng .xlsx hoặc .csv");
        event.target.value = null;
        setFileName("");
      }
    }
  };

  const handleImport = async () => {
    if (!inputFileRef.current || !inputFileRef.current.files[0]) {
      toast.error("Vui lòng chọn file để import.");
      return;
    }

    const fileEvent = { target: { files: inputFileRef.current.files } };
    const checkInColumnMappingReverse = {
      "Thời gian điểm danh": "dateTime",
      "Giờ vào": "checkinTime",
      "Mã nhân viên": "code",
      "Mã địa điểm chấm công": "checkinPlaceCode",
      "Vĩ độ": "lat",
      "Kinh độ": "lng",
    };

    const toastCreate = toast.loading("Đang thêm danh sách chấm công...");
    try {
      await importFile(
        fileEvent,
        checkInColumnMappingReverse,
        async (importedData) => {
          try {
            await checkinCall.bulkAdd(importedData);
            await refetchCheckInData();
            toast.success("Import thành công");
          } catch (error) {
            toast.error("API call failed");
            console.error("Lỗi khi bulkAdd:", error);
          }
        }
      );
    } catch (error) {
      toast.error("Import error");
      console.error("Lỗi khi importFile:", error);
    }
    toast.dismiss(toastCreate);
  };

  const handleExport = () => {
    const checkInColumnMapping = {
      dateTime: "Thời gian điểm danh",
      checkinTime: "Giờ vào",
      code: "Mã nhân viên",
      checkinPlaceCode: "Mã địa điểm chấm công",
      lat: "Vĩ độ",
      lng: "Kinh độ",
    };
    const data = [
      {
        dateTime: "30/10/2024",
        checkinTime: "15:42:20",
        code: "MSH0000021",
        checkinPlaceCode: "MSHDDCC_3",
        lat: 20.9843839419704,
        lng: 105.78689937882,
      },
      {
        dateTime: "30/10/2024",
        checkinTime: "15:42:20",
        code: "MSH000002",
        checkinPlaceCode: "MSHDDCC_3",
        // lat: 20.9843839419704,
        // lng: 105.78689937882,
      },
      {
        dateTime: "29/10/2024",
        checkinTime: "15:42:20",
        code: "MSH0000022",
        checkinPlaceCode: "MSHDDCC_3",
        lat: 20.9843839419704,
        lng: 105.78689937882,
      },
    ];
    try {
      exportFile(data, checkInColumnMapping, "bang_mau_cham_cong.xlsx");
      toast.success("Export thành công");
    } catch (error) {
      toast.error("Export lỗi");
      console.log(error);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent maxW="625px">
        <Flex
          alignItems={"center"}
          justifyContent={"space-between"}
          p={"25px 24px 24px 24px"}
          borderBottom={"1px solid var(--color-boder)"}
        >
          <Text
            fontSize={"18px"}
            fontWeight={600}
            lineHeight={"normal"}
            color="var(--color-black)"
          >
            Nhập danh sách chấm công
          </Text>
          <Image src={iconClose} cursor={"pointer"} onClick={onClose} />
        </Flex>
        <Flex p="24px" justifyContent="space-between">
          <HStack spacing="4">
            <Input
              type="text"
              placeholder="Chưa có file nào được chọn"
              value={fileName}
              isReadOnly
              cursor="not-allowed"
              bg="gray.100"
              color="gray.800"
              _placeholder={{ color: "gray.500" }}
            />
            <Button
              as="label"
              htmlFor="file-upload"
              bg="blue.500"
              color="white"
              px="4"
              py="2"
              borderRadius="md"
              cursor="pointer"
              _hover={{ bg: "blue.600" }}
              _active={{ bg: "blue.700" }}
            >
              Chọn file
            </Button>
            <Input
              id="file-upload"
              type="file"
              accept=".xlsx, .csv"
              onChange={handleFileChange}
              ref={inputFileRef}
              display="none"
            />
          </HStack>
          <Button
            display={"flex"}
            alignItems={"center"}
            p={"10px 16px 10px 12px"}
            gap={"8px"}
            backgroundImage="var(--color-gradient-excel)"
            _hover={{
              backgroundImage: "var(--color-gradient-excel)",
            }}
            onClick={handleExport}
          >
            <Image src={iconFile} />
            <Text fontSize="15px" fontWeight="600" color="var(--color-main)">
              Dữ liệu mẫu
            </Text>
          </Button>
        </Flex>
        <Box p="24px">
          <Button
            display={"flex"}
            alignItems={"center"}
            p={"10px 16px 10px 12px"}
            gap={"8px"}
            backgroundImage="var(--color-button)"
            _hover={{
              backgroundImage: "var(--color-button)",
            }}
            onClick={handleImport}
          >
            <Image src={iconExcel} />
            <Text fontSize="15px" fontWeight="600" color="var(--color-main)">
              Thêm danh sách chấm công
            </Text>
          </Button>
        </Box>
      </ModalContent>
    </Modal>
  );
};

export default PopupImport;
