import toast from "react-hot-toast";
import { customDateFormat } from "../../utils/utils";

const { clientAPI } = require("../client");
export const businessCategoryCall = {
  create: async (data) => {
    try {
      const response = await clientAPI("post", "/businessCategory", data);
      return response;
    } catch (error) {
      console.error("Create error:", error);
      const errorMessages = error?.response?.data?.errors?.map(
        (err) => err.message
      ) || [
        error?.response?.data?.message ||
          error?.response?.statusText ||
          error?.message ||
          "An unexpected error occurred.",
      ];

      errorMessages.forEach((message) => {
        toast.error(`${message}`);
      });
      throw error;
    }
  },
  update: async (id, data) => {
    try {
      const response = await clientAPI("put", `/businessCategory/${id}`, data);
      return response;
    } catch (error) {
      console.error("Update error:", error);
      const errorMessages = error?.response?.data?.errors?.map(
        (err) => err.message
      ) || [
        error?.response?.data?.message ||
          error?.response?.statusText ||
          error?.message ||
          "An unexpected error occurred.",
      ];

      errorMessages.forEach((message) => {
        toast.error(`${message}`);
      });
      throw error;
    }
  },
  updateHiddenStatus: async (id) => {
    try {
      const response = await clientAPI(
        "put",
        `/businessCategory/updateHiddenStatus/${id}`
      );
      return response;
    } catch (error) {
      console.error("Delete error:", error);
      const errorMessages = error?.response?.data?.errors?.map(
        (err) => err.message
      ) || [
        error?.response?.data?.message ||
          error?.response?.statusText ||
          error?.message ||
          "An unexpected error occurred.",
      ];

      errorMessages.forEach((message) => {
        toast.error(`${message}`);
      });
      throw error;
    }
  },
  export: async (entries, department, param) => {
    try {
      const options = {
        limit: entries,
        page: 1,
        department: department,
      };

      if (param.search) options.search = param.search;
      if (param.branchId) options.branchId = param.branchId;
      if (param.saleBlockId) options.saleBlockId = param.saleBlockId;
      if (param.saleAreaId) options.saleAreaId = param.saleAreaId;
      if (param.supportDepartmentId) options.supportDepartmentId = param.supportDepartmentId;
      if (param.jobPositionId) options.jobPositionId = param.jobPositionId; 

      const queryParams = new URLSearchParams(options).toString();
      const response = await clientAPI("get", `/employee?${queryParams}`);

      const dataExport = response?.items.map((item, index) => {
        return {
          ...(item?.code && { code: item.code }),
          ...(item?.fullName && { fullName: item.fullName }),
          ...(item?.password && { password: item.password }),
          ...(item?.phone && { phone: item.phone }),
          ...(item?.email && { email: item.email }),
          ...(item?.avatar && { avatar: item.avatar }),
          ...(item?.jobTitleId && { jobTitle: item?.jobTitle?.name }),
          ...(item?.identificationNumber && {
            identificationNumber: item.identificationNumber,
          }),
          ...(item?.dateOfIdentification && {
            dateOfIdentification: customDateFormat(item.dateOfIdentification),
          }),
          ...(item?.placeOfIdentification && {
            placeOfIdentification: item.placeOfIdentification,
          }),
          ...(item?.permanentAddress && {
            permanentAddress: item.permanentAddress,
          }),
          ...(item?.currentAddress && {
            currentAddress: item.currentAddress,
          }),
          ...(item?.bankAccount && { bankAccount: item.bankAccount }),
          ...(item?.bankName && { bankName: item.bankName }),
          ...(item?.startTime && {
            startTime: customDateFormat(item.startTime),
          }),
          ...(item?.endTime && { endTime: item.endTime }),
          status: item?.status === 0 ? "Đang hoạt động" : "Đã nghỉ làm",
          ...{
            department:
              item.department === 0
                ? "Khối hỗ trợ kinh doanh"
                : "Khối kinh doanh",
          },
          ...(item?.jobPosition && { jobPosition: item.jobPosition?.name }),
          ...(item?.supportDepartment && {
            supportDepartment: item.supportDepartment?.name,
          }),
          ...(item?.businessCategory && {
            businessCategory: item.businessCategory?.name,
          }),
          ...(item?.guaranteeType && {
            guaranteeType: item.guaranteeType?.name,
          }),
          ...(item?.guaranteeDate && {
            guaranteeDate: customDateFormat(item.guaranteeDate),
          }),
          ...(item?.depositDate && {
            depositDate: customDateFormat(item.depositDate),
          }),
          ...(item?.referralCode && {
            referralCode: item.referralCode?.name,
          }),
          ...(item?.saleBlock && { saleBlock: item.saleBlock?.name }),
          ...(item?.saleArea && { saleArea: item.saleArea?.name }),
          ...(item?.JobTitleCode && { JobTitleCode: item.JobTitleCode }),
          ...(item?.branch && { branch: item.branch?.name }),
        };
      });

      return dataExport;
    } catch (error) {
      console.error("get list error:", error);
      const errorMessages = error?.response?.data?.errors?.map(
        (err) => err.message
      ) || [
        error?.response?.data?.message ||
          error?.response?.statusText ||
          error?.message ||
          "An unexpected error occurred.",
      ];

      errorMessages.forEach((message) => {
        toast.error(`${message}`);
      });
      throw error;
    }
  },
};
