import * as XLSX from "xlsx";

function decimalToTime(decimal) {
  const totalHours = decimal * 24;

  const hours = Math.floor(totalHours);
  const minutes = Math.floor((totalHours - hours) * 60);
  const seconds = Math.floor(((totalHours - hours) * 60 - minutes) * 60);

  const formattedHours = String(hours).padStart(2, "0");

  return `${formattedHours}:${String(minutes).padStart(2, "0")}:${String(
    seconds
  ).padStart(2, "0")}`;
}

function decimalToDate(decimal) {
  const baseDate = new Date(1900, 0, 1);
  const date = new Date(baseDate.getTime() + decimal * 24 * 60 * 60 * 1000);

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

export const importFile = (event, columnMappingReverse, callback) => {
  const file = event.target.files[0];
  const reader = new FileReader();

  reader.onload = (e) => {
    const data = new Uint8Array(e.target.result);
    const workbook = XLSX.read(data, { type: "array" });

    // Lấy sheet đầu tiên của file
    const sheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[sheetName];

    // Chuyển đổi sheet thành JSON
    const jsonData = XLSX.utils.sheet_to_json(worksheet);

    // Ánh xạ ngược từ tiếng Việt về tên cột gốc
    const mappedData = jsonData.map((item) => {
      const mappedItem = {};
      for (const key in item) {
        const originalKey = columnMappingReverse[key] || key;
        let value = item[key];
        if (originalKey === "checkinTime" && typeof value === "number") {
          value = decimalToTime(value);
        }
        if (originalKey === "dateTime" && typeof value === "number") {
          value = decimalToDate(value);
        }
        mappedItem[originalKey] = value;
      }
      return mappedItem;
    });

    callback(mappedData); // Trả về dữ liệu JSON đã được ánh xạ thông qua callback
  };

  reader.readAsArrayBuffer(file);
};

export const exportFile = (data, columnMapping, fileName = "output.xlsx") => {
  // Áp dụng columnMapping để chuyển đổi tên cột
  const formattedData = data.map((item) => {
    const formattedItem = {};
    for (const key in item) {
      const newKey = columnMapping[key] || key; // Sử dụng tên tiếng Việt nếu có trong mapping
      formattedItem[newKey] = item[key];
    }
    return formattedItem;
  });

  // Tạo worksheet từ dữ liệu JSON đã format
  const worksheet = XLSX.utils.json_to_sheet(formattedData);

  // Xác định độ rộng cho từng cột dựa trên dữ liệu
  const columnWidths = Object.keys(formattedData[0]).map((key) => {
    const maxColumnWidth = Math.max(
      key.length, // Độ rộng của tên cột
      ...formattedData.map((row) =>
        row[key] ? row[key].toString().length : 10
      ) // Độ rộng tối đa của dữ liệu trong cột
    );
    return { wch: maxColumnWidth + 2 };
  });

  // Thiết lập độ rộng cho từng cột
  worksheet["!cols"] = columnWidths;

  // Tạo workbook và thêm worksheet vào
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  // Xuất file
  XLSX.writeFile(workbook, fileName);
};

export const formatDateDb = (value, type = 0) => {
  const dateTime = new Date(value);
  if (type) {
    const year = dateTime.getFullYear();
    const month = String(dateTime.getMonth() + 1).padStart(2, "0");
    const day = String(dateTime.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }
  const formattedDate = dateTime.toLocaleDateString("en-GB", {
    day: "numeric",
    month: "numeric",
    year: "numeric",
  });
  return formattedDate;
};

export const formatTimeDb = (value, type = 0) => {
  const checkinTime = new Date(value);
  if (type) {
    const formattedTime = checkinTime.toLocaleTimeString("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
    });
    return formattedTime;
  }
  const formattedTime = checkinTime.toLocaleTimeString("en-GB", {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
  return formattedTime;
};

export const removeFileExtension = (name) => {
  // Kiểm tra xem tên file có chứa các phần mở rộng như .doc, .pdf không
  const extensions = ['.doc', '.pdf', '.xls', '.xlsx', '.ppt', '.txt']; // Các phần mở rộng cần kiểm tra
  for (const ext of extensions) {
    if (name && name.includes(ext)) {
      return name.replace(ext, ''); // Xóa phần mở rộng
    }
  }
  return name;
};
