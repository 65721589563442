import {
  Box,
  Button,
  Flex,
  Grid,
  Image,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  Textarea,
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import iconClose from "../../assets/images/projectManagement/x-circle.svg";
import iconFile from "../../assets/images/projectManagement/file-text.svg";
import iconTrash from "../../assets/images/projectManagement/trash-2.svg";
import iconPlus from "../../assets/images/projectManagement/plus.svg";
import { useForm } from "react-hook-form";
import { InputControl, SelectControl } from "../form/formControls";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormTimeKeeping } from "../form/formTimeKeeping";
import { FormBonus } from "../form/formBonus";
import { FormInputImage } from "../form/formImage";
import { useCategories } from "../../hooks/useCategories";
import { projectManagerCall } from "../../api/projectManager";

const schema = Yup.object().shape({
  name: Yup.string().required("Vui lòng nhập tên dự án"),
  address: Yup.string().required("Vui lòng nhập địa chỉ"),
});

const PopupProjectManagementAdd = ({
  isOpen,
  onClose,
  defaultValues,
  onSubmit,
  isEdit
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValues || {},
  });

  const { constantData } = useCategories();

  const formatEntriesForSelect = (entries) =>
    Object.entries(entries || {}).map(([key, value]) => ({
      value: key,
      label: value,
    }));

  const investorData = useMemo(
    () => formatEntriesForSelect(constantData?.investor),
    [constantData?.investor]
  );

  const cityData = useMemo(
    () => formatEntriesForSelect(constantData?.city),
    [constantData?.city]
  );

  const projectTypeData = useMemo(
    () => formatEntriesForSelect(constantData?.projectType),
    [constantData?.projectType]
  );

  const employeeData = useMemo(
    () => formatEntriesForSelect(constantData?.employee),
    [constantData?.employee]
  );

  const [focusedIndex, setFocusedIndex] = useState(null);
  const [focusStates, setFocusStates] = useState({});
  const [errorData, setErrorData] = useState(false);
  const [file, setFile] = useState(null);

  //danh sách nhân viên quản lý và ảnh
  const [employeeProject, setEmployeeProject] = useState([
    { id: Date.now(), managerId: "", commission: "" },
  ]);
  const [imageProject, setImageProject] = useState(null);
  const [imageHonor, setImageHonor] = useState(null);

  useEffect(() => {
    setErrorData(false);
    reset(defaultValues);
    if (defaultValues?.dataProjectManagers) {
      setEmployeeProject(defaultValues?.dataProjectManagers);
    } else {
      setEmployeeProject([{ id: Date.now(), managerId: "", commission: "" }]);
    }

    setImageProject(null);
    setImageHonor(null);
  }, [isOpen, defaultValues]);

  //Thêm nhân viên
  const addEmployee = () => {
    setEmployeeProject([
      ...employeeProject,
      { id: Date.now(), managerId: "", commission: "" },
    ]);
  };

  // Hàm xóa nhân viên
  const deleteEmployee = async (id) => {
    if (defaultValues?.dataProjectManagers) {
      const exists = defaultValues?.dataProjectManagers.some(
        (employee) => employee.id === id
      );

      if (exists) {
        await projectManagerCall.delete(id);
      }
    }
    setEmployeeProject(employeeProject.filter((emp) => emp.id !== id));
  };

  // Hàm thay đổi dữ liệu
  const handleChange = (id, field, value) => {
    setEmployeeProject(
      employeeProject.map((emp) =>
        emp.id === id ? { ...emp, [field]: value } : emp
      )
    );
  };

  const handleFocus = (field, index) => {
    if (index !== null) {
      setFocusedIndex(index);
    } else {
      setFocusStates((prev) => ({ ...prev, [field]: true }));
    }
  };

  const handleBlur = (field, index) => {
    // setZIndex("");
    if (index !== null) {
      setFocusedIndex(index);
    } else {
      setFocusedIndex(null);
    }
    setFocusStates((prev) => ({ ...prev, [field]: false }));
  };

  const onchangeTypeProject = (option) => {
    setValue("projectTypeId", option);
  };

  const onchangeCity = (option) => {
    setValue("cityId", option);
  };

  const onchangeInvestor = (option) => {
    setValue("investorId", option);
  };

  const openFile = (e, type) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const dataUrl = e.target.result;
        setFile(dataUrl);
        setValue(type, file);
      };
      reader.readAsDataURL(file);
    }
  };

  const submitHandler = (data) => {
    const allFieldsEmpty = employeeProject.every(
      (employee) => !employee.managerId && !employee.commission
    );

    const hasAnyFieldEmpty = employeeProject.some(
      (employee) => !employee.managerId || !employee.commission
    );

    if (hasAnyFieldEmpty && !allFieldsEmpty) {
      setErrorData(true);
    } else {
      setErrorData(false);
      if (!allFieldsEmpty) data.employeeProject = employeeProject;
      if (!imageProject) data.imageProject = imageProject;
      if (!imageHonor) data.imageHonor = imageHonor;

      onSubmit(data);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        borderRadius={"16px"}
        maxW={"1210px"}
        maxH={"88vh"}
        overflow="auto"
      >
        <form maxH={"88vh"} onSubmit={handleSubmit(submitHandler)}>
          <Flex
            borderBottom={"1px solid var(--color-boder)"}
            alignItems={"center"}
            justifyContent={"space-between"}
            p={"24px 20px 24px 24px"}
          >
            <Text fontSize={"18px"} fontWeight={600} lineHeight={"normal"}>
              Tạo dự án
            </Text>
            <Image src={iconClose} onClick={onClose} cursor={"pointer"} />
          </Flex>
          <Box p={"0px 40px 24px 40px"} h={"auto"}>
            <Flex h={"auto"}>
              <Flex
                flex={1}
                mt={"24px"}
                flexDirection={"column"}
                gap={{ base: "24px" }}
              >
                <Flex flexDirection={"column"} gap={"16px"}>
                  <Text fontSize={"16px"} fontWeight={600}>
                    Thông tin dự án
                  </Text>
                  <Flex gap={"8px"} flexDirection={"column"}>
                    <InputControl
                      label="Tên dự án"
                      placeholder="Nhập tên dự án"
                      errors={errors.name}
                      register={register("name")}
                      onFocus={() => handleFocus("name")}
                      onBlur={() => handleBlur("name")}
                      state={focusStates["name"]}
                      mwidth={"100%"}
                    />
                    <Textarea
                      fontSize={{ base: "14px" }}
                      h={"207px !important"}
                      lineHeight={"17px"}
                      fontWeight={"500"}
                      placeholder="Mô tả dự án..."
                      border="1px solid var(--color-boder)"
                      borderColor="gray.200"
                      _placeholder={{
                        color: "var(--color-placeHolder-employee)",
                      }}
                      p="16px"
                      borderRadius="8px"
                      _focus={{
                        boxShadow: "none",
                        border: "1px solid var(--color-boder)",
                        zIndex: "0",
                      }}
                      {...register("description")}
                      onFocus={() => handleFocus("description")}
                      onBlur={() => handleBlur("description")}
                      isInvalid={!!errors.description}
                    />
                    {errors.description && (
                      <Text color="red.500" fontSize="sm" mt="2">
                        {errors.description.message}
                      </Text>
                    )}
                  </Flex>
                  <Flex
                    justifyContent={"space-between"}
                    alignItems={"flex-start"}
                    gap={"27px"}
                  >
                    <InputControl
                      type="number"
                      step="any"
                      label="Hệ số K"
                      placeholder="Nhập hệ số K"
                      errors={errors.kRate}
                      register={register("kRate")}
                      onFocus={() => handleFocus("kRate")}
                      onBlur={() => handleBlur("kRate")}
                      state={focusStates["kRate"]}
                      mwidth={"100%"}
                    />
                    {/* <InputControl
                      type="number"
                      step="any"
                      label="Diện tích"
                      placeholder="Nhập diện tích"
                      errors={errors.acreage}
                      register={register("acreage")}
                      onFocus={() => handleFocus("acreage")}
                      onBlur={() => handleBlur("acreage")}
                      state={focusStates["acreage"]}
                      mwidth={"100%"}
                    /> */}
                  </Flex>
                  <Flex
                    justifyContent={"space-between"}
                    alignItems={"flex-start"}
                    gap={"27px"}
                  >
                    <SelectControl
                      label="Loại hình dự án"
                      selectedMain={watch("projectTypeId")}
                      dataOption={projectTypeData}
                      placeholder="Chọn loại hình dự án"
                      errorMessage={errors.projectTypeId}
                      onChange={onchangeTypeProject}
                      onFocus={() => handleFocus("projectTypeId")}
                      onBlur={() => handleBlur("projectTypeId")}
                      state={focusStates["projectTypeId"]}
                      registerProps={register("projectTypeId")}
                    />
                    <SelectControl
                      label="Thành phố"
                      selectedMain={watch("cityId")}
                      dataOption={cityData}
                      placeholder="Chọn loại thành phố"
                      errorMessage={errors.cityId}
                      onChange={onchangeCity}
                      onFocus={() => handleFocus("cityId")}
                      onBlur={() => handleBlur("cityId")}
                      state={focusStates["cityId"]}
                      registerProps={register("cityId")}
                    />
                  </Flex>
                  <Flex
                    justifyContent={"space-between"}
                    alignItems={"flex-start"}
                    gap={"27px"}
                  >
                    <InputControl
                      label="Địa chỉ"
                      placeholder="Nhâp địa chỉ dự án"
                      errors={errors.address}
                      register={register("address")}
                      onFocus={() => handleFocus("address")}
                      onBlur={() => handleBlur("address")}
                      state={focusStates["address"]}
                      mwidth={"100%"}
                    />
                  </Flex>
                  <Flex alignItems={"center"} gap={{ base: "27px" }}>
                    <Box flex={1} position={"relative"}>
                      <FormTimeKeeping
                        title={"Tài liệu dự án"}
                        place={"Tải tài liệu lên"}
                        isIconPlace={true}
                        isIconTime={false}
                        length={"100%"}
                        register={register("fileProject")}
                        errors={errors.fileProject}
                        handleClick={openFile}
                        icon={iconFile}
                        inputTypeFile={true}
                        name={defaultValues?.nameProject || ""}
                      />
                    </Box>
                    <SelectControl
                      label="Chủ đầu tư"
                      selectedMain={watch("investorId")}
                      dataOption={investorData}
                      placeholder="Chọn chủ đầu tư"
                      errorMessage={errors.investorId}
                      onChange={onchangeInvestor}
                      onFocus={() => handleFocus("investorId")}
                      onBlur={() => handleBlur("investorId")}
                      state={focusStates["investorId"]}
                      registerProps={register("investorId")}
                    />
                  </Flex>
                </Flex>
                <Flex flexDirection={"column"} gap={"16px"}>
                  <Flex alignItems={"center"} justifyContent={"space-between"}>
                    <Text
                      fontSize={"16px"}
                      fontWeight={600}
                      lineHeight={"normal"}
                      color="var(--color-black)"
                    >
                      Nhân viên quản lý dự án
                    </Text>
                  </Flex>
                  <Flex
                    mt={"12px"}
                    flexDirection={"column"}
                    gap={"12px"}
                    alignItems={"flex-end"}
                  >
                    <Flex
                      justifyContent={"space-between"}
                      alignItems={"flex-start"}
                      gap={"12px"}
                      w={"100%"}
                      flexDirection={"column"}
                    >
                      {employeeProject.map((emp, index) => {
                        return (
                          <FormBonus
                            key={index}
                            zIndex={index}
                            isIconPlace={true}
                            isIconTime={false}
                            length={"100%"}
                            onChangeName={(e) => {
                              handleChange(emp.id, "managerId", e);
                            }}
                            onChangeBonus={(e) => {
                              handleChange(emp.id, "commission", e);
                            }}
                            deleteData={() => deleteEmployee(emp.id)}
                            icon={iconTrash}
                            nameMain={emp?.commission || null}
                            selectedMain={emp.managerId}
                            filteredOptions={employeeData}
                            isFocused={focusedIndex === index}
                            onFocus={() => handleFocus("managerProject", index)}
                            onBlur={() => handleBlur("managerProject", index)}
                          />
                        );
                      })}
                    </Flex>
                    {errorData && (
                      <Text
                        w={"100%"}
                        color={"var(--text-red-employee)"}
                        fontSize={"12px"}
                      >
                        Vui lòng nhập đầy đủ thông tin
                      </Text>
                    )}

                    <Flex
                      gap={"4px"}
                      alignItems={"end"}
                      p="8px 16px"
                      border={"1px solid var(--color-option-employee-hover)"}
                      borderRadius={"8px"}
                      w={"max-content"}
                      mr={"60px"}
                      cursor={"pointer"}
                      bg={"var(--color-main)"}
                      onClick={addEmployee}
                      _hover={{
                        filter: "brightness(90%)",
                      }}
                    >
                      <Image src={iconPlus} />
                      <Text
                        fontSize={"12px"}
                        lineHeight={"15px"}
                        color={"var(--color-option-employee-hover)"}
                        fontWeight={600}
                      >
                        Thêm NV tham gia
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
              <Box w={"1px"} m="0 28px" h={"auto"} bg={"var(--Line)"}></Box>
              <Flex flex={1} mt={"24px"} flexDirection={"column"} gap={"16px"}>
                <Text fontSize={"16px"} fontWeight={600}>
                  Ảnh dự án
                </Text>
                <Grid templateColumns="1fr" rowGap={"16px"} columnGap={"27px"}>
                  <FormInputImage
                    label={"Hình đại diện"}
                    placeholder={"Chọn hình đại diện"}
                    data={imageProject}
                    onChangeImage={openFile}
                    type={"project"}
                  />
                </Grid>
                <Grid templateColumns="1fr" rowGap={"16px"} columnGap={"27px"}>
                  <FormInputImage
                    label={"Hình vinh danh"}
                    placeholder={"Chọn hình vinh danh"}
                    data={imageHonor}
                    onChangeImage={openFile}
                    type={"honor"}
                  />
                </Grid>
              </Flex>
            </Flex>
          </Box>
          <Flex
            flex={1}
            pr={"24px"}
            alignItems={"center"}
            gap={"16px"}
            justifyContent={"flex-end"}
            position={"sticky"}
            bottom={"24px"}
            right={"24px"}
            left={"50%"}
          >
           {isEdit ? (
                <>
                  <Button
                    w={"112px"}
                    h={"44px"}
                    lineHeight={"18px"}
                    fontSize={"15px"}
                    fontWeight={600}
                    color={"var(--color-option-employee-hover)"}
                    border={"1px solid var(--color-option-employee-hover)"}
                    bg={"var(--color-main)"}
                    onClick={onClose}
                    _hover={{
                      filter: "brightness(90%)",
                    }}
                  >
                    Hủy
                  </Button>
                  <Button
                    type="submit"
                    w={"112px"}
                    h={"44px"}
                    lineHeight={"18px"}
                    fontSize={"15px"}
                    fontWeight={600}
                    color={"var(--color-main)"}
                    bg={"var(--linear-gradient-employee)"}
                    _hover={{
                      filter: "brightness(90%)",
                    }}
                  >
                    Lưu
                  </Button>
                </>
              ) : (
                <Button
                  type="submit"
                  padding={"13px 20px"}
                  lineHeight={"18px"}
                  fontSize={"15px"}
                  fontWeight={600}
                  h={"44px"}
                  color={"var(--color-main)"}
                  bg={"var(--linear-gradient-employee)"}
                  _hover={{
                    filter: "brightness(90%)",
                  }}
                >
                  Tạo dự án
                </Button>
              )}
          </Flex>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default PopupProjectManagementAdd;
