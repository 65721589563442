import {
  Box,
  Flex,
  Image,
  Link,
  ListItem,
  Modal,
  ModalContent,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
  UnorderedList,
} from "@chakra-ui/react";
import React from "react";
import iconClose from "../../assets/images/projectManagement/icon-close.svg";
// import FormProjectManagementAdd from "../form/formProjectManagementAdd";
import { formatTablePopup } from "../../pages/projectManagement/formatTablePopup";
import Slider from "../slider";
const baseURL = process.env.REACT_APP_BACKEND_API || "http://localhost:3001";
const PopupProjectManagementTitle = ({ isOpen, onClose, data }) => {
 
  const historyTableData = {
    headers: [
      {
        label: "Tên nhân viên",
        key: "name",
      },
      {
        label: "Chức danh",
        key: "position",
      },
      {
        label: "Hệ số thưởng",
        key: "commission",
      },
    ],
    data: data?.dataProjectManagers || []
  };

  const slideImage = [];
  if(data?.imageHonor?.path){
    slideImage.push(data?.imageHonor?.path);
  }

  if(data?.imageProject?.path){
    slideImage.push(data?.imageProject?.path);
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        borderRadius={"16px"}
        maxW={"1210px"}
        maxH={"88vh"}
        overflow="auto"
      >
        <Flex
          borderBottom={"1px solid var(--color-boder)"}
          alignItems={"center"}
          justifyContent={"space-between"}
          p={"24px 20px 24px 24px"}
        >
          <Text fontSize={"18px"} fontWeight={600} lineHeight={"normal"}>
            Chi tiết dự án
          </Text>
          <Image src={iconClose} onClick={onClose} cursor={"pointer"} />
        </Flex>
        <Box p={"24px 40px 107px 40px"}>
          <Flex gap={"40px"}>
            <Flex w={"50%"} flexDirection={"column"} gap={{ base: "24px" }}>
              <Flex flexDirection={"column"} gap={"8px"}>
                <Text
                  fontSize={"20px"}
                  fontWeight={600}
                  lineHeight={"140%"}
                  textTransform={"uppercase"}
                  color="var(--color-black)"
                >
                  {data?.name || ""}
                </Text>
                <Text
                  color="var(--color-black)"
                  fontSize={"14px"}
                  fontWeight={500}
                  lineHeight={"140%"}
                  textTransform={"capitalize"}
                >
                  {data?.address || ""}
                </Text>
              </Flex>
              <Flex flexDirection={"column"} gap={"16px"}>
                <Text
                  fontSize={"16px"}
                  fontWeight={600}
                  lineHeight={"normal"}
                  color="var(--color-black)"
                >
                  Đặc điểm dự án
                </Text>
                <Flex alignItems={"center"} gap={{ base: "27px" }}>
                  <Box flex={1}>
                    <Text
                      fontSize={"12px"}
                      lineHeight={"15px"}
                      color={"var(--color-label-employee)"}
                    >
                      Hệ số K
                    </Text>
                    <Text
                      mt={"8px"}
                      fontSize={"14px"}
                      lineHeight={"17px"}
                      fontWeight={500}
                      color={"var(--color-info-employee)"}
                    >
                      {data?.kRate || ""}
                    </Text>
                  </Box>
                  {/* <Box flex={1}>
                    <Text
                      fontSize={"12px"}
                      lineHeight={"15px"}
                      color={"var(--color-label-employee)"}
                    >
                      Diện tích
                    </Text>
                    <Text
                      mt={"8px"}
                      fontSize={"14px"}
                      lineHeight={"17px"}
                      fontWeight={500}
                      color={"var(--color-info-employee)"}
                    >
                      {data?.kRate || ""}m2
                    </Text>
                  </Box> */}
                </Flex>
                <Flex alignItems={"center"} gap={{ base: "27px" }}>
                  <Box flex={1}>
                    <Text
                      fontSize={"12px"}
                      lineHeight={"15px"}
                      color={"var(--color-label-employee)"}
                    >
                      Loại hình dự án
                    </Text>
                    <Text
                      mt={"8px"}
                      fontSize={"14px"}
                      lineHeight={"17px"}
                      fontWeight={500}
                      color={"var(--color-info-employee)"}
                    >
                      {data?.projectType?.name || ""}
                    </Text>
                  </Box>
                  <Box flex={1}>
                    <Text
                      fontSize={"12px"}
                      lineHeight={"15px"}
                      color={"var(--color-label-employee)"}
                    >
                      Thành phố
                    </Text>
                    <Text
                      mt={"8px"}
                      fontSize={"14px"}
                      lineHeight={"17px"}
                      fontWeight={500}
                      color={"var(--color-info-employee)"}
                    >
                       {data?.city?.name || ""}
                    </Text>
                  </Box>
                </Flex>
                <Flex alignItems={"center"} gap={{ base: "27px" }}>
                  <Box flex={1}>
                    <Text
                      fontSize={"12px"}
                      lineHeight={"15px"}
                      color={"var(--color-label-employee)"}
                    >
                      Chủ đầu tư
                    </Text>
                    <Text
                      mt={"8px"}
                      fontSize={"14px"}
                      lineHeight={"17px"}
                      fontWeight={500}
                      color={"var(--color-info-employee)"}
                    >
                      {data?.investor?.name || ""}
                    </Text>
                  </Box>
                </Flex>
                <Flex alignItems={"center"} gap={{ base: "27px" }}>
                  <Box flex={1}>
                    <Text
                      fontSize={"12px"}
                      lineHeight={"15px"}
                      color={"var(--color-label-employee)"}
                    >
                      Tài liệu dự án
                    </Text>
                    <Link
                      mt={"8px"}
                      fontSize={"14px"}
                      lineHeight={"17px"}
                      fontWeight={500}
                      color={"var(--color-secondary2)"}
                      cursor={"pointer"}
                      href={data?.investor?.name ? `${baseURL}/${data?.investor?.name}` : ""}
                      target="_blank"
                    >
                      {data?.investor?.name ? `${baseURL}/${data?.investor?.name}` : ""}
                    </Link>
                  </Box>
                </Flex>
              </Flex>
              <Flex flexDirection={"column"} gap={"16px"}>
                <Flex alignItems={"center"} justifyContent={"space-between"}>
                  <Text
                    fontSize={"16px"}
                    fontWeight={600}
                    lineHeight={"normal"}
                    color="var(--color-black)"
                  >
                    Nhân viên quản lý
                  </Text>
                </Flex>
                <Flex
                  border={"1px solid var(--color-boder)"}
                  borderRadius={"8px"}
                >
                  <TableContainer w={"100%"} pb={"32px"}>
                    <Table w={"100%"} variant="unstyled">
                      <Thead
                        fontSize={"12px"}
                        w={"100%"}
                        color="var(--color-black-label)"
                        fontWeight="400"
                        borderBottom={"1px solid var(--color-boder)"}
                      >
                        <Tr bg={"var(--fill-avatar)"}>
                          {historyTableData.headers.map((e, index) => {
                            let width = "auto";
                            let textAlign = "auto";
                            if (e.key === "name") {
                              width = "40%";
                              textAlign = "start";
                            } else if (e.key === "position") {
                              width = "20%";
                              textAlign = "start";
                            } else if (e.key === "commission") {
                              width = "20%";
                              textAlign = "start";
                            }
                            return (
                              <Td
                                p={"12px 12px 8px 16px"}
                                key={index}
                                border={"none"}
                                color={"#51607B"}
                                fontSize={{ base: "14px" }}
                                w={width}
                              >
                                <Box textAlign={textAlign}>{e.label}</Box>
                              </Td>
                            );
                          })}
                        </Tr>
                      </Thead>
                      <Tbody w={"100%"} h={"100%"}>
                        {historyTableData.data?.length > 0 ? (
                          historyTableData.data?.map((e, rowIndex) => {
                            const keyValues = Object.keys(e);
                            return (
                              <Tr
                                w={"100%"}
                                key={rowIndex}
                                h={"44px"}
                                backgroundColor={
                                  rowIndex % 2 === 0
                                    ? "#transparent"
                                    : "var(--fill-avatar)"
                                }
                              >
                                {keyValues.map((keyvalue, index) => {
                                  return (
                                    <Td p={"12px 10px 12px 16px"} key={index}>
                                      <Box
                                        fontSize={{ base: "14px" }}
                                        fontWeight={500}
                                        color="var(--color-black)"
                                        h={"140%"}
                                      >
                                        {formatTablePopup(
                                          e[keyvalue],
                                          keyvalue
                                        )}
                                      </Box>
                                    </Td>
                                  );
                                })}
                              </Tr>
                            );
                          })
                        ) : (
                          <Tr>
                            <Td
                              colSpan={historyTableData.headers.length}
                              textAlign="center"
                              padding={"40px 0"}
                            >
                              Không có dữ liệu
                            </Td>
                          </Tr>
                        )}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </Flex>
              </Flex>
            </Flex>
            <Flex w={"50%"} flexDirection={"column"} gap={"24px"}>
              <Flex flexDirection={"column"} gap={"8px"}>
                <Text fontSize={"16px"} fontWeight={600}>
                  Ảnh dự án
                </Text>
                <Slider 
                  slideDatas={slideImage}
                />
              </Flex>

              <Flex flexDirection={"column"} gap={"8px"}>
                <Text
                  fontSize={"16px"}
                  fontWeight={600}
                  lineHeight={"normal"}
                  color="var(--color-black)"
                >
                  Mô tả dự án
                </Text>
                <UnorderedList
                  m={0}
                  listStyleType="none"
                  fontSize={"14px"}
                  fontWeight={400}
                  lineHeight={"140%"}
                  textTransform={"capitalize"}
                  color="var(--color-black)"
                >
                  <ListItem>{data?.description || ""}</ListItem>
                </UnorderedList>
              </Flex>
            </Flex>
          </Flex>
        </Box>
      </ModalContent>
    </Modal>
  );
};

export default PopupProjectManagementTitle;
