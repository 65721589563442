import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { clientAPI } from "../api/client";
import { NumberOfPerPage } from "../utils/globalVariable";

const NUMBER_NFT_PER_PAGE = NumberOfPerPage.projectManagement;
// const queryKey = "attendance";

async function fetchProjectManagement(
  currentPage, 
) {
  try {
    const options = {
      limit: NUMBER_NFT_PER_PAGE,
      page: currentPage,
    };

    const queryParams = new URLSearchParams(options).toString();
    let data = await clientAPI("get", `/project/?${queryParams}`);

    const totalPages = Math.ceil(data?.pagination?.total / NUMBER_NFT_PER_PAGE);
    const entries = data?.pagination?.total;
    const startEntry =
      (data?.pagination?.page - 1) * data?.pagination?.limit + 1;
    const endEntry = Math.min(
      data?.pagination?.page * data?.pagination?.limit,
      entries
    );

    const dataProjects = data?.items.map((item, index) => {
      return item?._id;
    });
    
    let projectDocuments = await clientAPI("post", "/projectDocument/getManyPath", dataProjects);
    let projectManagers = await clientAPI("post", "/projectManager/getManyPath", dataProjects);
    let getImageHonor = await clientAPI("post", `/projectImage/getManyPath?status=HONOR`, dataProjects);
    let getImageProject = await clientAPI("post", `/projectImage/getManyPath?status=AVATAR`, dataProjects);
   
    let dataProjectManagers = projectManagers.map((e) => { 
      if (Array.isArray(e)) { 
        return e.map((item) => ({  
          id: item?.id,
          managerId: item?.managerId,
          commission: item?.commission.toString(),
        }));
      }
      return null; 
    });

    let dataProjectManagerDetails = projectManagers.map((e) => { 
      if (Array.isArray(e)) { 
        return e.map((item) => ({  
          name: item?.manager?.fullName || "",
          position: item?.manager?.jobPosition?.name || "",
          commission: item?.commission.toString() || "",
        }));
      }
      return null; 
    });
    
    const newData = data?.items.map((item, index) => {  
      return {
        id: item?._id || "",
        image: item?.images[0] || "",
        nameproject: {
          name: item?.name || "",
          des: item?.description || "",
        },
        investor: item?.investor?.name || "-",
        projectType: item?.projectType?.name || "-",
        address: item?.address || "-",
        city: item?.city?.name || "-",
        file: projectDocuments[index] ? projectDocuments[index]?.path : "",
        status: item?.status || "",
        edit: {
          id: item?._id || "",
          name: item?.name || "",
          description: item?.description || "",
          investorId: item?.investorId || "",
          kRate: item?.kRate || "",
          projectTypeId: item?.projectTypeId || "",
          cityId: item?.cityId || "",
          address: item?.address || "",
          nameProject: projectDocuments[index]?.name || "",
          dataProjectManagers: dataProjectManagers[index],
        },
        detail: {
          ...item,
          file: projectDocuments[index] ? projectDocuments[index]?.path : "",
          nameProject: projectDocuments[index]?.name || "",
          dataProjectManagers: dataProjectManagerDetails[index],
          imageHonor: getImageHonor[index],
          imageProject: getImageProject[index],
        },
      };
    });
   
    return { data: newData, totalPages, entries, startEntry, endEntry };
  } catch (error) {
    console.error("Error fetching attendance data:", error);
    return { data: [], totalPages: 0 };
  }
}

export function useProjectManagement() {
  const [currentPage, setCurrentPage] = useState(1); 
  const { data, refetch, isLoading, isRefetching } = useQuery({
    queryKey: [
      currentPage
    ],
    queryFn: () =>
      fetchProjectManagement(
        currentPage, 
      ),
    refetchOnWindowFocus: false,
    staleTime: 30000,
    keepPreviousData: true,
    onError: (error) => {
      console.error("Error fetching ProjectManagement data:", error);
    },
  });

  const nextPage = () => {
    if (currentPage < data?.totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return {
    projectManagementData: data?.data,
    totalPages: data?.totalPages,
    refetch,
    isLoading,
    isRefetching,
    prevPage,
    nextPage,
    setCurrentPage,
    currentPage,
    entries: data?.entries,
    startEntry: data?.startEntry,
    endEntry: data?.endEntry,
  };
}
