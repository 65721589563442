import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  Image,
  Button,
} from "@chakra-ui/react";
import CloseIconBlack from "../../../../assets/images/product/closeIconBlack.svg";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  InputControl,
} from "../../../../components/form/formControls";

const schema = Yup.object().shape({
  name: Yup.string().required("Vui lòng nhập tên chủ đầu tư"),
  address: Yup.string().required("Vui lòng nhập địa chỉ"),
  phone: Yup.string()
    .required("Vui lòng nhập số điện thoại")
    .matches(/^[0-9]{10,11}$/, "Số điện thoại không hợp lệ."),
});

const FormCustom = ({
  isOpen,
  onClose,
  type,
  data,
  defaultValues,
  onSubmit,
  isEdit,
  textAdd,
  textButtonAdd,
  textTitleEdit,
  dataSelect,
}) => {
  const [focusStates, setFocusStates] = useState({});

  if (Array.isArray(dataSelect)) {
    dataSelect = dataSelect.filter((item) => item?.value !== defaultValues?.id);
  }

  const {
    reset,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValues || {},
  });

  useEffect(() => {
    if (isOpen) {
      if (isEdit) {
        reset(defaultValues);
      } else {
        reset({
          name: "",
          address: "",
          phone: "",
        });
      }
    }
  }, [isOpen, isEdit, defaultValues, reset]);

  const submitHandler = (data) => {
    onSubmit(data);
  };

  const handleFocus = (field) => {
    setFocusStates((prev) => ({ ...prev, [field]: true }));
  };

  const handleCodeChange = (e) => {
    setValue("code", e.target.value, {
      shouldValidate: false,
      shouldDirty: true,
    });
  };

  const handleBlur = (field) => {
    setFocusStates((prev) => ({ ...prev, [field]: false }));
  };

  const onchangeProject = (option) => {
    setValue("projectId", option);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        backgroundColor="var(--color-main)"
        borderRadius={"16px"}
        h={"max-content"}
        w={"100%"}
        maxW={"625px"}
        overflowY={"auto"}
        sx={{
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-thumb": {
            width: "4px",
            borderRadius: "50px",
            background: "var(--color-background)",
          },
          "&::-moz-scrollbar": {
            width: "4px",
          },
          "&::-moz-scrollbar-thumb": {
            width: "4px",
            borderRadius: "50px",
            background: "var(--color-background)",
          },
        }}
      >
        <form onSubmit={handleSubmit(submitHandler)}>
          <Flex flexDirection={"column"}>
            <Flex
              justifyContent={"space-between"}
              alignItems={"center"}
              gap={"10px"}
              padding={"25px 24px"}
              borderBottom={"1px solid var(--bg-line-employee)"}
            >
              <Text
                color="var(--color-header-employee)"
                fontSize={"18px"}
                fontWeight={"600"}
              >
                {isEdit ? textTitleEdit : textAdd}
              </Text>
              <Box cursor={"pointer"} onClick={onClose}>
                <Image src={CloseIconBlack} />
              </Box>
            </Flex>
            <Box flex={1}>
              <Box
                m={"24px auto 0"}
                w={"545px"}
                borderRadius={"8px"}
                overflow={"hidden"}
              >
                <Flex
                  justifyContent={"space-between"}
                  alignItems={"flex-start"}
                  gap={"27px"}
                  mt={"16px"}
                >
                  <InputControl
                    label="Tên chủ đầu tư"
                    placeholder="Nhập tên chủ đầu tư"
                    errors={errors.name}
                    register={register("name")}
                    onFocus={() => handleFocus("name")}
                    onBlur={() => handleBlur("name")}
                    state={focusStates["name"]}
                    mwidth="100%"
                  />
                  <InputControl
                    label="Địa chỉ"
                    placeholder="Nhập địa chỉ"
                    errors={errors.address}
                    register={register("address")}
                    onFocus={() => handleFocus("address")}
                    onBlur={() => handleBlur("address")}
                    state={focusStates["address"]}
                    mwidth="100%"
                  />
                </Flex>

                <Flex
                  justifyContent={"space-between"}
                  alignItems={"flex-start"}
                  gap={"27px"}
                  mt={"16px"}
                >
                  <InputControl
                    type="number"
                    label="Số điện thoại"
                    placeholder="Nhập số điện thoại"
                    errors={errors.phone}
                    register={register("phone")}
                    onFocus={() => handleFocus("phone")}
                    onBlur={() => handleBlur("phone")}
                    state={focusStates["phone"]}
                    mwidth="100%"
                  />
                </Flex>
              </Box>
            </Box>
            <Flex p={"24px"} gap={"16px"} justifyContent={"flex-end"}>
              {isEdit ? (
                <>
                  <Button
                    w={"112px"}
                    h={"44px"}
                    lineHeight={"18px"}
                    fontSize={"15px"}
                    fontWeight={600}
                    color={"var(--color-option-employee-hover)"}
                    border={"1px solid var(--color-option-employee-hover)"}
                    bg={"var(--color-main)"}
                    onClick={onClose}
                    _hover={{
                      filter: "brightness(90%)",
                    }}
                  >
                    Hủy
                  </Button>
                  <Button
                    type="submit"
                    w={"112px"}
                    h={"44px"}
                    lineHeight={"18px"}
                    fontSize={"15px"}
                    fontWeight={600}
                    color={"var(--color-main)"}
                    bg={"var(--linear-gradient-employee)"}
                    _hover={{
                      filter: "brightness(90%)",
                    }}
                  >
                    Lưu
                  </Button>
                </>
              ) : (
                <Button
                  type="submit"
                  padding={"13px 20px"}
                  lineHeight={"18px"}
                  fontSize={"15px"}
                  fontWeight={600}
                  h={"44px"}
                  color={"var(--color-main)"}
                  bg={"var(--linear-gradient-employee)"}
                  _hover={{
                    filter: "brightness(90%)",
                  }}
                >
                  {textButtonAdd}
                </Button>
              )}
            </Flex>
          </Flex>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default FormCustom;
