import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  Image,
  Button,
  RadioGroup,
  Radio,
  SimpleGrid,
  FormControl,
  FormLabel,
  InputGroup,
  Input,
  useNumberInput,
  HStack,
  Textarea,
  InputRightElement,
} from "@chakra-ui/react";
import CloseIconBlack from "../../../assets/images/product/closeIconBlack.svg";
import Minus from "../../../assets/images/onleave/minus.svg";
import calendarIcon from "../../../assets/images/onleave/calendar.svg";
import Plus from "../../../assets/images/onleave/plus.svg";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useOnLeaveType } from "../../../hooks/useOnLeave";
import DatePicker from "react-datepicker";
import { customDateTimeFormat } from "../../../utils/utils";

const schema = Yup.object().shape({
  startDate: Yup.string().required("Vui lòng nhập ngày bắt đầu nghỉ"),
  reason: Yup.string().required("Vui lòng nhập lý do"),
});

const FormEdit = ({
  isOpen,
  onClose,
  data,
  userInfo,
  defaultValues,
  onSubmit,
}) => {
  const { leaveTypeData } = useOnLeaveType();

  const [valueInput, setValueInput] = useState(1);
  const [focusStates, setFocusStates] = useState({});
  const [inputColors, setInputColors] = useState({
    startDate: "var(--color-text-unclick)",
  });
  const [textInput, setTextInput] = useState("");

  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
    useNumberInput({
      step: 1,
      defaultValue: 1,
      min: 1,
      //   max: 6,
      value: valueInput,
      precision: 0,
      onChange: (valString) => setValueInput(Number(valString)),
    });

  const inc = getIncrementButtonProps();
  const dec = getDecrementButtonProps();
  const input = getInputProps();

  const {
    reset,
    setValue,
    getValues,
    watch,
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValues || {},
  });

  useEffect(() => {
    if (isOpen) {
      reset(defaultValues);
      setValueInput(defaultValues.daysRequested);
    }
  }, [isOpen]);

  const submitHandler = (data) => {
    const formData = {
      ...data,
      daysRequested: valueInput,
    };
    onSubmit(formData);
  };

  const handleFocus = (field) => {
    setFocusStates((prev) => ({ ...prev, [field]: true }));
  };

  const handleBlur = (field) => {
    setFocusStates((prev) => ({ ...prev, [field]: false }));
  };

  const handleDateChange = (selectedDate, inputName) => {
    setValue(inputName, selectedDate, { shouldValidate: true });
    setInputColors((prevColors) => ({
      ...prevColors,
      [inputName]: selectedDate
        ? "var(--color-info-employee)"
        : "var(--color-text-unclick)",
    }));
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        backgroundColor="var(--color-main)"
        borderRadius={"16px"}
        h={"625px"}
        w={"100%"}
        maxW={"625px"}
        overflowY={"auto"}
        sx={{
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-thumb": {
            width: "4px",
            borderRadius: "50px",
            background: "var(--color-background)",
          },
          "&::-moz-scrollbar": {
            width: "4px",
          },
          "&::-moz-scrollbar-thumb": {
            width: "4px",
            borderRadius: "50px",
            background: "var(--color-background)",
          },
        }}
      >
        <form onSubmit={handleSubmit(submitHandler)}>
          <Flex h={"625px"} flexDirection={"column"}>
            <Flex
              justifyContent={"space-between"}
              alignItems={"center"}
              gap={"10px"}
              padding={"25px 24px"}
              borderBottom={"1px solid var(--bg-line-employee)"}
            >
              <Text
                color="var(--color-header-employee)"
                fontSize={"18px"}
                fontWeight={"600"}
              >
                Chỉnh sửa Đơn xin nghỉ phép
              </Text>
              <Box cursor={"pointer"} onClick={onClose}>
                <Image src={CloseIconBlack} />
              </Box>
            </Flex>
            <Box flex={1}>
              <Flex
                alignItems={"center"}
                justifyContent={"space-between"}
                m={"24px"}
              >
                <Text
                  fontSize={"16px"}
                  lineHeight={"19px"}
                  fontWeight={600}
                  color={"var(--color-header-employee)"}
                >
                  {userInfo
                    ? `${userInfo.code} - ${userInfo.fullName}`
                    : "MSH686868-Nguyễn Thị Ngọc Anh"}
                </Text>
                <Box
                  display={"inline"}
                  fontSize={"14px"}
                  lineHeight={"17px"}
                  fontWeight={400}
                  color={"var(--color-label-employee)"}
                >
                  Ngày tạo:
                  <Text
                    display={"inline"}
                    ml={"12px"}
                    fontSize={"14px"}
                    lineHeight={"17px"}
                    fontWeight={500}
                    color={"var(--color-option-employee)"}
                  >
                    {userInfo ? customDateTimeFormat(userInfo.requestDate) : "02:58 25/12/2021"}
                  </Text>
                </Box>
              </Flex>
              <Box m={"24px auto 0"} w={"545px"}>
                <Text
                  fontSize={"12px"}
                  lineHeight={"15px"}
                  color={"--color-header-employee"}
                  fontWeight={400}
                  mb={"6px"}
                >
                  Lí do nghỉ
                </Text>
                <Controller
                  name="reason"
                  control={control}
                  render={({ field }) => (
                    <Textarea
                      padding={"13px 12px 14px 12px"}
                      fontSize={"14px"}
                      lineHeight={"19px"}
                      color={"--color-header-employee"}
                      fontWeight={600}
                      borderRadius={"8px"}
                      border={"1px solid var(--border-input-employee)"}
                      value={field.value}
                      onChange={(e) => field.onChange(e.target.value)}
                    />
                  )}
                />
                {errors.reason && (
                  <Text fontSize="12px" color="red" lineHeight={"15px"}>
                    {errors.reason.message}
                  </Text>
                )}
                <Text
                  fontSize={"12px"}
                  lineHeight={"15px"}
                  color={"--color-header-employee"}
                  fontWeight={400}
                  mb={"6px"}
                  mt={"16px"}
                >
                  Phân loại
                </Text>
                <Controller
                  name="leaveType"
                  control={control}
                  render={({ field }) => (
                    <RadioGroup
                      value={field.value}
                      name="leaveType"
                      defaultValue="0"
                      p={"16px"}
                      borderRadius={"8px"}
                      bg={"var(--fill-avatar)"}
                      onChange={field.onChange}
                    >
                      <SimpleGrid columns={2} spacing={5}>
                        {leaveTypeData &&
                          leaveTypeData.map((item, key) => (
                            <Radio
                              key={key}
                              value={item.value}
                              size="lg"
                              className="custom-radio-blue"
                              gap={"12px"}
                            >
                              <Text
                                fontSize={"15px"}
                                lineHeight={"19px"}
                                fontWeight={500}
                                color={"var(--color-info-employee)"}
                              >
                                {item.name}
                              </Text>
                              <Flex alignItems={"center"}>
                                <Text
                                  fontSize={"12px"}
                                  lineHeight={"15px"}
                                  fontWeight={400}
                                  color={"var(--color-label-employee)"}
                                >
                                  Nghỉ tối đa
                                </Text>
                                <Text
                                  ml={"3px"}
                                  fontSize={"12px"}
                                  lineHeight={"15px"}
                                  fontWeight={500}
                                  color={"var(--color-info-employee)"}
                                >
                                  {item.daysAllocated} ngày/tháng
                                </Text>
                              </Flex>
                            </Radio>
                          ))}
                      </SimpleGrid>
                    </RadioGroup>
                  )}
                />
                <Flex justifyContent={"space-between"} gap={"27px"} mt={"16px"}>
                  <FormControl zIndex={1} maxW={"259px"}>
                    <FormLabel
                      mb={"6px"}
                      lineHeight={"15px"}
                      fontSize={{ base: "12px" }}
                      fontWeight={400}
                      color={
                        focusStates["startDate"]
                          ? "var(--color-option-employee-hover)"
                          : "var(--color-label-employee)"
                      }
                    >
                      Ngày bắt đầu nghỉ
                    </FormLabel>
                    <InputGroup>
                      <DatePicker
                        width="100%"
                        selected={watch("startDate")}
                        onChange={(date) => handleDateChange(date, "startDate")}
                        dateFormat="dd/MM/yyyy"
                        locale="vi"
                        placeholderText="dd/mm/yy"
                        className="custom-datepicker datePickerStyle"
                        onFocus={() => handleFocus("startDate")}
                        onBlur={() => handleBlur("startDate")}
                      />
                      <InputRightElement pointerEvents="none" h={"44px"}>
                        <Image src={calendarIcon} />
                      </InputRightElement>
                    </InputGroup>
                    {errors.startDate && (
                      <Text fontSize={{ base: "12px" }} color={"red"}>
                        {errors.startDate.message}
                      </Text>
                    )}
                  </FormControl>
                  {/* <InputControl
                                type="date"
                                label="Ngày bắt đầu nghỉ"
                                placeholder="Nhập ngày bắt đầu nghỉ"
                                colorIp={inputColors.startDate}
                                errors={errors.startDate}
                                register={register("startDate")}
                                onFocus={() => handleFocus("startDate")}
                                onBlur={() => handleBlur("startDate")}
                                onChange={(e) => handleDateChange(e, "startDate")}
                                state={focusStates["startDate"]}
                            /> */}
                  <Box maxW={"259px"} flex={"1"}>
                    <Text
                      mb={"6px"}
                      lineHeight={"15px"}
                      fontSize={{ base: "12px" }}
                      fontWeight={400}
                      color={"var(--color-label-employee)"}
                    >
                      Số ngày nghỉ
                    </Text>
                    <HStack
                      h={"44px"}
                      maxW={"259px"}
                      borderRadius={"8px"}
                      border={"0.5px solid var(--bg-line-employee)"}
                    >
                      <Button
                        {...dec}
                        h={"100%"}
                        w={"44px"}
                        border={"none"}
                        borderRadius={0}
                        borderTopLeftRadius={"8px"}
                        borderBottomLeftRadius={"8px"}
                        bg={"var(--color-main)"}
                        borderRight={"1px solid var(--bg-line-employee)"}
                      >
                        <Image src={Minus} minW={"20px"} minH={"20px"} />
                      </Button>
                      <Input
                        {...input}
                        textAlign={"center"}
                        border={"none"}
                        outline={"none"}
                        color={"var(--color-info-employee)"}
                        fontSize={"14px"}
                        fontWeight={500}
                        sx={{
                          "&:focus-visible": {
                            borderColor: "transparent",
                            boxShadow: "none",
                            outline: "none !important",
                          },
                          "&[data-focus-visible]": {
                            borderColor: "transparent",
                            boxShadow: "none",
                            outline: "none !important",
                          },
                        }}
                      />
                      <Button
                        {...inc}
                        h={"100%"}
                        w={"44px"}
                        border={"none"}
                        borderRadius={"0"}
                        borderTopRightRadius={"8px"}
                        borderBottomRightRadius={"8px"}
                        bg={"var(--color-main)"}
                        borderLeft={"1px solid var(--bg-line-employee)"}
                      >
                        <Image src={Plus} minW={"20px"} minH={"20px"} />
                      </Button>
                    </HStack>
                  </Box>
                </Flex>
              </Box>
            </Box>
            <Flex p={"24px"} gap={"16px"} justifyContent={"flex-end"}>
              <Button
                w={"112px"}
                h={"44px"}
                color={"var(--color-option-employee-hover)"}
                border={"1px solid var(--color-option-employee-hover)"}
                bg={"var(--color-main)"}
                onClick={onClose}
                _hover={{
                  filter: "brightness(90%)",
                }}
              >
                Hủy
              </Button>
              <Button
                type="submit"
                w={"112px"}
                h={"44px"}
                color={"var(--color-main)"}
                bg={"var(--linear-gradient-employee)"}
                _hover={{
                  filter: "brightness(90%)",
                }}
              >
                Xong
              </Button>
            </Flex>
          </Flex>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default FormEdit;
