import toast from "react-hot-toast";

const { clientAPI } = require("../client");
export const projectDocumentCall = {
  create: async (data) => { 
    try {
      const response = await clientAPI("post", "/projectDocument", data);
      return response;
    } catch (error) {
      console.error("Create error:", error);
      const errorMessages = error?.response?.data?.errors?.map(
        (err) => err.message
      ) || [
        error?.response?.data?.message ||
          error?.response?.statusText ||
          error?.message ||
          "An unexpected error occurred.",
      ];

      errorMessages.forEach((message) => {
        toast.error(`${message}`);
      });
      throw error;
    }
  },
  update: async (id,data) => {
    try {
      const response = await clientAPI("put", `/projectDocument/${id}`, data);
      return response;
    } catch (error) {
      console.error("Update error:", error);
      const errorMessages = error?.response?.data?.errors?.map(
        (err) => err.message
      ) || [
        error?.response?.data?.message ||
          error?.response?.statusText ||
          error?.message ||
          "An unexpected error occurred.",
      ];

      errorMessages.forEach((message) => {
        toast.error(`${message}`);
      });
      throw error;
    }
  },
  updateByProjectId: async (data) => {
    try {
      const response = await clientAPI("put", `/projectDocument/update/updateByProjectId`, data);
      return response;
    } catch (error) {
      console.error("Update error:", error);
      const errorMessages = error?.response?.data?.errors?.map(
        (err) => err.message
      ) || [
        error?.response?.data?.message ||
          error?.response?.statusText ||
          error?.message ||
          "An unexpected error occurred.",
      ];

      errorMessages.forEach((message) => {
        toast.error(`${message}`);
      });
      throw error;
    }
  },
  delete: async (id) => {
    try {
      const response = await clientAPI("delete", `/projectDocument/${id}`);
      return response;
    } catch (error) {
      console.error("Delete error:", error);
      const errorMessages = error?.response?.data?.errors?.map(
        (err) => err.message
      ) || [
        error?.response?.data?.message ||
          error?.response?.statusText ||
          error?.message ||
          "An unexpected error occurred.",
      ];

      errorMessages.forEach((message) => {
        toast.error(`${message}`);
      });
      throw error;
    }
  },
  getManyPath: async (data) => { 
    try {
      const response = await clientAPI("post", "/projectDocument/getManyPath", data);
      return response;
    } catch (error) {
      console.error("Create error:", error);
      const errorMessages = error?.response?.data?.errors?.map(
        (err) => err.message
      ) || [
        error?.response?.data?.message ||
          error?.response?.statusText ||
          error?.message ||
          "An unexpected error occurred.",
      ];

      errorMessages.forEach((message) => {
        toast.error(`${message}`);
      });
      throw error;
    }
  },
  getAll: async () => {
    try {
      const response = await clientAPI(
        "get",
        `/projectDocument?page=1&limit=9999`
      );
      return response;
    } catch (error) {
      console.error("update error:", error);
      const errorMessages = error?.response?.data?.errors?.map(
        (err) => err.message
      ) || [
        error?.response?.data?.message ||
          error?.response?.statusText ||
          error?.message ||
          "An unexpected error occurred.",
      ];

      errorMessages.forEach((message) => {
        toast.error(`${message}`);
      });
      throw error;
    }
  },
};