import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  Image,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import iconExcel from "../../assets/images/employee/iconExcel.svg";
import plus from "../../assets/images/product/plus.svg";
import TableCustomers from "./tableCustomers";
import FormNew from "./formCustomers/formNew";
import { useCustomerList } from "../../hooks/useCustomer";
import toast from "react-hot-toast";
import useDebounce from "../../hooks/useDebounce";
import { customerCall } from "../../api/customer";
import { exportFile } from "../../utils/tools";

export default function Customers() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 500);
  const [employeeId, setEmployeeId] = useState(null);
  const [projectId, setProjectId] = useState(null);
  const {
    customerListData,
    customerListExport,
    refetch,
    totalPages,
    setCurrentPage,
    isLoading,
    isRefetching,
    currentPage,
    entries,
    startEntry,
    endEntry,
  } = useCustomerList(debouncedSearch, projectId, employeeId);

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
    refetch();
  };

  const tableData = {
    headers: [
      {
        label: "",
        key: "",
      },
      {
        label: "STT",
        key: "",
      },
      {
        label: "Tên",
        key: "name",
      },
      {
        label: "SĐT&Email",
        key: "contact",
      },
      {
        label: "Số CCCD",
        key: "identificationNumber",
      },
      {
        label: "Ngày cấp&N/c CCCD",
        key: "identificationInfo",
      },
      {
        label: "Đ/c thường trú",
        key: "permanentAddress",
      },
      {
        label: "Chỗ ở hiện tại",
        key: "currentAddress",
      },
      {
        label: "Nghề nghiệp",
        key: "career",
      },
      {
        label: "Dự án quan tâm",
        key: "project",
      },
      {
        label: "Phân loại",
        key: "type",
      },
      {
        label: "Nhân viên tạo",
        key: "employee",
      },
      {
        label: "Ngày tạo",
        key: "dateCreate",
      },
    ],
    data: customerListData,
  };

  const onSubmit = async (data) => {
    const toastCreate = toast.loading("Đang thêm quyền...");
    try {
      await customerCall.create({ ...data });
      await refetch();
      onClose();
      toast.success("Thêm thành công");
    } catch (error) {
      console.error("Error during check-in creation:", error);
      toast.error("Có lỗi xảy ra khi thêm");
    }

    toast.dismiss(toastCreate);
  };

  const handleExport = () => {
    const columnMapping = {
      name: "Tên",
      phone: "Số điện thoại",
      email: "Email",
      identificationNumber: "Số CMND/CCCD",
      dateOfIdentification: "Ngày cấp",
      placeOfIdentification: "Nơi cấp",
      permanentAddress: "Địạ chỉ thường chú",
      currentAddress: "Địa chỉ hiện tại",
      career: "Nghề nghiệp",
      project: "Dự án quan tâm",
      type: "Loại khách hàng",
      employee: "Nhân viên tạo",
      dateCreate: "Ngày tạo",
    };
    if (customerListExport && customerListExport?.length) {
      exportFile(
        customerListExport,
        columnMapping,
        "danh_sach_khach_hang.xlsx"
      );
      toast.success("Export thành công");
    } else toast.error("Không có dữ liệu");
  };

  return (
    <Box
      w={{ base: "100%" }}
      backgroundColor="var(--color-backgroundmain)"
      p={{ base: "24px 16px 16px" }}
    >
      <Flex alignItems={"center"} justifyContent={"space-between"}>
        <Flex alignItems={"center"} gap={{ base: "74px" }}>
          <Text fontSize={{ base: "20px" }} fontWeight={600}>
            Quản lý khách hàng
          </Text>
        </Flex>
        <Flex alignItems={"center"} gap={"16px"}>
          <Button
            display={"flex"}
            alignItems={"center"}
            p={"10px 16px 10px 12px"}
            gap={"8px"}
            h={"44px"}
            backgroundImage="var(--linear-gradient-excel)"
            _hover={{
              filter: "brightness(90%)",
            }}
            onClick={handleExport}
          >
            <Image src={iconExcel} />
            <Text
              fontSize={{ base: "15px" }}
              fontWeight={600}
              color="var(--color-main)"
            >
              Xuất excel
            </Text>
          </Button>
          <Button
            w={"176px"}
            h={"44px"}
            display={"flex"}
            alignItems={"center"}
            p={"10px 16px 10px 12px"}
            gap={"8px"}
            backgroundImage="var(--color-button)"
            _hover={{
              filter: "brightness(90%)",
            }}
            onClick={onOpen}
          >
            <Image src={plus} />
            <Text
              fontSize={{ base: "15px" }}
              fontWeight={600}
              color="var(--color-main)"
            >
              Tạo khách hàng
            </Text>
          </Button>
        </Flex>
      </Flex>
      <Box mt={{ base: "23px" }}>
        <TableCustomers
          tableData={tableData}
          search={search}
          setSearch={setSearch}
          employeeId={employeeId}
          setEmployeeId={setEmployeeId}
          projectId={projectId}
          setProjectId={setProjectId}
          totalPages={totalPages}
          isLoading={isLoading}
          isRefetching={isRefetching}
          currentPage={currentPage}
          entries={entries}
          startEntry={startEntry}
          endEntry={endEntry}
          handlePageChange={handlePageChange}
          refetch={refetch}
        />
      </Box>
      <FormNew
        isOpen={isOpen}
        onClose={onClose}
        isEdit={false}
        onSubmit={onSubmit}
      />
    </Box>
  );
}
