import React from "react";
import { Grid, GridItem } from "@chakra-ui/react";
import FormFeature from "./form/formFeature";
import { ROLES_CONFIG } from "../../../utils";
import { useSelector } from "react-redux";
import { useProjectCategoriesInvestor } from "../../../hooks/useProjectCategoriesInvestor";

const Investor = () => {
  const {
    investor,
    refetch,
    isLoading,
    isRefetching,
  } = useProjectCategoriesInvestor();

  const user = useSelector((state) => state.auth.login?.currentUser);
  const roles = user?.roles || [];

  const hasPermission = (requiredRoles) => {
    return (
      requiredRoles.some((role) => roles.includes(role)) ||
      roles.includes("ALL")
    );
  };

  const gridItemsData = [
    {
      title: "Danh sách chủ đầu tư",
      button: "Thêm chủ đầu tư",
      data: {
        headers: [
          { label: "", key: "" },
          { label: "STT", key: "" },
          { label: "Tên chủ đầu tư", key: "name" },
          { label: "Địa chỉ", key: "address" },
          { label: "Số điện thoại", key: "phone" },
        ],
        data: investor,
        role: ROLES_CONFIG.MENU.MENU_QLDM.CHILDREN.CHILDREN_DA.TAB.TAB_CDT.role,
      },
      type: 0,
      textDelete: "chủ đầu tư",
      textAdd: "Tạo chủ đầu tư mới",
      textButtonAdd: "Tạo chủ đầu tư mới",
      textTitleEdit: "Chỉnh sửa chủ đầu tư",
    },
  ];

  return (
    <Grid minH={"80vh"} templateColumns="repeat(1, 1fr)" gap={4}>
      {gridItemsData.map(
        (item, index) =>
          hasPermission(item.data.role) && (
            <GridItem
              key={index}
              p={"16px"}
              bg={"var(--color-main)"}
              borderRadius={"16px"}
            >
              <FormFeature
                title={item.title}
                button={item.button}
                data={item.data}
                isLoading={isLoading}
                isRefetching={isRefetching}
                refetch={refetch}
                type={item.type}
                textDelete={item?.textDelete || null}
                textAdd={item?.textAdd || null}
                textButtonAdd={item?.textButtonAdd || null}
                textTitleEdit={item?.textTitleEdit || null}
              />
            </GridItem>
          )
      )}
    </Grid>
  );
};

export default Investor;
