import React from "react";
import {
  Box,
  Flex,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import TableOpenContract from "./tableOpenContract";

export default function Contract() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box
      w={{ base: "100%" }}
      backgroundColor="var(--color-backgroundmain)"
      p={{ base: "24px 16px 16px" }}
    >
      <Flex alignItems={"center"} justifyContent={"space-between"}>
        <Flex alignItems={"center"} gap={{ base: "74px" }}>
          <Text fontSize={{ base: "20px" }} fontWeight={600}>
            Quản lý hợp đồng mua bán
          </Text>
        </Flex>
      </Flex>
      <Box mt={{ base: "23px" }}>
        <TableOpenContract />
      </Box>
      {/* <AddOpenForSale
        isOpen={isOpen}
        onClose={onClose}
        isEdit={false}
        onSubmit={() => {
          console.log("dat");
        }}
      /> */}
    </Box>
  );
}
