import toast from "react-hot-toast";
import { formatDateToLocal } from "../../utils/utils";

const { clientAPI } = require("../client");
export const attendanceCall = {
  create: async (data) => {
    try {
      const response = await clientAPI("post", "/attendance", data);
      return response;
    } catch (error) {
      console.error("Create error:", error);
      const errorMessages = error?.response?.data?.errors?.map(
        (err) => err.message
      ) || [
        error?.response?.data?.message ||
          error?.response?.statusText ||
          error?.message ||
          "An unexpected error occurred.",
      ];

      errorMessages.forEach((message) => {
        toast.error(`${message}`);
      });
      throw error;
    }
  },
  update: async (id,data) => {
    try {
      const response = await clientAPI("put", `/attendance/${id}`, data);
      return response;
    } catch (error) {
      console.error("Update error:", error);
      const errorMessages = error?.response?.data?.errors?.map(
        (err) => err.message
      ) || [
        error?.response?.data?.message ||
          error?.response?.statusText ||
          error?.message ||
          "An unexpected error occurred.",
      ];

      errorMessages.forEach((message) => {
        toast.error(`${message}`);
      });
      throw error;
    }
  },
  delete: async (id) => {
    try {
      const response = await clientAPI("delete", `/attendance/${id}`);
      return response;
    } catch (error) {
      console.error("Delete error:", error);
      const errorMessages = error?.response?.data?.errors?.map(
        (err) => err.message
      ) || [
        error?.response?.data?.message ||
          error?.response?.statusText ||
          error?.message ||
          "An unexpected error occurred.",
      ];

      errorMessages.forEach((message) => {
        toast.error(`${message}`);
      });
      throw error;
    }
  },
  export: async (entries,param) => {
    try {
      const options = {
        limit: entries,
        page: 1,
      };

      if (param.search) options.search = param.search;
      if (param.department) options.department = param.department;
      if (param.branchId) options.branchId = param.branchId;
      if (param.saleBlockId) options.saleBlockId = param.saleBlockId;
      if (param.saleAreaId) options.saleAreaId = param.saleAreaId;
      if (param.supportDepartmentId)
        options.supportDepartmentId = param.supportDepartmentId;
      if (param.date) options.date = formatDateToLocal(param.date);

      const queryParams = new URLSearchParams(options).toString();
      const response = await clientAPI("get", `/attendance?${queryParams}`);
      
      const dataExport = response?.items.map((item, index) => {
        return {
          date: (item?.month && item?.year ) ? (item?.month  + "/" + item?.year) : '' ,
          codeEmployee: item?.employeeObject?.code || "",
          fullName: item?.employeeObject?.fullName || "",
          saleBlock: item?.saleBlock?.name || "",
          saleArea: item?.saleArea?.name || "",
          jobPosition: item?.jobPosition?.name || "",
          commissionRate: item?.commissionRate || "",
          workingDays: item?.workingDays || "",
          businessCategory: item?.businessCategory?.name || "",
        };
      });

      return dataExport;
    } catch (error) {
      console.error("get list error:", error);
      const errorMessages = error?.response?.data?.errors?.map(
        (err) => err.message
      ) || [
        error?.response?.data?.message ||
          error?.response?.statusText ||
          error?.message ||
          "An unexpected error occurred.",
      ];

      errorMessages.forEach((message) => {
        toast.error(`${message}`);
      });
      throw error;
    }
  },
};