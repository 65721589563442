import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  Image,
  Text,
  useDisclosure,
  // useDisclosure,
} from "@chakra-ui/react";
import TableBookingManagement from "./tableBookingManagement";
import IconFile from "../../assets/images/booking/upfile.svg";
import iconPlus from "../../assets/images/booking/plus.svg";
import PopupImport from "../../components/popup/PopupImport";
import FormDetail from "./form/detail";
import { bookingCall } from "../../api/booking";
import { commonCall } from "../../api/common";
import { bookingImageCall } from "../../api/bookingImage";
import toast from "react-hot-toast";

export default function BookingManagement() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [popupVisible, setPopupVisible] = useState(false);

  const onSubmitAdd = async (data) => {
    const toastCreate = toast.loading("Đang thêm booking ...");
    try {
      let filteredData = Object.fromEntries(
        Object.entries(data).filter(
          ([_, value]) => value !== "" && value !== undefined && value !== null
        )
      );

      const { infident1, infident2, imagePayment, ...dataFilter } =
        filteredData;
      const dataCreateBooking = await bookingCall.create({
        ...dataFilter,
      });

      if (dataCreateBooking?._id) {
        if (data?.infident1 && Object.keys(data?.infident1).length !== 0) {
          const { filePath } = await commonCall.uploadFile(
            infident1?.file,
            "bookingDocument"
          );
          if (filePath) {
            bookingImageCall.create({
              bookingId: dataCreateBooking?._id,
              path: filePath,
              type: "IDENTIFICATION_BEFOR",
            });
          }
        }
        if (data?.infident2 && Object.keys(data?.infident2).length !== 0) {
          const { filePath } = await commonCall.uploadFile(
            infident2?.file,
            "bookingDocument"
          );
          if (filePath) {
            bookingImageCall.create({
              bookingId: dataCreateBooking?._id,
              path: filePath,
              type: "IDENTIFICATION_AFTER",
            });
          }
        }
        if (
          data?.imagePayment &&
          Object.keys(data?.imagePayment).length !== 0
        ) {
          const { filePath } = await commonCall.uploadFile(
            imagePayment?.file,
            "bookingDocument"
          );
          if (filePath) {
            bookingImageCall.create({
              bookingId: dataCreateBooking?._id,
              path: filePath,
              type: "TRANCTION",
            });
          }
        }
      }

      toast.success("Thêm thành công");
    } catch (error) {
      console.log({ error });
      toast.dismiss(toastCreate);
    }
    toast.dismiss(toastCreate);
  };

  return (
    <Box
      w={{ base: "100%" }}
      backgroundColor="var(--color-backgroundmain)"
      p={{ base: "24px 16px 16px" }}
    >
      <Flex alignItems={"center"} justifyContent={"space-between"}>
        <Flex alignItems={"center"} gap={{ base: "74px" }}>
          <Text fontSize={{ base: "20px" }} fontWeight={600}>
            Quản lý booking
          </Text>
        </Flex>
        <Flex alignItems={"center"} gap={"16px"}>
          <Button
            display="flex"
            alignItems="center"
            p="10px 16px"
            gap="8px"
            backgroundImage="var(--color-button)"
            onClick={() => setPopupVisible(true)}
            _hover={{
              filter: "brightness(90%)",
            }}
          >
            <Image src={IconFile} />
            <Text fontSize="15px" fontWeight={600} color="var(--color-main)">
              Tải file lên
            </Text>
          </Button>
          <Button
            w={"176px"}
            h={"44px"}
            display={"flex"}
            alignItems={"center"}
            p={"10px 16px 10px 12px"}
            gap={"8px"}
            background="var(--linear-gradient-employee)"
            _hover={{
              filter: "brightness(90%)",
            }}
            onClick={onOpen}
          >
            <Image src={iconPlus} />
            <Text
              fontSize={{ base: "15px" }}
              fontWeight={600}
              color="var(--color-main)"
            >
              Thêm booking
            </Text>
          </Button>
        </Flex>
      </Flex>
      <Box mt={{ base: "23px" }}>
        <TableBookingManagement />
      </Box>
      {popupVisible && (
        <PopupImport
          isOpen={popupVisible}
          onClose={() => setPopupVisible(false)}
          refetchCheckInData={{}}
        />
      )}
      <FormDetail
        isOpen={isOpen}
        onClose={onClose}
        isEdit={false}
        onSubmit={onSubmitAdd}
      />
    </Box>
  );
}
