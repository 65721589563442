import {
  Box,
  Flex,
  Text,
  Modal,
  ModalContent,
  ModalOverlay,
  Avatar,
  Image,
  Button,
  Tbody,
  Tr,
  Td,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  TableContainer,
  Table,
  Thead,
} from "@chakra-ui/react";
import React, { memo } from "react";
import IconX from "../../../assets/images/booking/x-circle.svg";
import IconCheck from "../../../assets/images/booking/check-circle.svg";
import IconRed from "../../../assets/images/booking/x-red.svg";
import Infident1 from "../../../assets/images/booking/infident1.png";
import Infident2 from "../../../assets/images/booking/infident2.png";
import Payment from "../../../assets/images/booking/payment.png";
import { formatTableValue } from "../formatTable";

const FormInfo = memo(({ isOpen, onClose, value }) => {
  const dataTable = {
    headers: [
      {
        label: "Tên nhân viên",
        key: "name",
      },
      {
        label: "Chức danh",
        key: "jobPosition",
      },
      {
        label: "Hệ số thưởng",
        key: "rate",
      },
    ],
    data: [
      {
        name: "Lê Đỗ Phương Oanh",
        jobPosition: "Nhân viên",
        rate: "20",
      },
      {
        name: "Lê Đỗ Phương Oanh",
        jobPosition: "Nhân viên",
        rate: "20",
      },
      {
        name: "Lê Đỗ Phương Oanh",
        jobPosition: "Nhân viên",
        rate: "20",
      },
    ],
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        backgroundColor="var(--color-main)"
        borderRadius={"16px"}
        position={"relative"}
        w={"100%"}
        maxW={"1217px"}
      >
        <Box
          paddingBottom={"80px"}
          h={"86.5vh"}
          w={"100%"}
          overflowY={"scroll"}
          sx={{
            "&::-webkit-scrollbar": {
              width: "4px",
            },
            "&::-webkit-scrollbar-thumb": {
              width: "4px",
              borderRadius: "50px",
              background: "var(--color-background)",
            },
            "&::-moz-scrollbar": {
              width: "4px",
            },
            "&::-moz-scrollbar-thumb": {
              width: "4px",
              borderRadius: "50px",
              background: "var(--color-background)",
            },
          }}
        >
          <Flex
            alignItems={"center"}
            p={"25px 24px 24px 24px"}
            position={"relative"}
            borderBottom={"1px solid var(--border-input-employee)"}
          >
            <Text
              color={"var(--color-header-employee)"}
              fontSize={"16px"}
              fontWeight={"600"}
            >
              Tên đợt locking
            </Text>
            <Box
              position={"absolute"}
              top={"24px"}
              right={"24px"}
              cursor={"pointer"}
              onClick={onClose}
            >
              <Image src={IconX} />
            </Box>
          </Flex>
          <Box margin={"0 auto"}>
            <Flex>
              <Box flex={1} p={"24px 24px 40px 40px"}>
                <Box>
                  <Text
                    color={"var(--color-header-employee)"}
                    fontSize={"16px"}
                    fontWeight={"600"}
                  >
                    Thông tin chung
                  </Text>
                  <Box
                    background={"var(--fill-avatar)"}
                    p={"16px 24px 16px 16px"}
                    mt={"13px"}
                    borderRadius={"8px"}
                  >
                    <Flex gap={"27px"}>
                      <Box flex={"1"}>
                        <Text
                          color={"var(--color-label-employee)"}
                          fontSize={"12px"}
                        >
                          Người tạo locking
                        </Text>
                        <Text
                          color={"var(--color-option-employee)"}
                          fontSize={"14px"}
                          fontWeight={"500"}
                          mt={"8px"}
                        >
                          Nguyễn Thục Quyên
                        </Text>
                      </Box>
                      <Box flex={"1"}>
                        <Text
                          color={"var(--color-label-employee)"}
                          fontSize={"12px"}
                        >
                          Ngày tạo locking
                        </Text>
                        <Text
                          color={"var(--color-option-employee)"}
                          fontSize={"14px"}
                          fontWeight={"500"}
                          mt={"8px"}
                        >
                          05/09/2024
                        </Text>
                      </Box>
                    </Flex>
                    <Flex mt={"16px"} gap={"27px"}>
                      <Box flex={"1"}>
                        <Text
                          color={"var(--color-label-employee)"}
                          fontSize={"12px"}
                        >
                          Dự án
                        </Text>
                        <Text
                          color={"var(--color-option-employee)"}
                          fontSize={"14px"}
                          fontWeight={"500"}
                          mt={"8px"}
                        >
                          THE DIAMOND RESIDENCE
                        </Text>
                      </Box>
                      <Box flex={"1"}>
                        <Text
                          color={"var(--color-label-employee)"}
                          fontSize={"12px"}
                        >
                          Mã sản phẩm
                        </Text>
                        <Text
                          color={"var(--color-option-employee)"}
                          fontSize={"14px"}
                          fontWeight={"500"}
                          mt={"8px"}
                        >
                          SP1
                        </Text>
                      </Box>
                    </Flex>
                    <Flex mt={"16px"} gap={"27px"}>
                      <Box flex={"1"}>
                        <Text
                          color={"var(--color-label-employee)"}
                          fontSize={"12px"}
                        >
                          Số tiền đặt cọc
                        </Text>
                        <Text
                          color={"var(--color-option-employee)"}
                          fontSize={"14px"}
                          fontWeight={"500"}
                          mt={"8px"}
                        >
                          135.000.000 đ
                        </Text>
                      </Box>
                      <Box flex={"1"}>
                        <Text
                          color={"var(--color-label-employee)"}
                          fontSize={"12px"}
                        >
                          Thời gian đặt cọc
                        </Text>
                        <Text
                          color={"var(--color-option-employee)"}
                          fontSize={"14px"}
                          fontWeight={"500"}
                          mt={"8px"}
                        >
                          05/09/2024
                        </Text>
                      </Box>
                    </Flex>
                  </Box>
                </Box>
                <Box mt={"20px"}>
                  <Text
                    color={"var(--color-header-employee)"}
                    fontSize={"16px"}
                    fontWeight={"600"}
                  >
                    Chi tiết sản phẩm
                  </Text>
                  <Box
                    background={"var(--fill-avatar)"}
                    p={"16px 24px 16px 16px"}
                    mt={"16px"}
                    borderRadius={"8px"}
                  >
                    <Flex gap={"27px"}>
                      <Box flex={"1"}>
                        <Text
                          color={"var(--color-label-employee)"}
                          fontSize={"12px"}
                        >
                          Tên sản phẩm
                        </Text>
                        <Text
                          color={"var(--color-option-employee)"}
                          fontSize={"14px"}
                          fontWeight={"500"}
                          mt={"8px"}
                        >
                          Sản phẩm cc A
                        </Text>
                      </Box>
                      <Box flex={"1"}>
                        <Text
                          color={"var(--color-label-employee)"}
                          fontSize={"12px"}
                        >
                          Loại hình
                        </Text>
                        <Text
                          color={"var(--color-option-employee)"}
                          fontSize={"14px"}
                          fontWeight={"500"}
                          mt={"8px"}
                        >
                          Loại hình sản phẩm
                        </Text>
                      </Box>
                    </Flex>
                    <Flex mt={"16px"} gap={"27px"}>
                      <Box flex={"1"}>
                        <Text
                          color={"var(--color-label-employee)"}
                          fontSize={"12px"}
                        >
                          Hướng
                        </Text>
                        <Text
                          color={"var(--color-option-employee)"}
                          fontSize={"14px"}
                          fontWeight={"500"}
                          mt={"8px"}
                        >
                          Đông nam
                        </Text>
                      </Box>
                      <Box flex={"1"}>
                        <Text
                          color={"var(--color-label-employee)"}
                          fontSize={"12px"}
                        >
                          Tính chất
                        </Text>
                        <Text
                          color={"var(--color-option-employee)"}
                          fontSize={"14px"}
                          fontWeight={"500"}
                          mt={"8px"}
                        >
                          Tính chất sản phẩm
                        </Text>
                      </Box>
                    </Flex>
                    <Flex mt={"16px"} gap={"27px"}>
                      <Box flex={"1"}>
                        <Text
                          color={"var(--color-label-employee)"}
                          fontSize={"12px"}
                        >
                          DT đất
                        </Text>
                        <Text
                          color={"var(--color-option-employee)"}
                          fontSize={"14px"}
                          fontWeight={"500"}
                          mt={"8px"}
                        >
                          120m2
                        </Text>
                      </Box>
                      <Box flex={"1"}>
                        <Text
                          color={"var(--color-label-employee)"}
                          fontSize={"12px"}
                        >
                          DT xây dựng
                        </Text>
                        <Text
                          color={"var(--color-option-employee)"}
                          fontSize={"14px"}
                          fontWeight={"500"}
                          mt={"8px"}
                        >
                          120m2
                        </Text>
                      </Box>
                    </Flex>
                    <Flex mt={"16px"} gap={"27px"}>
                      <Box flex={"1"}>
                        <Text
                          color={"var(--color-label-employee)"}
                          fontSize={"12px"}
                        >
                          Phòng ngủ
                        </Text>
                        <Text
                          color={"var(--color-option-employee)"}
                          fontSize={"14px"}
                          fontWeight={"500"}
                          mt={"8px"}
                        >
                          3
                        </Text>
                      </Box>
                      <Box flex={"1"}>
                        <Text
                          color={"var(--color-label-employee)"}
                          fontSize={"12px"}
                        >
                          Phòng VS
                        </Text>
                        <Text
                          color={"var(--color-option-employee)"}
                          fontSize={"14px"}
                          fontWeight={"500"}
                          mt={"8px"}
                        >
                          2
                        </Text>
                      </Box>
                    </Flex>
                    <Flex mt={"16px"} gap={"27px"}>
                      <Box flex={"1"}>
                        <Text
                          color={"var(--color-label-employee)"}
                          fontSize={"12px"}
                        >
                          Phân khu
                        </Text>
                        <Text
                          color={"var(--color-option-employee)"}
                          fontSize={"14px"}
                          fontWeight={"500"}
                          mt={"8px"}
                        >
                          Phân khu A
                        </Text>
                      </Box>
                      <Box flex={"1"}>
                        <Text
                          color={"var(--color-label-employee)"}
                          fontSize={"12px"}
                        >
                          Giá trị
                        </Text>
                        <Text
                          color={"var(--color-option-employee)"}
                          fontSize={"14px"}
                          fontWeight={"500"}
                          mt={"8px"}
                        >
                          3.950.000.000
                        </Text>
                      </Box>
                    </Flex>
                  </Box>
                  <Box mt={"20px"}>
                    <Text
                      color={"var(--color-header-employee)"}
                      fontSize={"16px"}
                      fontWeight={"600"}
                    >
                      Nhân viên tham gia
                    </Text>
                    <Box
                      background={"var(--fill-avatar)"}
                      mt={"16px"}
                      borderRadius={"8px"}
                    >
                      <TableContainer w={"100%"}>
                        <Table w={"100%"} variant="unstyled">
                          <Thead
                            w={"100%"}
                            h={"41px"}
                            color="white"
                            fontWeight="400"
                            backgroundColor="#F9FAFD"
                            borderBottom={"1px solid var(--bg-line-employee)"}
                          >
                            <Tr h={{ base: "41px" }}>
                              {dataTable.headers.map((e, index) => {
                                let width = "auto";
                                let textAlign = "auto";
                                if (e.key === "name") {
                                  width = "2%";
                                  textAlign = "start";
                                } else if (e.key === "jobPosition") {
                                  width = "5%";
                                  textAlign = "start";
                                } else {
                                  width = "10%";
                                  textAlign = "end";
                                }
                                return (
                                  <Td
                                    p={"16px 16px 8px 16px"}
                                    key={index}
                                    border={"none"}
                                    color={"#51607B"}
                                    fontSize={{ base: "14px" }}
                                    w={width}
                                  >
                                    <Box textAlign={textAlign}>{e.label}</Box>
                                  </Td>
                                );
                              })}
                            </Tr>
                          </Thead>
                          <Tbody w={"100%"} h={"100%"}>
                            {dataTable.data?.length > 0 ? (
                              dataTable.data?.map((e, rowIndex) => {
                                const keyValues = Object.keys(e);
                                return (
                                  <Tr 
                                    key={rowIndex} 
                                    h={"44px"}  
                                    backgroundColor={
                                    rowIndex % 2 === 0
                                      ? "var(--color-main)"
                                      : "var(--fill-avatar)"
                                    }
                                  >
                                    {keyValues.map((keyvalue, index) => {
                                      let width = "auto";
                                      let widthText = "100%";
                                      let textAlign = "auto";
                                      let whiteSpace = "inherit";
                                      if (keyvalue === "name") {
                                        width = "40%";
                                        textAlign = "start";
                                      } else if (keyvalue === "jobPosition") {
                                        width = "40%";
                                        textAlign = "start";
                                      } else {
                                        width = "20%";
                                        textAlign = "center";
                                        whiteSpace = "inherit";
                                      }
                                      return (
                                        <Td
                                          p={"16px 16px 8px 16px"}
                                          w={width}
                                          key={index}
                                        >
                                          <Box
                                            fontSize={{ base: "14px" }}
                                            lineHeight={"19.6px"}
                                            textAlign={textAlign}
                                            fontWeight={500}
                                            color={"var(--color-info-employee)"}
                                            w={widthText}
                                            whiteSpace={whiteSpace}
                                            overflow="hidden"
                                            textOverflow="ellipsis"
                                            display="-webkit-box"
                                            sx={{
                                              WebkitLineClamp: "2",
                                              WebkitBoxOrient: "vertical",
                                            }}
                                          >
                                            {formatTableValue(
                                              e[keyvalue],
                                              keyvalue
                                            )}
                                          </Box>
                                        </Td>
                                      );
                                    })}
                                  </Tr>
                                );
                              })
                            ) : (
                              <Tr>
                                <Td
                                  colSpan={dataTable.headers.length}
                                  textAlign="center"
                                  padding={"40px 0"}
                                >
                                  Không có dữ liệu
                                </Td>
                              </Tr>
                            )}
                          </Tbody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box w={"0.5px"} bg={"var(--bg-line-employee)"}></Box>
              <Box flex={1} p={"24px 40px 40px 24px"}>
                <Box mt={"16px"}>
                  <Flex gap={"27px"}>
                    <Box flex={"1"}>
                      <Text
                        color={"var(--color-label-employee)"}
                        fontSize={"12px"}
                      >
                        Ảnh CCCD mặt trước
                      </Text>
                      <Box
                        mt={"8px"}
                        border={"0.5px solid var(--bg-line-employee)"}
                        w={"259px"}
                        height={"146px"}
                        borderRadius={"8px"}
                      >
                        <Image
                          borderRadius={"8px"}
                          w={"100%"}
                          h={"100%"}
                          objectFit={"cover"}
                          src={Infident1}
                        />
                      </Box>
                    </Box>
                    <Box flex={"1"}>
                      <Text
                        color={"var(--color-label-employee)"}
                        fontSize={"12px"}
                      >
                        Ảnh CCCD mặt trước
                      </Text>
                      <Box
                        mt={"8px"}
                        border={"0.5px solid var(--bg-line-employee)"}
                        w={"259px"}
                        height={"146px"}
                        borderRadius={"8px"}
                      >
                        <Image
                          borderRadius={"8px"}
                          w={"100%"}
                          h={"100%"}
                          objectFit={"cover"}
                          src={Infident2}
                        />
                      </Box>
                    </Box>
                  </Flex>
                </Box>
                <Box mt={"16px"}>
                  <Flex>
                    <Box flex={"1"}>
                      <Text
                        color={"var(--color-label-employee)"}
                        fontSize={"12px"}
                      >
                        Ảnh ủy nhiệm chi
                      </Text>
                      <Flex
                        mt={"8px"}
                        border={"0.5px solid var(--bg-line-employee)"}
                        borderRadius={"8px"}
                        justifyContent={"center"}
                      >
                        <Image
                          borderRadius={"11px"}
                          w={"202px"}
                          h={"300px"}
                          objectFit={"cover"}
                          src={Payment}
                        />
                      </Flex>
                    </Box>
                  </Flex>
                </Box>
              </Box>
            </Flex>
          </Box>
          <Box
            position={"fixed"}
            w={"100%"}
            bottom={"0"}
            bg={"var(--color-main)"}
            borderTop={"0.5px solid var(--bg-line-employee)"}
            borderRadius={"0px 0px 16px 16px"}
          >
            <Flex p={"24px"} gap={"16px"} justifyContent={"flex-end"}>
              <Button
                p={"13px 20px"}
                fontSize={"15px"}
                fontWeight={600}
                color={"var(--color-option-employee-hover)"}
                borderRadius={"8px"}
                border={"1px solid var(--color-option-employee-hover)"}
                bg={"var(--color-main)"}
                _hover={{
                  filter: "brightness(90%)",
                }}
              >
                Không duyệt
              </Button>
              <Button
                w={"133px"}
                p={"13px 20px"}
                fontSize={"15px"}
                fontWeight={600}
                color={"var(--color-main)"}
                borderRadius={"8px"}
                bg={"var(--color-option-employee-hover)"}
                _hover={{
                  filter: "brightness(90%)",
                }}
              >
                Duyệt
              </Button>
            </Flex>
            {/* <Flex p={"16px 24px"} justifyContent={"space-between"}>
              <Flex
                p={"12px"}
                justifyContent={"space-between"}
                gap={"8px"}
                bg={"var(--bg-status-success)"}
                borderRadius={"8px"}
                w={"max-content"}
                minW={"151px"}
                h={"max-content"}
              >
                <Image
                  src={IconCheck}
                  w={"20px"}
                  h={"20px"}
                  objectFit={"cover"}
                />
                <Text
                  fontSize={"14px"}
                  lineHeight={"20px"}
                  fontWeight={500}
                  color={"var(--color-status-employ)"}
                  display={"block"}
                >
                  Đã được duyệt
                </Text>
              </Flex>
              <Flex
                p={"12px"}
                justifyContent={"space-between"}
                gap={"8px"}
                bg={"var(--bg-status-error)"}
                borderRadius={"8px"}
                w={"max-content"}
                minW={"176px"}
                h={"max-content"}
              >
                <Image src={IconRed} w={"20px"} h={"20px"} objectFit={"cover"} />
                <Text
                  fontSize={"14px"}
                  lineHeight={"20px"}
                  fontWeight={500}
                  color={"var(--text-red-employee)"}
                  display={"block"}
                >
                  Không được duyệt
                </Text>
              </Flex>
              <Flex gap={"40px"}>
                <Box textAlign={"end"}>
                  <Text color={"var(--color-label-employee)"} fontSize={"12px"}>
                    Người phê duyệt
                  </Text>
                  <Text
                    color={"var(--color-option-employee)"}
                    fontSize={"14px"}
                    fontWeight={"500"}
                    mt={"8px"}
                  >
                    Nguyễn Thị Ngọc Ánh
                  </Text>
                </Box>
                <Box textAlign={"end"}>
                  <Text color={"var(--color-label-employee)"} fontSize={"12px"}>
                    Thời gian duyệt
                  </Text>
                  <Text
                    color={"var(--color-option-employee)"}
                    fontSize={"14px"}
                    fontWeight={"500"}
                    mt={"8px"}
                  >
                    05/09/2024
                  </Text>
                </Box>
              </Flex>
            </Flex> */}
          </Box>
        </Box>
      </ModalContent>
    </Modal>
  );
});

export default FormInfo;
