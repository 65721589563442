import { Box, Flex, Image, Text } from "@chakra-ui/react";
import iconFile from "../../assets/images/projectManagement/file-text (1).svg";
import noImage from "../../assets/images/projectManagement/noImage.png";

const baseURL = process.env.REACT_APP_BACKEND_API || "http://localhost:3001";

export const formatTableValue = (value, key, popupFile, data) => {  
  switch (key) {
    case "id":
      return <Text>{value}</Text>;
    case "image":
      const isImage = value;
      const path = isImage?.path ? `${baseURL}/${isImage?.path}` : "";
      
      return (
        <Box>
          {isImage ? (
            <Image
              width={"100%"}
              minW={"153px"}
              h={"86px"}
              src={path}
            ></Image>
          ) : (
            // <Box h={"48px"} w={"100%"} backgroundColor={"#D9D9D9"}></Box>
            <Image
              width={"100%"}
              minW={"153px"}
              h={"86px"}
              src={noImage}
            ></Image>
          )}
        </Box>
      );
    case "nameproject":
      return (
        <Box>
          <Text textAlign={"start"}>{value.name}</Text>
          <Text
            fontSize={"14px"}
            fontWeight={400}
            color={"var(--color-label-employee)"}
            textAlign={"start"}
          >
            {value.des}
          </Text>
        </Box>
      );
    case "investor":
      return <Text textAlign={"start"}>{value}</Text>;
    case "classify":
      return <Text>{value}</Text>;
    case "address":
      return <Text>{value}</Text>;
    case "city":
      return <Text>{value}</Text>;
    case "file":
      return value ? (
        <Flex
          justifyContent={"flex-end"}
          cursor={"pointer"}
          onClick={() =>popupFile(`${baseURL}/${value}`,data)}
        >
          <Image src={iconFile} />
        </Flex>
      ) : (
        <Text>{value}</Text>
      );
    default:
      return <Text>{value}</Text>;
  }
};
