import React, { useEffect, useState } from "react";
import { Box, Button, Flex, Image } from "@chakra-ui/react";
const baseURL = process.env.REACT_APP_BACKEND_API || "http://localhost:3001";
const Slider = ({ slideDatas }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slides, setSlides] = useState([]);

  useEffect(() => {
    if (Array.isArray(slideDatas)) {
      setSlides(slideDatas);
    }
  }, [slideDatas]);

  const handleSlideChange = (index) => {
    setCurrentSlide(index);
  };

  return (
    <Box
      width="100%"
      h="307px"
      backgroundColor="var(--color-background)"
      position="relative"
      overflow="hidden"
      border="1px solid lightgray"
      borderRadius="8px"
      mx="auto"
    >
      {slides.length > 0 && (
        <Image
          src={slides[currentSlide] ? `${baseURL}/${slides[currentSlide]}` : ""}
          width="100%"
          height="100%"
          objectFit="cover"
        />
      )}

      <Flex
        gap={"7px"}
        position="absolute"
        bottom="20px"
        left="50%"
        transform="translateX(-50%)"
      >
        {slides.map((item, index) => (
          <Button
            borderRadius={"4px"}
            w={"56px"}
            h={"37px"}
            key={index}
            bg={currentSlide === index ? "gray.600" : "gray.400"}
            onClick={() => handleSlideChange(index)}
          >
            <Image
              src={`${baseURL}/${item}`}
              width="100%"
              height="100%"
              objectFit="cover"
            />
          </Button>
        ))}
      </Flex>
    </Box>
  );
};

export default Slider;
