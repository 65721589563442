import React, { useEffect, useState } from "react";
import {
  Button,
  Text,
  Flex,
  Image,
  ModalContent,
  ModalOverlay,
  Modal,
  Box,
  Input,
  FormControl,
  FormLabel,
  InputGroup,
  InputRightElement,
  Link,
} from "@chakra-ui/react";
import iconClose from "../../assets/images/projectManagement/x-circle.svg";
import iconButton from "../../assets/images/employee/plus.svg";
import iconTextFile from "../../assets/images/employee/image.svg";
import trashIcon from "../../assets/images/employee/trash-2.svg";
import downloadIcon from "../../assets/images/employee/Frame.svg";
import { useForm } from "react-hook-form";
import { employeeDocumentCall } from "../../api/employeeDocument";
import DeleteDialog from "../dialog/delete";
import toast from "react-hot-toast";
const baseURL = process.env.REACT_APP_BACKEND_API || "http://localhost:3001";
const PopupFileDocument = ({
  isOpen,
  onClose,
  onSubmit,
  defaultValues,
  value,
  name,
}) => {
  const { handleSubmit } = useForm({
    defaultValues: defaultValues || {},
  });

  const [dataEmployeeDocument, setDataEmployeeDocument] = useState([]);
  const [dataDelete, setDataDelete] = useState(null);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const dataFileEmployee = await employeeDocumentCall.getByEmployeeId(
          value
        );

        if (Array.isArray(dataFileEmployee?.items)) {
          const datas = dataFileEmployee?.items.map((item) => { 
            const fileExtension = item?.path.substring(item?.path.lastIndexOf('.') + 1);
            const fileName = item?.name.includes('.') 
              ? item?.name 
              : `${item?.name}.${fileExtension}`; 
          
            return {
              id: item?._id || "",
              path: item?.path || "",
              pathOrigin: item?.path || "",
              name: item?.name || "",
              fileName: fileName || "",
              status: true,
              init: true,
            };
          });
          setDataEmployeeDocument(datas);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [isOpen, value]);

  // Thêm tài liệu
  const addDocument = () => {
    setIsError(false);
    setDataEmployeeDocument([
      ...dataEmployeeDocument,
      { id: Date.now(), path: "", name: "" },
    ]);
  };

  // Hàm xóa tài liệu
  const deleteEmployee = (id) => {
    setIsError(false);
    const employeeToDelete = dataEmployeeDocument.find((emp) => emp.id === id);

    if (employeeToDelete?.init) {
      setDataDelete(id);
    } else {
      setDataEmployeeDocument(
        dataEmployeeDocument.filter((emp) => emp.id !== id)
      );
    }
  };

  // Hàm thay đổi dữ liệu
  const handleChange = (id, event) => {
    setIsError(false);
    const value = event.target.value;
    setDataEmployeeDocument((prev) =>
      prev.map((emp) =>
        emp.id === id ? { ...emp, name: value, status: false } : emp
      )
    );
  };

  const handleChangeFile = (id, e) => {
    setIsError(false);
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setDataEmployeeDocument(
          dataEmployeeDocument.map((emp) =>
            emp.id === id
              ? {
                  ...emp,
                  file: file,
                  fileName: file?.name,
                  // name: file?.name,
                  status: false,
                }
              : emp
          )
        );
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCloseDelete = () => {
    setIsError(false);
    setDataDelete(null);
  };

  const handleDelete = async (id) => {
    setIsError(false);
    const toastUpdate = toast.loading("Đang xóa tài liệu ...");
    try {
      await employeeDocumentCall.delete(id);
      setDataEmployeeDocument(
        dataEmployeeDocument.filter((emp) => emp.id !== id)
      );
      toast.success("Xóa thành công");
      setDataDelete(null);
    } catch (error) {
      console.log({ error });
    }
    toast.dismiss(toastUpdate);
  };

  const submitHandler = (data) => {  
    data = dataEmployeeDocument.filter(
      // (item) => !item.file || !(item.path === "" && item.name === "")
      (item) => !item?.status
    ); 
    const hasEmptyItems = data.some(
      (item) => (!item.file && item.init===false) || !item.name 
    );
    
    if (hasEmptyItems) {
      setIsError(true);
    } else {
      setIsError(false);
      onSubmit(value, data);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        borderRadius={"16px"}
        maxW={"625px"}
        maxH={"88vh"}
        overflow="auto"
      >
        <form onSubmit={handleSubmit(submitHandler)}>
          <Flex
            borderBottom={"1px solid var(--color-boder)"}
            alignItems={"center"}
            justifyContent={"space-between"}
            p={"24px 20px 24px 24px"}
          >
            <Text fontSize={"18px"} fontWeight={600} lineHeight={"normal"}>
              Tài liệu nhân viên
            </Text>
            <Image src={iconClose} onClick={onClose} cursor={"pointer"} />
          </Flex>
          <Box p={"24px"}>
            <Flex
              justifyContent={"space-between"}
              alignItems={"center"}
              gap={"10px"}
            >
              <Text
                color="var(--color-header-employee)"
                fontSize={"16px"}
                fontWeight={"600"}
              >
                {name || ""}
              </Text>
            </Flex>
            <Box flex={1} m={"24px 0 24px 24px"}>
              <Flex borderRadius="8px" width="100%" flexDirection={"column"}>
                <Box w={"100%"}>
                  {dataEmployeeDocument &&
                    dataEmployeeDocument.map((item, index) => (
                      <Box
                        key={index}
                        pb={"20px"}
                        mb={
                          index === dataEmployeeDocument.length - 1
                            ? "0"
                            : "20px"
                        }
                        borderBottom={
                          index === dataEmployeeDocument.length - 1
                            ? "none"
                            : "1px solid var(--border-input-employee)"
                        }
                      >
                        <Flex
                          gap={"20px"}
                          justifyContent={"space-between"}
                          w={"100%"}
                        >
                          <Box w={"100%"}>
                            <FormControl w={"100%"}>
                              <FormLabel
                                lineHeight={"15px"}
                                m="0"
                                fontSize={{ base: "12px" }}
                                mb={"6px"}
                                fontWeight={400}
                                color={"var(--color-black-label)"}
                              >
                                Tên tài liệu
                              </FormLabel>
                              <Flex
                                justifyContent={"space-between"}
                                alignItems={"center"}
                              >
                                <Input
                                  maxW={"521px"}
                                  placeholder="Vui lòng nhập tên tài liệu"
                                  value={item?.name || ""}
                                  onChange={(e) => handleChange(item?.id, e)}
                                />
                                <Flex>
                                  <Box cursor={"pointer"} h={"16px"}>
                                    <Image
                                      w={"16px"}
                                      h={"16px"}
                                      objectFit={"cover"}
                                      cursor={"pointer"}
                                      src={trashIcon}
                                      onClick={() => deleteEmployee(item?.id)}
                                      _hover={{
                                        filter: "var(--filter-red)",
                                      }}
                                    />
                                  </Box>
                                </Flex>
                              </Flex>
                            </FormControl>
                            <Box
                              cursor={"pointer"}
                              mt={"12px"}
                              position={"relative"}
                            >
                              <Text
                                lineHeight={"15px"}
                                m="0"
                                fontSize={{ base: "12px" }}
                                mb={"6px"}
                                fontWeight={400}
                                color={"var(--color-black-label)"}
                              >
                                Ảnh tài liệu
                              </Text>
                              <Flex
                                justifyContent={"space-between"}
                                alignItems={"center"}
                              >
                                <InputGroup
                                  mt={"0"}
                                  p={{ base: "14px 12px 13px 12px" }}
                                  alignItems={"center"}
                                  h={"44px"}
                                  border={"0.5px solid var(--Line)"}
                                  borderRadius={"8px"}
                                  _focus={{
                                    boxShadow: "none",
                                  }}
                                  maxW={"521px"}
                                  w={"100%"}
                                >
                                  <Input
                                    type="file"
                                    opacity={0}
                                    position={"absolute"}
                                    top={0}
                                    left={0}
                                    w={"100%"}
                                    h={"100%"}
                                    cursor={"pointer"}
                                    onChange={(e) =>
                                      handleChangeFile(item?.id, e)
                                    }
                                    accept="
                                  image/png,
                                  image/jpg,
                                  image/jpeg,
                                  image/webp,
                                  application/pdf,
                                  application/msword,
                                  application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                                  application/vnd.ms-excel,
                                  application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                                  text/csv
                                "
                                    zIndex={3}
                                  />
                                  <Text
                                    step={"auto"}
                                    color="var(--text-color-Subtittle)"
                                    fontWeight={500}
                                    fontSize={"14px"}
                                    border={"none"}
                                    p={"0px"}
                                    // placeholder={"Vui lòng chọn dữ liệu"}
                                    type={"text"}
                                    _focus={{
                                      boxShadow: "none",
                                    }}
                                  >
                                    {(item?.fileName
                                      ? item?.fileName
                                      : item?.path
                                      ? item?.name
                                      : "Vui lòng chọn tài liệu") ||
                                      "Vui lòng chọn tài liệu"}
                                  </Text>
                                  <InputRightElement>
                                    <Image
                                      zIndex={"0"}
                                      cursor={"pointer"}
                                      src={iconTextFile}
                                    />
                                  </InputRightElement>
                                </InputGroup>
                                {item?.status && (
                                  <Box
                                    cursor={"pointer"}
                                    position={"relative"}
                                    h={"16px"}
                                  >
                                    <Image
                                      w={"16px"}
                                      h={"16px"}
                                      objectFit={"cover"}
                                      cursor={"pointer"}
                                      src={downloadIcon}
                                      filter={"var(--filter-red)"}
                                    />
                                    <Link
                                      position={"absolute"}
                                      opacity={0}
                                      top={0}
                                      left={0}
                                      w={"100%"}
                                      h={"100%"}
                                      target="_blank"
                                      href={baseURL + "/" + item?.path}
                                      download
                                    ></Link>
                                  </Box>
                                )}
                              </Flex>
                            </Box>
                          </Box>
                        </Flex>
                      </Box>
                    ))}
                </Box>
                {isError && (
                  <Text fontSize="12px" color="red" lineHeight={"15px"}>
                    Vui lòng nhập đầy đủ dữ liệu
                  </Text>
                )}
                <Flex justifyContent="end" w={"100%"}>
                  <Button
                    w={"127px"}
                    h={"32px"}
                    border={"1px solid var(--color-option-employee-hover)"}
                    bg={"var(--color-main)"}
                    _hover={{
                      filter: "brightness(90%)",
                    }}
                    onClick={addDocument}
                  >
                    <Flex
                      alignItems={"center"}
                      p={"10px 0px 10px 0px"}
                      gap={"4px"}
                    >
                      <Image src={iconButton} />
                      <Text
                        fontSize={{ base: "12px" }}
                        lineHeight={"15px"}
                        fontWeight={600}
                        color={"var(--color-option-employee-hover)"}
                      >
                        Thêm tài liệu
                      </Text>
                    </Flex>
                  </Button>
                </Flex>
              </Flex>
            </Box>
            <Box mt={"85px"}>
              <Flex
                flex={1}
                pr={"24px"}
                alignItems={"center"}
                gap={"16px"}
                justifyContent={"flex-end"}
                bottom={"24px"}
                right={"24px"}
                left={"50%"}
              >
                <Button
                  w={"112px"}
                  h={"44px"}
                  lineHeight={"18px"}
                  fontSize={"15px"}
                  fontWeight={600}
                  color={"var(--color-option-employee-hover)"}
                  border={"1px solid var(--color-option-employee-hover)"}
                  bg={"var(--color-main)"}
                  onClick={onClose}
                  _hover={{
                    filter: "brightness(90%)",
                  }}
                >
                  Hủy
                </Button>
                <Button
                  type="submit"
                  w={"112px"}
                  h={"44px"}
                  lineHeight={"18px"}
                  fontSize={"15px"}
                  fontWeight={600}
                  color={"var(--color-main)"}
                  bg={"var(--linear-gradient-employee)"}
                  _hover={{
                    filter: "brightness(90%)",
                  }}
                >
                  Lưu
                </Button>
              </Flex>
            </Box>
          </Box>
        </form>
      </ModalContent>
      {dataDelete && (
        <DeleteDialog
          isOpen={!!dataDelete}
          onClose={handleCloseDelete}
          title={"Xóa tài liệu"}
          description={`Bạn có muốn xóa tài liệu này không ?`}
          action={() => handleDelete(dataDelete)}
        />
      )}
    </Modal>
  );
};

export default PopupFileDocument;
