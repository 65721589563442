import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  Image,
  Button,
} from "@chakra-ui/react";
import CloseIconBlack from "../../../../assets/images/product/closeIconBlack.svg";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  InputControl,
  SelectControl,
} from "../../../../components/form/formControls";
import { generateCodeFromName, removeAccents } from "../../../../utils/utils";
import { divisionCall } from "../../../../api/division";

const schemaType1 = Yup.object().shape({
  code: Yup.string()
    .required("Vui lòng nhập mã phân khu")
    .test("is-unique", "Mã đã tồn tại", async function (value) {
      if (!value) return true;

      const { id } = this.parent;
      if (id) {
        return true;
      }

      return await divisionCall.checkCodeUnique(value, id || null);
    }),
  name: Yup.string().optional().required("Vui lòng nhập tên phân khu"),
  projectId: Yup.string().optional().required("Vui lòng chọn dự án"),
});

const schemaType2 = Yup.object().shape({
  code: Yup.string().required("Vui lòng nhập mã loại hình dự án"),
  name: Yup.string().required("Vui lòng nhập tên loại hình dự án"),
});

const schemaType3 = Yup.object().shape({
  code: Yup.string().required("Vui lòng nhập mã loại hình căn hộ"),
  name: Yup.string().required("Vui lòng nhập tên loại hình căn hộ"),
});

const schemaType4 = Yup.object().shape({
  name: Yup.string().required("Vui lòng nhập tên phân loại"),
  number: Yup.number()
    .nullable()
    .transform((value, originalValue) => {
      return originalValue.trim() === "" ? null : Number(originalValue);
    })
    .required("Vui lòng nhập số công"),
});

const schemaType5 = Yup.object().shape({
  name: Yup.string().required("Vui lòng nhập tên loại bảo lãnh"),
  code: Yup.string().required("Vui lòng nhập mã loại bảo lãnh"),
});

const FormCustom = ({
  isOpen,
  onClose,
  type,
  defaultValues,
  onSubmit,
  isEdit,
  textAdd,
  textButtonAdd,
  textTitleEdit,
  dataSelect,
}) => {
  const [focusStates, setFocusStates] = useState({});
  const [isCodeEdited, setIsCodeEdited] = useState(false);

  if (Array.isArray(dataSelect)) {
    dataSelect = dataSelect.filter((item) => item?.value !== defaultValues?.id);
  }
  
  let schema = null;
  if (type === 0) {
    schema = schemaType1;
  } else if (type === 1) {
    schema = schemaType2;
  } else if (type === 2) {
    schema = schemaType3;
  } else if (type === 3) {
    schema = schemaType4;
  } else {
    schema = schemaType5;
  }

  const {
    reset,
    register,
    handleSubmit,
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValues || {},
  });

  useEffect(() => {
    if (isOpen) {
      if (isEdit) {
        reset(defaultValues);
      } else {
        reset(
          type === 0
            ? {
                code: "",
                name: "",
                projectId: "",
              }
            : {
                code: "",
                name: "",
              }
        );
      }
    }
  }, [isOpen, isEdit, defaultValues, reset]);

  const submitHandler = (data) => {
    onSubmit(data);
  };

  const handleFocus = (field) => {
    setFocusStates((prev) => ({ ...prev, [field]: true }));
    if (type === 0 || type === 2) {
      if (field === "code") {
        setIsCodeEdited(true);
      } else if (field === "name") {
        setIsCodeEdited(false);
      }
    }
  };

  useEffect(() => {
    if (type === 0 || type === 2) {
      const name = watch("name");
      const nameWithoutAccents = removeAccents(name || "");
      const generatedCode = generateCodeFromName(nameWithoutAccents);

      if (!isCodeEdited && name) {
        setValue("code", generatedCode, {
          shouldValidate: false,
          shouldDirty: false,
        });
        clearErrors("code");
      }
      if (name === "") {
        setValue("code", "", { shouldValidate: false, shouldDirty: false });
      }
    }
  }, [watch("name"), isCodeEdited, setValue]);

  const handleCodeChange = (e) => {
    setValue("code", e.target.value, {
      shouldValidate: false,
      shouldDirty: true,
    });
  };

  const handleBlur = (field) => {
    setFocusStates((prev) => ({ ...prev, [field]: false }));
  };

  const onchangeProject = (option) => {
    setValue("projectId", option);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        backgroundColor="var(--color-main)"
        borderRadius={"16px"}
        h={"max-content"}
        w={"100%"}
        maxW={"625px"}
        overflowY={"auto"}
        sx={{
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-thumb": {
            width: "4px",
            borderRadius: "50px",
            background: "var(--color-background)",
          },
          "&::-moz-scrollbar": {
            width: "4px",
          },
          "&::-moz-scrollbar-thumb": {
            width: "4px",
            borderRadius: "50px",
            background: "var(--color-background)",
          },
        }}
      >
        <form onSubmit={handleSubmit(submitHandler)}>
          <Flex flexDirection={"column"}>
            <Flex
              justifyContent={"space-between"}
              alignItems={"center"}
              gap={"10px"}
              padding={"25px 24px"}
              borderBottom={"1px solid var(--bg-line-employee)"}
            >
              <Text
                color="var(--color-header-employee)"
                fontSize={"18px"}
                fontWeight={"600"}
              >
                {isEdit ? textTitleEdit : textAdd}
              </Text>
              <Box cursor={"pointer"} onClick={onClose}>
                <Image src={CloseIconBlack} />
              </Box>
            </Flex>
            <Box flex={1}>
              <Box
                m={"24px auto 0"}
                w={"545px"}
                borderRadius={"8px"}
                overflow={"hidden"}
              >
                {(() => {
                  switch (type) {
                    case 0:
                      return (
                        <>
                          <Flex
                            justifyContent={"space-between"}
                            alignItems={"flex-start"}
                            gap={"27px"}
                            mt={"16px"}
                          >
                            <InputControl
                              label="Mã phân khu"
                              placeholder="Nhập mã phân khu"
                              errors={errors.code}
                              register={register("code")}
                              onFocus={() => handleFocus("code")}
                              onBlur={() => handleBlur("code")}
                              state={focusStates["code"]}
                              onChange={handleCodeChange}
                              mwidth="100%"
                            />
                            <InputControl
                              label="Tên phân khu"
                              placeholder="Nhập tên phân khu"
                              errors={errors.name}
                              register={register("name")}
                              onFocus={() => handleFocus("name")}
                              onBlur={() => handleBlur("name")}
                              state={focusStates["name"]}
                              // onChange = {handleGeneCode}
                              mwidth="100%"
                            />
                          </Flex>

                          <Flex
                            justifyContent={"space-between"}
                            alignItems={"flex-start"}
                            gap={"27px"}
                            mt={"16px"}
                          >
                            {dataSelect && (
                              <SelectControl
                                label="Vùng"
                                dataOption={dataSelect}
                                placeholder="Chọn vùng"
                                selectedMain={watch("projectId")}
                                onChange={onchangeProject}
                                onFocus={() => handleFocus("projectId")}
                                onBlur={() => handleBlur("projectId")}
                                state={focusStates["projectId"]}
                                registerProps={register("projectId")}
                                errorMessage={errors.projectId}
                                mwidth="100%"
                              />
                            )}
                          </Flex>
                        </>
                      );
                    case 1:
                      return (
                        <Flex
                          justifyContent={"space-between"}
                          alignItems={"flex-start"}
                          gap={"27px"}
                          mt={"16px"}
                        >
                          <InputControl
                            label="Mã loại hình dự án"
                            placeholder="Nhập mã loại hình dự án"
                            errors={errors.code}
                            register={register("code")}
                            onFocus={() => handleFocus("code")}
                            onBlur={() => handleBlur("code")}
                            state={focusStates["code"]}
                            mwidth="100%"
                          />
                          <InputControl
                            label="Tên loại hình dự án"
                            placeholder="Nhập tên loại hình dự án"
                            errors={errors.name}
                            register={register("name")}
                            onFocus={() => handleFocus("name")}
                            onBlur={() => handleBlur("name")}
                            state={focusStates["name"]}
                            mwidth="100%"
                          />
                        </Flex>
                      );
                    case 2:
                      return (
                        <Flex
                          justifyContent={"space-between"}
                          alignItems={"flex-start"}
                          gap={"27px"}
                          mt={"16px"}
                        >
                          <InputControl
                            label="Mã loại hình căn hộ dự án"
                            placeholder="Nhập mã loại hình căn hộ dự án"
                            errors={errors.code}
                            register={register("code")}
                            onFocus={() => handleFocus("code")}
                            onBlur={() => handleBlur("code")}
                            state={focusStates["code"]}
                            mwidth="100%"
                          />
                          <InputControl
                            label="Tên loại hình căn hộ dự án"
                            placeholder="Nhập tên loại hình căn hộ dự án"
                            errors={errors.name}
                            register={register("name")}
                            onFocus={() => handleFocus("name")}
                            onBlur={() => handleBlur("name")}
                            state={focusStates["name"]}
                            mwidth="100%"
                          />
                        </Flex>
                      );
                    default:
                      return (
                        <InputControl
                          label="Phân loại"
                          placeholder="Nhập tên phân loại"
                          errors={errors.name}
                          register={register("name")}
                          onFocus={() => handleFocus("name")}
                          onBlur={() => handleBlur("name")}
                          state={focusStates["name"]}
                          mwidth="100%"
                        />
                      );
                  }
                })()}
              </Box>
            </Box>
            <Flex p={"24px"} gap={"16px"} justifyContent={"flex-end"}>
              {isEdit ? (
                <>
                  <Button
                    w={"112px"}
                    h={"44px"}
                    lineHeight={"18px"}
                    fontSize={"15px"}
                    fontWeight={600}
                    color={"var(--color-option-employee-hover)"}
                    border={"1px solid var(--color-option-employee-hover)"}
                    bg={"var(--color-main)"}
                    onClick={onClose}
                    _hover={{
                      filter: "brightness(90%)",
                    }}
                  >
                    Hủy
                  </Button>
                  <Button
                    type="submit"
                    w={"112px"}
                    h={"44px"}
                    lineHeight={"18px"}
                    fontSize={"15px"}
                    fontWeight={600}
                    color={"var(--color-main)"}
                    bg={"var(--linear-gradient-employee)"}
                    _hover={{
                      filter: "brightness(90%)",
                    }}
                  >
                    Lưu
                  </Button>
                </>
              ) : (
                <Button
                  type="submit"
                  padding={"13px 20px"}
                  lineHeight={"18px"}
                  fontSize={"15px"}
                  fontWeight={600}
                  h={"44px"}
                  color={"var(--color-main)"}
                  bg={"var(--linear-gradient-employee)"}
                  _hover={{
                    filter: "brightness(90%)",
                  }}
                >
                  {textButtonAdd}
                </Button>
              )}
            </Flex>
          </Flex>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default FormCustom;
