import React, { useState } from "react";
import { Box, Button, Flex, Image, Text } from "@chakra-ui/react";
import iconExcel from "../../assets/images/employee/iconExcel.svg";
import TableJobTitle from "./tableJobTitle";
import { useAttendance } from "../../hooks/useAttendance";
import { exportFile } from "../../utils/tools";
import toast from "react-hot-toast";
import { attendanceCall } from "../../api/attendance";

export default function JobTitle() {
  const { entries, attendanceData } = useAttendance();
  const [paramExport, setParamExport] = useState(null);

  const handleExport = async () => {
    const checkInColumnMapping = {
      date: "Thời gian",
      codeEmployee: "Mã nhân viên",
      fullName: "Tên nhân viên",
      saleBlock: "Khối",
      saleArea: "Vùng",
      jobPosition: "Chức vụ",
      commissionRate: "Tỉ lệ hoa hồng",
      workingDays: "Ngày công",
      businessCategory: "Phân loại",
    };
    if (attendanceData?.length) {
      const data = await attendanceCall.export(entries,paramExport);
      if (data) {
        exportFile(
          data,
          checkInColumnMapping,
          "danh_sach_xac_nhan_cong_va_chuc_danh.xlsx"
        );
        toast.success("Export thành công");
      } else {
        toast.error("Không có dữ liệu");
      }
    } else toast.error("Không có dữ liệu");
  };

  return (
    <Box
      w={{ base: "100%" }}
      backgroundColor="var(--color-backgroundmain)"
      p={{ base: "24px 16px 16px" }}
    >
      <Flex alignItems={"center"} justifyContent={"space-between"}>
        <Flex alignItems={"center"} gap={{ base: "74px" }}>
          <Text fontSize={{ base: "20px" }} fontWeight={600}>
            Xác nhận công và chức danh
          </Text>
        </Flex>
        <Button
          display={"flex"}
          alignItems={"center"}
          p={"10px 16px 10px 12px"}
          gap={"8px"}
          backgroundImage="var(--linear-gradient-excel)"
          _hover={{
            filter: "brightness(90%)",
          }}
          onClick={handleExport}
        >
          <Image src={iconExcel} />
          <Text
            fontSize={{ base: "15px" }}
            fontWeight={600}
            color="var(--color-main)"
          >
            Xuất excel
          </Text>
        </Button>
      </Flex>
      <Box mt={{ base: "23px" }}>
        <TableJobTitle paramData={setParamExport} />
      </Box>
    </Box>
  );
}
