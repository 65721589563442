import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { clientAPI } from "../api/client";
import { NumberOfPerPage } from "../utils/globalVariable";
import { formatDateToLocal, customDateFormat, formatTime } from "../utils/utils";

const NUMBER_NFT_PER_PAGE = NumberOfPerPage.attendance;
// const queryKey = "attendance";

async function fetchAttendance(
  currentPage, 
  saleBlock, 
  saleArea,
  department,
  supportDepartmentId,
  branchId, 
  search, 
  date
) {
  try {
    const options = {
      limit: NUMBER_NFT_PER_PAGE,
      page: currentPage,
    };

    if (search !== undefined) options.search = search;
    if (saleBlock) options.saleBlockId = saleBlock;
    if (saleArea) options.saleAreaId = saleArea;
    if (department) options.department = department;
    if (supportDepartmentId) options.supportDepartmentId = supportDepartmentId;
    if (branchId) options.branchId = branchId;

    if (date) {
      const dateNumber = new Date(date);
      const month = dateNumber.getMonth() + 1;
      const year = dateNumber.getFullYear();
      options.date = year + "-" + month;
    }

    const queryParams = new URLSearchParams(options).toString();
    let data = await clientAPI("get", `/attendance/?${queryParams}`);

    const totalPages = Math.ceil(data?.pagination?.total / NUMBER_NFT_PER_PAGE);
    const entries = data?.pagination?.total;
    const startEntry =
      (data?.pagination?.page - 1) * data?.pagination?.limit + 1;
    const endEntry = Math.min(
      data?.pagination?.page * data?.pagination?.limit,
      entries
    );

    const newData = data?.items.map((item, index) => {
      return {
        date: (item?.month || "-") + "/" + (item?.year || "-"),
        year: item?.year || "",
        month: item?.month || "",
        codeEmployee: item?.employeeObject?.code || "-",
        idEmployee: item?.employeeObject?._id || "-",
        fullName: item?.employeeObject?.fullName || "-",
        saleBlock: item?.saleBlock?.name || "-",
        saleArea: item?.saleArea?.name || "-",
        supportDepartment: item?.supportDepartment?.name || "-",
        jobPosition: item?.jobPosition?.name || "-",
        commissionRate: item?.commissionRate || "-",
        workingDays: item?.workingDays || "-",
        businessCategory: item?.businessCategory?.name || "-",
        department: item?.employeeObject?.department || 0,
        dataEdit: {
          id: item?._id || "",
          businessCategoryId: item?.businessCategory?._id || "",
          jobPositionId: item?.jobPosition?._id || "",
          saleAreaId: item?.saleArea?._id || "",
          commissionRate: item?.commissionRate || "",
          saleBlockId: item?.saleBlock?._id || "",
          workingDays: item?.workingDays || "",
          department: item?.employeeObject?.department || 0,
          supportDepartmentId: item?.supportDepartment?._id || "",
        },
        export: {
          date: (item?.month && item?.year ) ? (item?.month  + "/" + item?.year) : '' ,
          codeEmployee: item?.employeeObject?.code || "",
          fullName: item?.employeeObject?.fullName || "",
          saleBlock: item?.saleBlock?.name || "",
          saleArea: item?.saleArea?.name || "",
          jobPosition: item?.jobPosition?.name || "",
          commissionRate: item?.commissionRate || "",
          workingDays: item?.workingDays || "",
          businessCategory: item?.businessCategory?.name || "",
        }
      };
    });

    return { data: newData, totalPages, entries, startEntry, endEntry };
  } catch (error) {
    console.error("Error fetching attendance data:", error);
    return { data: [], totalPages: 0 };
  }
}

async function fetchListAttendanceByEmployeeAndMonth(
  currentPage,
  employeeId,
  month,
  year,
  page,
  limit,
  sort
) {
  try {
    const options = {
      limit: NUMBER_NFT_PER_PAGE,
      page: currentPage,
    };

    if (employeeId !== undefined) options.employeeId = employeeId;
    if (month) options.month = month;
    if (year) options.year = year;
    if (page) options.page = page;
    if (limit) options.limit = limit;
    if (sort) options.sort = sort;

    const queryParams = new URLSearchParams(options).toString();
    let data = await clientAPI(
      "get",
      `/attendance/listAttendanceByEmployeeAndMonth?${queryParams}`
    );

    if (employeeId) {
      data = data?.items[0];
    }

    return { data };
  } catch (error) {
    console.error("Error fetching attendance data:", error);
    return { data: [], totalPages: 0 };
  }
}

async function fetchGetCheckinEmployeeByMonth(employeeId, date) {
  try {
    const options = {};

    if (employeeId) options.employeeId = employeeId;
    if (date) options.date = date;

    const queryParams = new URLSearchParams(options).toString();
    let data = [];

    if (queryParams) {
      data = await clientAPI(
        "get",
        `/employee/app/getCheckinEmployeeByMonth?${queryParams}`
      );
    }

    const newData = Array.isArray(data)
      ? data.map((item, index) => {
          return {
            time: item?.time ? customDateFormat(item?.time) : "-",
            checkinTime: item?.checkinTime ? formatTime(item?.checkinTime) :  "-",
            type: item?.type || "-",
            workingDayRate: item?.workingDayRate || "-",
          };
        })
      : [];

    return { data: newData };
  } catch (error) {
    console.error("Error fetching attendance data:", error);
    return { data: [], totalPages: 0 };
  }
}

export function useAttendance(
  saleBlock, 
  saleArea, 
  department,
  supportDepartmentId,
  branchId,
  search, 
  date
) {
  const [currentPage, setCurrentPage] = useState(1);
  const executionDate = date ? formatDateToLocal(date) : "";
  const { data, refetch, isLoading, isRefetching } = useQuery({
    queryKey: [
      currentPage, 
      saleBlock, 
      saleArea, 
      department,
      supportDepartmentId,
      branchId,
      search, 
      date
    ],
    queryFn: () =>
      fetchAttendance(
        currentPage, 
        saleBlock, 
        saleArea, 
        department,
        supportDepartmentId,
        branchId,
        search, 
        executionDate
      ),
    refetchOnWindowFocus: false,
    staleTime: 30000,
    keepPreviousData: true,
    onError: (error) => {
      console.error("Error fetching attendance data:", error);
    },
  });

  const nextPage = () => {
    if (currentPage < data?.totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return {
    attendanceData: data?.data,
    totalPages: data?.totalPages,
    refetch,
    isLoading,
    isRefetching,
    prevPage,
    nextPage,
    setCurrentPage,
    currentPage,
    entries: data?.entries,
    startEntry: data?.startEntry,
    endEntry: data?.endEntry,
  };
}

export function useListAttendanceByEmployeeAndMonth(
  employeeId,
  month,
  year,
  page,
  limit,
  sort
) {
  const [currentPage, setCurrentPage] = useState(1);
  const { data, refetch, isLoading, isRefetching } = useQuery({
    queryKey: [employeeId, month, year, page, limit, sort],
    queryFn: () =>
      fetchListAttendanceByEmployeeAndMonth(
        currentPage,
        employeeId,
        month,
        year,
        page,
        limit,
        sort
      ),
    refetchOnWindowFocus: false,
    staleTime: 30000,
    keepPreviousData: true,
    onError: (error) => {
      console.error(
        "Error fetching attendance by employee and month data:",
        error
      );
    },
  });

  const nextPage = () => {
    if (currentPage < data?.totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return {
    getByEmployeeAndMonthData: data?.data,
    totalPages: data?.totalPages,
    refetch,
    isLoading,
    isRefetching,
    prevPage,
    nextPage,
    setCurrentPage,
    currentPage,
    entries: data?.entries,
    startEntry: data?.startEntry,
    endEntry: data?.endEntry,
  };
}

export function useGetCheckinEmployeeByMonth(employeeId, date) {
  const { data, refetch, isLoading, isRefetching } = useQuery({
    queryKey: [employeeId, date],
    queryFn: () => fetchGetCheckinEmployeeByMonth(employeeId, date),
    refetchOnWindowFocus: false,
    staleTime: 30000,
    keepPreviousData: true,
    onError: (error) => {
      console.error(
        "Error fetching attendance by employee and month data:",
        error
      );
    },
  });
  return {
    getCheckinEmployeeByMonth: data?.data,
    totalPages: data?.totalPages,
    refetch,
    isLoading,
    isRefetching,
    entries: data?.entries,
    startEntry: data?.startEntry,
    endEntry: data?.endEntry,
  };
}
