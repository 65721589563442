import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Image,
  Input,
  InputGroup,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import rateIcon from "../../assets/images/projectManagement/rate.svg";
import trashIcon from "../../assets/images/projectManagement/trash-2.svg";
// import { ChevronDownIcon } from "@chakra-ui/icons";

export const FormBonus = ({
  filteredOptions,
  selectedMain,
  nameMain,
  deleteData,
  onChangeName,
  onChangeBonus,
  isFocused,
  onFocus,
  onBlur,
}) => {
  const [dataSelect, setDataSelect] = useState();
  const [dataBonus, setDataBonus] = useState("");

  const onChangeActionName = (value) => { 
    onChangeName(value);
  };

  const onChangeActionBonus = (value) => { 
    onChangeBonus(value.target.value);
  };

  useEffect(() => {
    if (
      selectedMain !== undefined &&
      selectedMain !== null &&
      selectedMain !== ""
    ) {
      setDataSelect(selectedMain);
    }
  }, [selectedMain]);

  useEffect(() => {
    if (nameMain) {
      setDataBonus(nameMain);
    }
  }, [nameMain]);

  const check = filteredOptions.find((option) => option.value === dataSelect);

  return (
    <Flex
      justifyContent={"space-between"}
      alignItems={"flex-start"}
      gap={"27px"}
      w={"100%"}
    >
      <Box
        w={"100%"}
        borderRadius={"12px"}
        maxW={"100%"}
        display={"flex"}
        flexDirection={"column"}
        gap={"8px"}
        h={"auto"}
        zIndex={isFocused ? 3 : 1}
      >
        <Text
          fontSize={{ base: "12px" }}
          fontWeight={400}
          lineHeight={"15px"}
          color={"var(--color-label-employee)"}
        >
          Tên người quản lý
        </Text>

        <Menu matchWidth>
          <MenuButton
            _active={{
              border: "1px solid var(--color-option-employee-hover)",
              borderBottom: "1px solid var(--color-option-employee-hover)",
            }}
            _hover={{
              boxShadow: "none",
            }}
            type={"button"}
            // rightIcon={<ChevronDownIcon color="#000" />}
            w="100%"
            backgroundColor="#FFF"
            border={"1px solid var(--bg-line-employee)"}
            fontSize="14px"
            fontWeight={500}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            textAlign={"left"}
            p={"0"}
            h={"auto"}
            maxH={"44px"}
            padding={"13px 12px 14px 12px"}
            color={
              dataSelect !== undefined &&
              dataSelect !== null &&
              dataSelect !== ""
                ? "var(--color-info-employee)"
                : "var(--color-placeHolder-employee)"
            }
            borderRadius={"8px"}
            borderBottom={"1px solid var(--bg-line-employee)"}
            onFocus={onFocus}
            onBlur={onBlur}
          >
            {dataSelect !== undefined &&
            dataSelect !== null &&
            dataSelect !== "" &&
            check
              ? check.label
              : "Tên nhân viên dự án"}
          </MenuButton>

          <MenuList borderRadius="12px" minW="275px" overflow={"scroll"} maxH={"250px"}>
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option) => (
                <MenuItem
                  key={option.value}
                  onClick={() => onChangeActionName(option.value)}
                  backgroundColor={"#FFF"}
                  color={"#000"}
                >
                  {option.label}
                </MenuItem>
              ))
            ) : (
              <Box fontSize="14px" p="12px" textAlign="center">
                Không tìm thấy kết quả
              </Box>
            )}
          </MenuList>
        </Menu>
      </Box>
      <FormControl
        display={"flex"}
        flexDirection={"column"}
        gap={{ base: "6px" }}
        maxW={"100%"}
      >
        <FormLabel
          fontSize={"12px"}
          fontWeight={400}
          color="var(--text-color-Subtittle)"
          lineHeight={"15px"}
          m={{ base: "0" }}
        >
          Hệ số thưởng
        </FormLabel>
        <Flex
          justifyContent={"space-between"}
          gap={"32px"}
          alignItems={"center"}
        >
          <InputGroup
            mt={"0"}
            p={{ base: "10px 14px 10px 12px" }}
            alignItems={"center"}
            h={"44px"}
            border={"0.5px solid var(--Line)"}
            borderRadius={"8px"}
            _focus={{
              boxShadow: "none",
            }}
          >
            <Input
              type="number"
              color="var(--text-color-Subtittle)"
              fontWeight={500}
              fontSize={"14px"}
              border={"none"}
              p={"0px"}
              placeholder={"Nhập hệ số thưởng"}
              // type={"text"}
              _focus={{
                boxShadow: "none",
              }}
              borderRight={"1px solid #DAE0EB"}
              borderRadius={"0"}
              h={"24px"}
              value={dataBonus || ""}
              onChange={(e) => onChangeActionBonus(e)}
              step="any"
            />
            <Image src={rateIcon} ml={"16px"} />
          </InputGroup>
          <Image
            cursor={"pointer"}
            src={trashIcon}
            mr={"12px"}
            onClick={deleteData}
            _hover={{
              filter: "var(--filter-red)",
            }}
          />
        </Flex>
      </FormControl>
    </Flex>
  );
};
