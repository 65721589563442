import {
  Box,
  Flex,
  Text,
  Modal,
  ModalContent,
  ModalOverlay,
  Image,
  Button,
} from "@chakra-ui/react";
import React, { useState, memo, useEffect } from "react";
import IconX from "../../../assets/images/booking/x-circle.svg";
import IconCheck from "../../../assets/images/booking/check-circle.svg";
import IconRed from "../../../assets/images/booking/x-red.svg";
import { bookingImageCall } from "../../../api/bookingImage";
import { bookingCall } from "../../../api/booking";
import toast from "react-hot-toast";
import { bookingHistoryCall } from "../../../api/bookingHistory";
import { formatDateDb } from "../../../utils/tools";
const baseURL = process.env.REACT_APP_BACKEND_API || "http://localhost:3001";

const FormInfo = memo(({ isOpen, onClose, defaultValues, refetch }) => {
  const [infident1, setInfident1] = useState(null);
  const [infident2, setInfident2] = useState(null);
  const [imagePayment, setImagePayment] = useState(null);
  const [historyBooking, setHistoryBooking] = useState(null);

  useEffect(() => {
    if (defaultValues?.id) {
      if (
        defaultValues?.status === "APPROVED" ||
        defaultValues?.status === "REJECTED"
      ) {
        const fetchDataBookingHistory = async () => {
          try {
            const data = await bookingHistoryCall.getHistoryBooking(
              defaultValues?.id
            );

            setHistoryBooking(null);
            if (data?.items) {
              setHistoryBooking(data?.items[0]);
            }
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };
        fetchDataBookingHistory();
      }
      const fetchData = async () => {
        try {
          const dataFileEmployee = await bookingImageCall.getByBookingId(
            defaultValues?.id
          );
          
          setInfident1(null);
          setInfident2(null);
          setImagePayment(null);
          if (Array.isArray(dataFileEmployee?.items)) {
            for (const item of dataFileEmployee?.items) {
              if (item?.type === "IDENTIFICATION_BEFOR") {
                setInfident1(item?.path);
              }
              if (item?.type === "IDENTIFICATION_AFTER") {
                setInfident2(item?.path);
              }
              if (item?.type === "TRANCTION") {
                setImagePayment(item?.path);
              }
            }
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
    }
  }, [isOpen]);

  const submitStatus = async (status) => {
    const toastEdit = toast.loading("Đang cập nhập trạng thái ...");
    const dataFormat = {
      status: status,
    };

    try {
      await bookingCall.approve(defaultValues?.id, dataFormat);
      refetch();
      onClose();
      toast.success("Cập nhập trạng thái thành công");
    } catch (error) {
      console.error("Error:", error);
      toast.error("Có lỗi xảy ra khi cập nhập trạng thái");
    }
    toast.dismiss(toastEdit);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        backgroundColor="var(--color-main)"
        borderRadius={"16px"}
        position={"relative"}
        w={"100%"}
        maxW={"625px"}
      >
        <Box
          paddingBottom={"80px"}
          h={"86.5vh"}
          w={"100%"}
          overflowY={"auto"}
          sx={{
            "&::-webkit-scrollbar": {
              width: "4px",
            },
            "&::-webkit-scrollbar-thumb": {
              width: "4px",
              borderRadius: "50px",
              background: "var(--color-background)",
            },
            "&::-moz-scrollbar": {
              width: "4px",
            },
            "&::-moz-scrollbar-thumb": {
              width: "4px",
              borderRadius: "50px",
              background: "var(--color-background)",
            },
          }}
        >
          <Flex
            alignItems={"center"}
            p={"25px 24px 24px 24px"}
            position={"relative"}
            borderBottom={"1px solid var(--border-input-employee)"}
          >
            <Text
              color={"var(--color-header-employee)"}
              fontSize={"16px"}
              fontWeight={"600"}
            >
              Chi tiết đợt Booking
            </Text>
            <Box
              position={"absolute"}
              top={"24px"}
              right={"24px"}
              cursor={"pointer"}
              onClick={onClose}
            >
              <Image src={IconX} />
            </Box>
          </Flex>
          <Box
            paddingTop={"24px"}
            paddingBottom={"41px"}
            maxW={"545px"}
            margin={"0 auto"}
          >
            <Box>
              <Text
                color={"var(--color-header-employee)"}
                fontSize={"16px"}
                fontWeight={"600"}
              >
                Đây là tiêu đề tên đợt booking
              </Text>
              <Box
                background={"var(--fill-avatar)"}
                p={"16px 24px 16px 16px"}
                mt={"13px"}
                borderRadius={"8px"}
              >
                <Flex gap={"27px"}>
                  <Box flex={"1"}>
                    <Text
                      color={"var(--color-label-employee)"}
                      fontSize={"12px"}
                    >
                      Người tạo booking
                    </Text>
                    <Text
                      color={"var(--color-option-employee)"}
                      fontSize={"14px"}
                      fontWeight={"500"}
                      mt={"8px"}
                    >
                      {defaultValues?.nameBooker || ""}
                    </Text>
                  </Box>
                  <Box flex={"1"}>
                    <Text
                      color={"var(--color-label-employee)"}
                      fontSize={"12px"}
                    >
                      Ngày tạo booking
                    </Text>
                    <Text
                      color={"var(--color-option-employee)"}
                      fontSize={"14px"}
                      fontWeight={"500"}
                      mt={"8px"}
                    >
                      {defaultValues?.createdAt || ""}
                    </Text>
                  </Box>
                </Flex>
                <Flex mt={"16px"} gap={"27px"}>
                  <Box flex={"1"}>
                    <Text
                      color={"var(--color-label-employee)"}
                      fontSize={"12px"}
                    >
                      Dự án
                    </Text>
                    <Text
                      color={"var(--color-option-employee)"}
                      fontSize={"14px"}
                      fontWeight={"500"}
                      mt={"8px"}
                    >
                      {defaultValues?.projectId || ""}
                    </Text>
                  </Box>
                  <Box flex={"1"}>
                    <Text
                      color={"var(--color-label-employee)"}
                      fontSize={"12px"}
                    >
                      Sản phẩm
                    </Text>
                    <Text
                      color={"var(--color-option-employee)"}
                      fontSize={"14px"}
                      fontWeight={"500"}
                      mt={"8px"}
                    >
                      {defaultValues?.customerId || ""}
                    </Text>
                  </Box>
                </Flex>
                <Flex mt={"16px"} gap={"27px"}>
                  <Box flex={"1"}>
                    <Text
                      color={"var(--color-label-employee)"}
                      fontSize={"12px"}
                    >
                      Số tiền đặt cọc
                    </Text>
                    <Text
                      color={"var(--color-option-employee)"}
                      fontSize={"14px"}
                      fontWeight={"500"}
                      mt={"8px"}
                    >
                      {`${defaultValues?.price} đ` || ""}
                    </Text>
                  </Box>
                  <Box flex={"1"}>
                    <Text
                      color={"var(--color-label-employee)"}
                      fontSize={"12px"}
                    >
                      TG đặt cọc
                    </Text>
                    <Text
                      color={"var(--color-option-employee)"}
                      fontSize={"14px"}
                      fontWeight={"500"}
                      mt={"8px"}
                    >
                      {defaultValues?.bookingTime || ""}
                    </Text>
                  </Box>
                </Flex>
              </Box>
            </Box>
            <Box mt={"20px"}>
              <Text
                color={"var(--color-header-employee)"}
                fontSize={"16px"}
                fontWeight={"600"}
              >
                Thông tin khách hàng
              </Text>
              <Box
                background={"var(--fill-avatar)"}
                p={"16px 24px 16px 16px"}
                mt={"16px"}
                borderRadius={"8px"}
              >
                <Flex gap={"27px"}>
                  <Box flex={"1"}>
                    <Text
                      color={"var(--color-label-employee)"}
                      fontSize={"12px"}
                    >
                      Tên khách hàng
                    </Text>
                    <Text
                      color={"var(--color-option-employee)"}
                      fontSize={"14px"}
                      fontWeight={"500"}
                      mt={"8px"}
                    >
                      {defaultValues?.customer || ""}
                    </Text>
                  </Box>
                </Flex>
              </Box>
            </Box>
            <Box mt={"16px"}>
              <Flex gap={"27px"}>
                <Box flex={"1"}>
                  <Text color={"var(--color-label-employee)"} fontSize={"12px"}>
                    Ảnh CCCD mặt trước
                  </Text>
                  <Box
                    mt={"8px"}
                    border={"0.5px solid var(--bg-line-employee)"}
                    w={"259px"}
                    height={"146px"}
                    borderRadius={"8px"}
                  >
                    {infident1 && (
                      <Image
                        borderRadius={"11px"}
                        w={"100%"}
                        h={"100%"}
                        objectFit={"cover"}
                        src={`${baseURL}/${infident1}`}
                      />
                    )}
                  </Box>
                </Box>
                <Box flex={"1"}>
                  <Text color={"var(--color-label-employee)"} fontSize={"12px"}>
                    Ảnh CCCD mặt trước
                  </Text>
                  <Box
                    mt={"8px"}
                    border={"0.5px solid var(--bg-line-employee)"}
                    w={"259px"}
                    height={"146px"}
                    borderRadius={"8px"}
                  >
                    {infident2 && (
                      <Image
                        borderRadius={"11px"}
                        w={"100%"}
                        h={"100%"}
                        objectFit={"cover"}
                        src={`${baseURL}/${infident2}`}
                      />
                    )}
                  </Box>
                </Box>
              </Flex>
            </Box>
            <Box mt={"16px"}>
              <Flex>
                <Box flex={"1"}>
                  <Text color={"var(--color-label-employee)"} fontSize={"12px"}>
                    Ảnh ủy nhiệm chi
                  </Text>
                  <Flex
                    mt={"8px"}
                    border={"0.5px solid var(--bg-line-employee)"}
                    borderRadius={"8px"}
                    justifyContent={"center"}
                  >
                    <Box borderRadius={"11px"} w={"202px"} h={"300px"}>
                      {imagePayment && (
                        <Image
                          borderRadius={"11px"}
                          w={"100%"}
                          h={"100%"}
                          objectFit={"cover"}
                          src={`${baseURL}/${imagePayment}`}
                        />
                      )}
                    </Box>
                  </Flex>
                </Box>
              </Flex>
            </Box>
          </Box>
          <Box
            position={"absolute"}
            w={"100%"}
            bottom={"0"}
            bg={"var(--color-main)"}
            borderTop={"0.5px solid var(--bg-line-employee)"}
            borderRadius={"0px 0px 16px 16px"}
          >
            {defaultValues?.status === "NEW" && (
              <Flex p={"24px"} gap={"16px"} justifyContent={"flex-end"}>
                <Button
                  p={"13px 20px"}
                  fontSize={"15px"}
                  fontWeight={600}
                  color={"var(--color-option-employee-hover)"}
                  borderRadius={"8px"}
                  border={"1px solid var(--color-option-employee-hover)"}
                  bg={"var(--color-main)"}
                  onClick={()=>submitStatus('REJECTED')}
                  _hover={{
                    filter: "brightness(90%)",
                  }}
                >
                  Không duyệt
                </Button>
                <Button
                  w={"133px"}
                  p={"13px 20px"}
                  fontSize={"15px"}
                  fontWeight={600}
                  color={"var(--color-main)"}
                  borderRadius={"8px"}
                  bg={"var(--color-option-employee-hover)"}
                  onClick={()=>submitStatus('APPROVED')}
                  _hover={{
                    filter: "brightness(90%)",
                  }}
                >
                  Duyệt
                </Button>
              </Flex>
            )}

            {(defaultValues?.status === "APPROVED" ||
              defaultValues?.status === "REJECTED") && (
              <Flex p={"16px 24px"} justifyContent={"space-between"}>
                {defaultValues?.status === "APPROVED" && (
                  <Flex
                    p={"12px"}
                    justifyContent={"space-between"}
                    gap={"8px"}
                    bg={"var(--bg-status-success)"}
                    borderRadius={"8px"}
                    w={"max-content"}
                    minW={"151px"}
                    h={"max-content"}
                  >
                    <Image
                      src={IconCheck}
                      w={"20px"}
                      h={"20px"}
                      objectFit={"cover"}
                    />
                    <Text
                      fontSize={"14px"}
                      lineHeight={"20px"}
                      fontWeight={500}
                      color={"var(--color-status-employ)"}
                      display={"block"}
                    >
                      Đã được duyệt
                    </Text>
                  </Flex>
                )}
                {defaultValues?.status === "REJECTED" && (
                  <Flex
                    p={"12px"}
                    justifyContent={"space-between"}
                    gap={"8px"}
                    bg={"var(--bg-status-error)"}
                    borderRadius={"8px"}
                    w={"max-content"}
                    minW={"176px"}
                    h={"max-content"}
                  >
                    <Image
                      src={IconRed}
                      w={"20px"}
                      h={"20px"}
                      objectFit={"cover"}
                    />
                    <Text
                      fontSize={"14px"}
                      lineHeight={"20px"}
                      fontWeight={500}
                      color={"var(--text-red-employee)"}
                      display={"block"}
                    >
                      Không được duyệt
                    </Text>
                  </Flex>
                )}

                <Flex gap={"40px"}>
                  <Box textAlign={"end"}>
                    <Text
                      color={"var(--color-label-employee)"}
                      fontSize={"12px"}
                    >
                      Người phê duyệt
                    </Text>
                    <Text
                      color={"var(--color-option-employee)"}
                      fontSize={"14px"}
                      fontWeight={"500"}
                      mt={"8px"}
                    >
                      {historyBooking?.approver?.fullName}
                    </Text>
                  </Box>
                  <Box textAlign={"end"}>
                    <Text
                      color={"var(--color-label-employee)"}
                      fontSize={"12px"}
                    >
                      Thời gian duyệt
                    </Text>
                    <Text
                      color={"var(--color-option-employee)"}
                      fontSize={"14px"}
                      fontWeight={"500"}
                      mt={"8px"}
                    >
                      {historyBooking?.createdAt
                        ? formatDateDb(historyBooking?.createdAt)
                        : ""}
                    </Text>
                  </Box>
                </Flex>
              </Flex>
            )}
          </Box>
        </Box>
      </ModalContent>
    </Modal>
  );
});

export default FormInfo;
