import { useQuery } from "@tanstack/react-query";
import { clientAPI } from "../api/client";

async function fetchAllData() {
  const [city, direction, property, job] = await Promise.all([
    clientAPI("get", `/city?page=1&limit=9999`),
    clientAPI("get", `/direction?page=1&limit=9999`),
    clientAPI("get", `/property?page=1&limit=9999`),
    clientAPI("get", `/job?page=1&limit=9999`),
  ]);

  return {
    city: city?.items?.map(item => ({ 
      id: item._id,
      code: item?.code || '',
      name: item?.name || '',
    })) || [],
    direction: direction?.items?.map(item => ({ 
      id: item._id,
      code: item.code,
      name: item.name ,
    })) || [],
    property: property?.items?.map(item => ({ 
      id: item._id,
      code: item.code,
      name: item.name ,
    })) || [],
    job: job?.items?.map(item => ({ 
      id: item._id,
      code: item.code,
      name: item.name ,
    })) || [],
  };
}

export function useProjectCategoriesOther() {
  const { data, isLoading, isRefetching, error, refetch } = useQuery({
    queryKey: ["allData"],
    queryFn: fetchAllData,
    refetchOnWindowFocus: false
  });

  return {
    city: data?.city || {}, 
    direction: data?.direction || {},
    property: data?.property || {},
    job: data?.job || {},
    isLoading,
    isRefetching,
    error,
    refetch, 
  };
}
