import { Text } from "@chakra-ui/react";

export const formatTableValue = (value, key) => {
  switch (key) {
    case "startDate":
      const timestamp = value;

      const date = new Date(timestamp);
      
      const formattedDate = date.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
      
      return <Text>{formattedDate}</Text>;
    case "rate":
      return <Text>{value}%</Text>;
    default:
      return <Text>{value}</Text>;
  }
};