import toast from "react-hot-toast";
import { formatDateDb, formatTimeDb } from "../../utils/tools";
import {
  customDateFormat,
  formatDate,
  formatDateToLocal,
} from "../../utils/utils";

const { clientAPI } = require("../client");
export const checkinCall = {
  bulkAdd: async (data) => {
    try {
      const response = await clientAPI("post", "/checkin/import/", data);
      return response;
    } catch (error) {
      console.error("Create error:", error);
      const errorMessages = error?.response?.data?.errors?.map(
        (err) => err.message
      ) || [
        error?.response?.data?.message ||
          error?.response?.statusText ||
          error?.message ||
          "An unexpected error occurred.",
      ];

      errorMessages.forEach((message) => {
        toast.error(`${message}`);
      });
      throw error;
    }
  },
  export: async (entries, param) => {
    try {
      const options = {
        limit: entries,
        page: 1,
      };

      if (param.search) options.search = param.search;
      if (param.department) options.department = param.department;
      if (param.branchId) options.branchId = param.branchId;
      if (param.saleBlockId) options.saleBlockId = param.saleBlockId;
      if (param.saleAreaId) options.saleAreaId = param.saleAreaId;
      if (param.supportDepartmentId)
        options.supportDepartmentId = param.supportDepartmentId;
      if (param.startDate) {
        const fromDate = param.startDate ? formatDateToLocal(param.startDate) : "";
        options.fromDate = formatDate(fromDate);
      }
      if (param.endDate) {
        const toDate = param.endDate ? formatDateToLocal(param.endDate) : "";
        options.toDate = formatDate(toDate);
      }
      if (param.checkinTimeSort)
        options.checkinTimeSort = param.checkinTimeSort;
      if (param.fullNameSort) options.fullNameSort = param.fullNameSort;
      if (param.codeSort) options.codeSort = param.codeSort;

      const queryParams = new URLSearchParams(options).toString();
      const response = await clientAPI(
        "get",
        `/checkin/report/list-checkin?${queryParams}`
      );

      const dataExport = response?.items.map((item, index) => {
        return {
          dateTime: item?.checkinTime ? formatDateDb(item?.checkinTime) : "",
          checkinTime: item?.checkinTime ? formatTimeDb(item?.checkinTime) : "",
          code: item?.employeeObject?.code || "",
          fullName: item?.employeeObject?.fullName || "",
          name: item?.checkinPlaceObject?.name || "",
          workingDayRate: item?.workingDayRate || "",
          reason: item?.comment || "",
        };
      });

      return dataExport;
    } catch (error) {
      console.error("get list error:", error);
      const errorMessages = error?.response?.data?.errors?.map(
        (err) => err.message
      ) || [
        error?.response?.data?.message ||
          error?.response?.statusText ||
          error?.message ||
          "An unexpected error occurred.",
      ];

      errorMessages.forEach((message) => {
        toast.error(`${message}`);
      });
      throw error;
    }
  },
  update: async (id, data) => {
    try {
      const response = await clientAPI("put", `/checkin/report/${id}`, data);
      return response;
    } catch (error) {
      console.error("Update error:", error);
      const errorMessages = error?.response?.data?.errors?.map(
        (err) => err.message
      ) || [
        error?.response?.data?.message ||
          error?.response?.statusText ||
          error?.message ||
          "An unexpected error occurred.",
      ];

      errorMessages.forEach((message) => {
        toast.error(`${message}`);
      });
      throw error;
    }
  },
};
