import { useQuery } from "@tanstack/react-query";
import { clientAPI } from "../api/client";

async function fetchAllData() {
  const [customerType] = await Promise.all([
    clientAPI("get", `/customerType?page=1&limit=99999999`),
  ]);

  return {
    customerType: customerType?.items?.map(item => ({ 
      id: item._id,
      code: item.code,
      name: item.name,
    })) || [],
  };
}

export function useProjectCategoriesCustomerType() {
  const { data, isLoading, isRefetching, error, refetch } = useQuery({
    queryKey: ["allData"],
    queryFn: fetchAllData,
    refetchOnWindowFocus: false
  });

  return {
    customerType: data?.customerType || {}, 
    isLoading,
    isRefetching,
    error,
    refetch, 
  };
}
