import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
  HStack,
  Img,
  Button,
  Image,
  MenuItem,
  MenuList,
  MenuButton,
  Menu,
  useDisclosure,
  Skeleton,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { formatTableValue } from "./formatTable";
import chevronLeft from "../../assets/images/employee/chevron-left.svg";
import chevronRight from "../../assets/images/employee/chevron-right.svg";
import useDebounce from "../../hooks/useDebounce";
import ReactPaginate from "react-paginate";
import iconEdit from "../../assets/images/product/edit.svg";
import iconDetail from "../../assets/images/employee/detail.svg";
import icontrash from "../../assets/images/product/trash.svg";
import { AiOutlineMore } from "react-icons/ai";
import DeleteDialog from "../../components/dialog/delete";
import FormNew from "./formCustomers/formNew";
import SearchableSelect from "../../components/selectForm";
import FormInfo from "./formCustomers/formInfo";
import { useConditionalLists, useCustomerList } from "../../hooks/useCustomer";
import SearchBox from "./searchBox";
import toast from "react-hot-toast";
import { customerCall } from "../../api/customer";

const TableCustomers = ({
  tableData,
  search,
  setSearch,
  employeeId,
  setEmployeeId,
  projectId,
  setProjectId,
  totalPages,
  handlePageChange,
  isLoading,
  isRefetching,
  currentPage,
  entries,
  startEntry,
  endEntry,
  refetch,
}) => {
  // table
  const [searchTermEmployee, setSearchTermEmployee] = useState("");
  const debouncedSearchTermEmployee = useDebounce(searchTermEmployee, 500);
  const [searchTermProject, setSearchTermProject] = useState("");
  const debouncedSearchTermProject = useDebounce(searchTermProject, 500);
  const { employeeList, isLoading: employeeIsLoading } = useConditionalLists(
    debouncedSearchTermEmployee,
    { employee: true }
  );
  const { projectList, isLoading: projectIsLoading } = useConditionalLists(
    debouncedSearchTermProject,
    { project: true }
  );
  const formatEntriesForSelect = (entries) =>
    Object.entries(entries || {}).map(([key, value]) => ({
      value: key,
      label: value,
    }));
  const employees = useMemo(() => {
    if (!employeeList) return [];
    return formatEntriesForSelect(employeeList);
  }, [employeeList]);
  const projects = useMemo(() => {
    if (!projectList) return [];
    return formatEntriesForSelect(projectList);
  }, [projectList]);

  const onChangeSearch = useCallback((e) => {
    const { value } = e.target;
    setSearch(value);
  });

  // edit
  const [dataEdit, setDataEdit] = useState(null);
  const handleOpenEditCustomer = (data) => {
    setDataEdit(data);
  };

  const handleCloseEditCustomer = () => {
    setDataEdit(null);
  };

  const onSubmitEdit = async (data) => {
    const toastCreate = toast.loading("Đang sửa khách hàng ...");
    try {
      const { id, ...option } = data;
      await customerCall.update(id, { ...option });
      await refetch();
      setDataEdit(null);
      toast.success("Sửa thành công");
    } catch (error) {
      console.error("Error", error);
      toast.error("Có lỗi xảy ra khi sửa");
    }

    toast.dismiss(toastCreate);
  };

  // del
  const [dataDel, setDataDel] = useState(null);
  const handleOpenDeleteCustomer = (data) => {
    setDataDel(data);
  };

  const handleCloseDeleteCustomer = () => {
    setDataDel(null);
  };
  const onSubmitDelete = async () => {
    const toastEdit = toast.loading("Đang xóa dữ liệu ...");
    try {
      const { id } = dataDel;
      await customerCall.delete(id);
      await refetch();
      setDataDel(null);
      toast.success("Xóa thành công");
    } catch (error) {
      console.error("Error:", error);
      toast.error("Có lỗi xảy ra khi xóa");
    }
    toast.dismiss(toastEdit);
  };

  // detal
  const [dataDetail, setDataDetail] = useState(null);
  const handleOpenDetailCustomer = (data) => {
    setDataDetail(data);
  };

  const handleCloseDetailCustomer = () => {
    setDataDetail(null);
  };

  return (
    <>
      <Flex flexDirection={"column"} gap={{ base: "16px" }}>
        <Flex
          backgroundColor="var(--color-main)"
          borderRadius={{ base: "12px" }}
          flexDirection={"column"}
        >
          <Flex
            alignItems={"center"}
            justifyContent={"space-between"}
            gap={{ base: "32px" }}
            p={"16px 16px 12px 16px"}
          >
            <Flex alignItems={"center"} gap={{ base: "32px" }} flex={"1"}>
              <InputGroup
                maxW="506px"
                borderRadius={{ base: "8px" }}
                w={{ base: "100%" }}
              >
                <Input
                  placeholder="Nhập tên khách hàng, số điện thoại, ..."
                  type="text"
                  borderRadius={{ base: "8px" }}
                  border={{ base: "1px solid var(--color-boder)" }}
                  onChange={onChangeSearch}
                />
                <InputRightElement
                  borderTopRightRadius={"8px"}
                  borderBottomRightRadius={"8px"}
                  pointerEvents="none"
                  backgroundImage="var(--color-button)"
                  cursor={"pointer"}
                >
                  <SearchIcon color="var(--color-main)" />
                </InputRightElement>
              </InputGroup>
              <Flex alignItems={"center"} gap={"24px"}>
                <SearchBox
                  isSearchable={true}
                  placeholder="Chọn dự án"
                  selectedMain={projectId}
                  onChange={(option) => setProjectId(option)}
                  data={projects}
                  isLoading={projectIsLoading}
                  searchTerm={searchTermProject}
                  setSearchTerm={setSearchTermProject}
                />

                <SearchBox
                  isSearchable={true}
                  placeholder="Chọn nhân viên tạo"
                  selectedMain={employeeId}
                  onChange={(option) => setEmployeeId(option)}
                  data={employees}
                  isLoading={employeeIsLoading}
                  searchTerm={searchTermEmployee}
                  setSearchTerm={setSearchTermEmployee}
                />
              </Flex>
            </Flex>
          </Flex>
          <Box width={"100%"} height={"100%"}>
            <TableContainer w={"100%"}>
              <Skeleton isLoaded={!isLoading && !isRefetching}>
                <Table w={"100%"} variant="unstyled">
                  <Thead
                    w={"100%"}
                    h={"41px"}
                    color="white"
                    fontWeight="400"
                    backgroundColor="#F9FAFD"
                  >
                    <Tr h={{ base: "41px" }}>
                      {tableData.headers.map((e, index) => {
                        let width = "auto";
                        let textAlign = "auto";
                        if (e.key === "") {
                          width = "2%";
                          textAlign = "center";
                        } else if (e.key === "contact") {
                          width = "5%";
                          textAlign = "start";
                        } else if (e.key === "status") {
                          width = "10%";
                          textAlign = "center";
                        } else if (e.key === "name") {
                          width = "25%";
                          textAlign = "start";
                        } else if (e.key === "identificationNumber") {
                          width = "10%";
                          textAlign = "start";
                        } else if (e.key === "identificationInfo") {
                          width = "10%";
                          textAlign = "start";
                        } else if (e.key === "currentAddress") {
                          width = "40%";
                          textAlign = "end";
                        } else if (e.key === "project") {
                          width = "20%";
                          textAlign = "end";
                        } else {
                          width = "20%";
                          textAlign = "end";
                        }
                        return (
                          <Td
                            p={"16px 16px 8px 16px"}
                            key={index}
                            border={"none"}
                            color={"#51607B"}
                            fontSize={{ base: "14px" }}
                            w={width}
                          >
                            <Box textAlign={textAlign}>{e.label}</Box>
                          </Td>
                        );
                      })}
                    </Tr>
                  </Thead>
                  <Tbody w={"100%"} h={"100%"}>
                    {tableData.data?.length > 0 ? (
                      tableData.data?.map((e, rowIndex) => {
                        const items = { ...e };
                        delete items.detail;
                        delete items.edit;
                        const keyValues = Object.keys(items);
                        return (
                          <Tr key={rowIndex} h={"72px"}>
                            <Td
                              p={"16px 16px 8px 16px"}
                              w={"2%"}
                              bg={
                                rowIndex % 2 === 0
                                  ? "var(--color-main)"
                                  : "var(--fill-avatar)"
                              }
                              verticalAlign={"top"}
                            >
                              <Menu>
                                <MenuButton
                                  _hover={{ backgroundColor: "none" }}
                                  _active={{ backgroundColor: "none" }}
                                  background="none"
                                  as={Button}
                                  alignItems={"unset"}
                                >
                                  <AiOutlineMore fontSize={"20px"} />
                                </MenuButton>
                                <MenuList
                                  fontSize={{ base: "14px" }}
                                  fontWeight={500}
                                  lineHeight={"140%"}
                                  color={"var(--color-info-employee)"}
                                >
                                  <MenuItem
                                    gap={"16px"}
                                    onClick={() =>
                                      handleOpenDetailCustomer(e?.detail)
                                    }
                                  >
                                    <Image src={iconDetail} /> Xem chi tiết
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() =>
                                      handleOpenEditCustomer(e?.edit)
                                    }
                                    gap={"16px"}
                                  >
                                    <Image src={iconEdit} /> Chỉnh sửa
                                  </MenuItem>
                                  <MenuItem
                                    gap={"16px"}
                                    onClick={() =>
                                      handleOpenDeleteCustomer(e?.edit)
                                    }
                                  >
                                    <Image src={icontrash} /> Xóa khách hàng
                                  </MenuItem>
                                </MenuList>
                              </Menu>
                            </Td>
                            <Td
                              bg={
                                rowIndex % 2 === 0
                                  ? "var(--color-main)"
                                  : "var(--fill-avatar)"
                              }
                              verticalAlign={"top"}
                            >
                              {rowIndex + 1}
                            </Td>
                            {keyValues.map((keyvalue, index) => {
                              let width = "auto";
                              let widthText = "100%";
                              let textAlign = "auto";
                              let whiteSpace = "inherit";
                              if (keyvalue === "") {
                                width = "2%";
                                textAlign = "center";
                              } else if (keyvalue === "contact") {
                                width = "5%";
                                textAlign = "start";
                              } else if (keyvalue === "status") {
                                width = "10%";
                                textAlign = "center";
                              } else if (keyvalue === "name") {
                                width = "25%";
                                textAlign = "start";
                                whiteSpace = "normal";
                                widthText = "118px";
                              } else if (keyvalue === "identificationInfo") {
                                width = "10%";
                                textAlign = "start";
                              } else if (keyvalue === "identificationNumber") {
                                width = "10%";
                                textAlign = "start";
                              } else if (keyvalue === "permanentAddress") {
                                width = "40%";
                                textAlign = "end";
                                whiteSpace = "normal";
                                widthText = "171px";
                              } else if (keyvalue === "currentAddress") {
                                width = "40%";
                                textAlign = "end";
                                whiteSpace = "normal";
                                widthText = "146px";
                              } else if (keyvalue === "project") {
                                width = "20%";
                                textAlign = "end";
                                whiteSpace = "normal";
                                widthText = "143px";
                              } else {
                                width = "20%";
                                textAlign = "end";
                                whiteSpace = "inherit";
                              }
                              return (
                                <Td
                                  p={"16px 16px 8px 16px"}
                                  w={width}
                                  key={index}
                                  bg={
                                    rowIndex % 2 === 0
                                      ? "var(--color-main)"
                                      : "var(--fill-avatar)"
                                  }
                                  verticalAlign={"top"}
                                >
                                  <Box
                                    fontSize={{ base: "14px" }}
                                    lineHeight={"19.6px"}
                                    textAlign={textAlign}
                                    fontWeight={500}
                                    color={"#293755"}
                                    h={
                                      whiteSpace !== "inherit"
                                        ? "39.2px"
                                        : "72px"
                                    }
                                    w={widthText}
                                    whiteSpace={whiteSpace}
                                    overflow="hidden"
                                    textOverflow="ellipsis"
                                    display="-webkit-box"
                                    sx={{
                                      WebkitLineClamp: "2",
                                      WebkitBoxOrient: "vertical",
                                    }}
                                  >
                                    {formatTableValue(
                                      items[keyvalue],
                                      keyvalue
                                    )}
                                  </Box>
                                </Td>
                              );
                            })}
                          </Tr>
                        );
                      })
                    ) : (
                      <Tr>
                        <Td
                          colSpan={tableData.headers.length}
                          textAlign="center"
                          padding={"40px 0"}
                        >
                          Không có dữ liệu
                        </Td>
                      </Tr>
                    )}
                  </Tbody>
                </Table>
              </Skeleton>
            </TableContainer>

            <Box mt={10} mr={5} mb={5} ml={5}>
              <Flex alignItems={"center"} justifyContent={"space-between"}>
                Showing {startEntry} to {endEntry} of {entries} entries
                <HStack spacing={2} justify="flex-end">
                  <ReactPaginate
                    className="pagination-custom"
                    pageCount={totalPages}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={1}
                    onPageChange={handlePageChange}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                    breakClassName={"ellipsis"}
                    breakLabel={"..."}
                    previousLabel={
                      <Img
                        w={"16px"}
                        minW={"16px"}
                        h={"16px"}
                        src={chevronLeft}
                      />
                    }
                    nextLabel={
                      <Img
                        w={"16px"}
                        minW={"16px"}
                        h={"16px"}
                        src={chevronRight}
                      />
                    }
                    renderOnZeroPageCount={null}
                    initialPage={currentPage - 1}
                  />
                </HStack>
              </Flex>
            </Box>
          </Box>
        </Flex>
      </Flex>
      {dataEdit && (
        <FormNew
          isOpen={!!dataEdit}
          onClose={handleCloseEditCustomer}
          onSubmit={onSubmitEdit}
          defaultValues={dataEdit}
          isEdit={true}
        />
      )}
      {dataDel && (
        <DeleteDialog
          isOpen={!!dataDel}
          onClose={handleCloseDeleteCustomer}
          title={"Xóa khách hàng"}
          description={
            <Text display={"inline"}>
              "Bạn có chắc chắn muốn xóa khách hàng{" "}
              <Text fontWeight={"600"} display={"inline"}>
                {dataDel?.name}
              </Text>{" "}
              này không?"
            </Text>
          }
          action={() => onSubmitDelete()}
        />
      )}

      {/* form */}
      {dataDetail && (
        <FormInfo
          isOpen={!!dataDetail}
          onClose={handleCloseDetailCustomer}
          value={dataDetail}
        />
      )}
    </>
  );
};

export default TableCustomers;
