import React from 'react';
import { Box, Flex, Grid, GridItem, Text } from '@chakra-ui/react';
import FormFeature from './form/formFeature';
import { useOnLeaveCategories } from '../../hooks/useOnLeaveCategories';
// import FormFeature from './form/formFeature';

const OrganizationalStructure = () => {
  const {
    holiday,
    leaveType,
    // businessCategory,
    guaranteeType,
    refetch,
    isLoading,
    isRefetching,
  } = useOnLeaveCategories();
 
  const holidayCategory = {
    headers: [
      {
        label: "",
        key: "",
      },
      {
        label: "STT",
        key: "",
      },
      {
        label: "Phân loại",
        key: "name",
      },
      {
        label: "Số ngày nghỉ",
        key: "numberDay",
      },
      {
        label: "Ngày bắt đầu nghỉ",
        key: "startDate",
      },
    ],
    data: holiday
  };

  // const tableBusinessCategory = {
  //   headers: [
  //     {
  //       label: "",
  //       key: "",
  //     },
  //     {
  //       label: "STT",
  //       key: "",
  //     },
  //     {
  //       label: "Loại nhân viên kinh doanh",
  //       key: "name",
  //     },
  //     {
  //       label: "Tỷ lệ ưu đãi",
  //       key: "rate",
  //     },
  //   ],
  //   data: businessCategory
  // };

  const tableLeaveType = {
    headers: [
      {
        label: "",
        key: "",
      },
      {
        label: "STT",
        key: "",
      },
      {
        label: "Phân loại",
        key: "type",
      },
      {
        label: "Tổng số ngày",
        key: "totalDaysAllocated",
      },
      {
        label: "Nghỉ tối đa/tháng",
        key: "daysAllocated",
      },
    ],
    data: leaveType,
  };

  const tableGuaranteeType = {
    headers: [
      {
        label: "",
        key: "",
      },
      {
        label: "STT",
        key: "",
      },
      {
        label: "Tên phân loại",
        key: "name",
      },
    ],
    data: guaranteeType,
  };

return (
    <Box
      minH="calc(100vh - 80px)"
      w={{ base: "100%" }}
      backgroundColor="var(--color-backgroundmain)"
      p={{ base: "24px 16px 16px 15px" }}
    >
      <Flex alignItems={"center"} justifyContent={"space-between"}>
        <Flex alignItems={"center"} gap={{ base: "74px" }}>
          <Text fontSize={{ base: "20px" }} fontWeight={600}>
          Danh mục công&nghỉ phép
          </Text>
        </Flex>
      </Flex>
      <Box mt={{ base: "23px" }}>
        <Grid
            minH={"80vh"}
            templateColumns="36.88% 36.88% 24.24%"
            // templateColumns="50% 50%"
            templateRows='repeat(3, 1fr)'
            // templateRows='repeat(1, 1fr)'
            gap={4}
            >
            <GridItem rowSpan={1} colSpan={1} p={"16px"} bg={"var(--color-main)"} borderRadius={"16px"}>
                <FormFeature 
                    title="Cài đặt các ngày nghỉ lễ" 
                    button="Thêm ngày nghỉ lễ" 
                    data={holidayCategory}
                    type={0}
                    isLoading={isLoading} 
                    isRefetching={isRefetching}
                    refetch={refetch}
                    textDelete="ngày nghỉ lễ"
                    textAdd="Thêm ngày nghỉ lễ mới"
                    textButtonAdd="Tạo ngày nghỉ lễ"
                    textTitleEdit="Sửa ngày nghỉ lễ mới"
                />
            </GridItem>
            {/* <GridItem rowSpan={2} colSpan={1} p={"16px"} bg={"var(--color-main)"} borderRadius={"16px"}> */}
            <GridItem rowSpan={1} colSpan={1} p={"16px"} bg={"var(--color-main)"} borderRadius={"16px"}>
                <FormFeature 
                   title="Phân loại nghỉ phép" 
                   button="Thêm PL nghỉ phép" 
                   isLoading={isLoading} 
                   isRefetching={isRefetching}
                   refetch={refetch}
                   data={tableLeaveType}
                   type={1}
                   formTable={true}
                   textDelete="phân loại nghỉ phép"
                   textAdd="Phân loại nghỉ phép mới"
                   textButtonAdd="Tạo NP mới"
                   textTitleEdit="Chỉnh sửa phân loại nghỉ phép"
                   showButton={false}
                />
            </GridItem>
            {/* <GridItem rowSpan={2} colSpan={1} p={"16px"} bg={"var(--color-main)"} borderRadius={"16px"}> */}
            {/* <GridItem p={"16px"} bg={"var(--color-main)"} borderRadius={"16px"}> */}
                {/* <FormFeature 
                    title="Phân loại NVKD" 
                    button="Thêm phân loại NVKD" 
                    data={tableBusinessCategory}
                    type={2}
                    isLoading={isLoading}
                    isRefetching={isRefetching}
                    refetch={refetch}
                    formTable={true}
                    textDelete="Phân loại nhân viên kinh doanh"
                    textAdd="Phân loại nhân viên kinh doanh mới"
                    textButtonAdd="Tạo phân loại nhân viên kinh doanh"
                    textTitleEdit="Chỉnh sửa phân loại nhân viên kinh doanh"
                />
            </GridItem> */}
            {/* <GridItem p={"16px"} bg={"var(--color-main)"} borderRadius={"16px"}>
                <FormFeature 
                    title="Cài đặt công-NVHT kinh doanh" 
                    button="Thêm PL công" 
                    data={tableDataDepartment}
                    type={3}
                    textDelete="phân loại công mới - NV kinh doanh"
                    textAdd="Phân loại công mới - NV kinh doanh"
                    textButtonAdd="Phân loại công mới - NVHT kinh doanh"
                    textTitleEdit="Chỉnh sửa phân loại công mới - NVHT kinh doanh"
                />
            </GridItem> */}
            <GridItem p={"16px"} bg={"var(--color-main)"} borderRadius={"16px"}>
                <FormFeature 
                  title="Phân loại bảo lãnh" 
                  button="Thêm PL bảo lãnh" 
                  data={tableGuaranteeType}
                  type={4}
                  isLoading={isLoading}
                  isRefetching={isRefetching}
                  refetch={refetch}
                  textDelete="phân loại bảo lãnh"
                  textAdd="Phân loại bảo lãnh mới"
                  textButtonAdd="Tạo PL bảo lãnh"
                  textTitleEdit="Chỉnh sửa phân loại bảo lãnh mới"
                />
            </GridItem>
        </Grid>
      </Box>
    </Box>
    
)
}

export default OrganizationalStructure