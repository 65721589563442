import {
    Box,
    Flex,
    Input,
    Text,
    Menu,
    MenuButton,
    Button,
    MenuList,
    Spinner,
    MenuItem,
  } from "@chakra-ui/react";
  import React, { useEffect, useState } from "react";
  import { ChevronDownIcon } from "@chakra-ui/icons";
  import useDebounce from "../../../hooks/useDebounce";
  import { CiSearch } from "react-icons/ci";
  import { IoCheckmarkOutline } from "react-icons/io5";
  import { useSearchEmployee } from "../../../hooks/useSearchEmployee";
  
  export default function SearchBox({
    label,
    placeholder,
    selectedMain,
    onChange,
    errorMessage,
    registerProps,
    isSearchable,
    onFocus,
    onBlur,
    state,
    bdTop,
    mwidth,
    initialSelectedEmp,
  }) {
    const [searchTerm, setSearchTerm] = useState("");
    const [zIndex, setZIndex] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedOption, setSelectedOption] = useState(initialSelectedEmp); // Track the selected option
  
    const debouncedSearchTerm = useDebounce(searchTerm, 300);
  
    const { employeeData, isLoading } = useSearchEmployee(debouncedSearchTerm);
  
    useEffect(() => {
      setLoading(isLoading);
    }, [isLoading]);
  
    useEffect(() => {
      // Set initial selected option if initialSelectedEmp is provided
      if (initialSelectedEmp && !selectedMain) {
        setSelectedOption(initialSelectedEmp);
        onChange(initialSelectedEmp.value); // Set the initial value for the parent
      }
    }, [initialSelectedEmp, selectedMain, onChange]);
  
    const onChangeAction = (value) => {
      const selected = employeeData.find((option) => option.value === value);
      setSelectedOption(selected); // Update the selected option locally
      onChange(value); // Update the parent component
    };
  
    const handleFocusBlur = (type, callback) => {
      setZIndex(type);
      if (callback) {
        callback();
      }
    };
  
    return (
      <Box
        w={"100%"}
        borderRadius={bdTop ? "0px" : "12px"}
        maxW={mwidth ? "100%" : "259px"}
        display={"flex"}
        flexDirection={"column"}
        gap={bdTop ? "8px" : "0px"}
        h={bdTop ? "auto" : "max-content"}
        zIndex={zIndex ? 3 : 1}
      >
        {label && (
          <Text
            fontSize={{ base: "12px" }}
            fontWeight={400}
            lineHeight={"15px"}
            mb={bdTop ? "0" : "6px"}
            color={
              state
                ? "var(--color-option-employee-hover)"
                : bdTop
                ? "var(--color-black-label)"
                : "var(--color-label-employee)"
            }
          >
            {label}
          </Text>
        )}
  
        <Menu matchWidth>
          <MenuButton
            as={Button}
            _active={{
              border: bdTop
                ? "none"
                : "1px solid var(--color-option-employee-hover)",
              borderBottom: bdTop
                ? "1px solid var(--color-boder)"
                : "1px solid var(--color-option-employee-hover)",
            }}
            _hover={{
              boxShadow: "none",
            }}
            rightIcon={<ChevronDownIcon color="#000" />}
            w="100%"
            backgroundColor="#FFF"
            border={bdTop ? "none" : "1px solid var(--bg-line-employee)"}
            fontSize="14px"
            fontWeight={500}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            textAlign={"left"}
            p={bdTop ? "0" : "16px"}
            h={bdTop ? "22px" : "auto"}
            maxH={"44px"}
            padding={bdTop ? "4px 0" : "13px 12px 14px 12px"}
            color={
              selectedOption
                ? "var(--color-info-employee)"
                : bdTop
                ? "var(--text-gray-form)"
                : "var(--color-placeHolder-employee)"
            }
            {...registerProps}
            borderRadius={bdTop ? "0px" : "8px"}
            borderBottom={
              bdTop
                ? "1px solid var(--color-boder)"
                : "1px solid var(--bg-line-employee)"
            }
            onFocus={() => handleFocusBlur(true, onFocus)}
            onBlur={() => handleFocusBlur(false, onBlur)}
          >
            {selectedOption ? selectedOption.label : placeholder}
          </MenuButton>
  
          <MenuList
            borderRadius="12px"
            minW="275px"
            onFocus={() => handleFocusBlur(true, onFocus)}
            onBlur={() => handleFocusBlur(false, onBlur)}
          >
            {isSearchable && (
              <Flex
                alignItems="center"
                gap="12px"
                padding="0px 12px 0px 12px"
                borderBottom="1px solid var(--bg-line-employee)"
              >
                <CiSearch color="var(--Text-color-Disable)" fontSize="20px" />
                <Input
                  placeholder="Tìm kiếm..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  fontSize="14px"
                  fontWeight={500}
                  padding={0}
                  border="none"
                  _focus={{ boxShadow: "none" }}
                  _placeholder={{ color: "var(--color-placeHolder-employee)" }}
                />
                {loading && <Spinner size="sm" />}
              </Flex>
            )}
  
            {!loading && employeeData?.length > 0 ? (
              employeeData.map((option) => (
                <MenuItem
                  key={option.value}
                  onClick={() => onChangeAction(option.value)}
                  backgroundColor={
                    selectedOption && selectedOption.value === option.value
                      ? "#F9FAFD"
                      : "#FFF"
                  }
                  color={
                    selectedOption && selectedOption.value === option.value
                      ? "#1A9ED0"
                      : "#000"
                  }
                >
                  {option.label}
                  {selectedOption && selectedOption.value === option.value && (
                    <IoCheckmarkOutline style={{ marginLeft: "auto" }} />
                  )}
                </MenuItem>
              ))
            ) : (
              <Box fontSize="14px" p="12px" textAlign="center">
                Không tìm thấy kết quả
              </Box>
            )}
          </MenuList>
        </Menu>
  
        {(!selectedOption || selectedOption.value === "") && errorMessage && (
          <Text fontSize="12px" color="red" lineHeight={"15px"}>
            {errorMessage.message}
          </Text>
        )}
      </Box>
    );
  }
  