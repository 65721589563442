import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { clientAPI } from "../api/client";
import { NumberOfPerPage } from "../utils/globalVariable";
import { formatDateForInput, formatDate } from "../utils/utils";
import { formatDateDb } from "../utils/tools";

const NUMBER_NFT_PER_PAGE = NumberOfPerPage.onLeave;
const queryKey = "CustomerList";
const jobQueryKey = "JobList";
const projectQueryKey = "ProjectList";
const customerTypeQueryKey = "CustomerTypeList";

// //TODO: Job List
// async function fetchJobList(search) {
//   const searchOptions = {
//     limit: 5,
//     page: 1,
//   };
//   if (search !== undefined) searchOptions.search = search;
//   const searchQueryParams = new URLSearchParams(searchOptions).toString();

//   // Fetch constant data concurrently
//   const data = await clientAPI("get", `/job?${searchQueryParams}`);

//   // Index data by 'id' to improve lookup performance
//   return data?.items?.reduce(
//     (acc, item) => ({ ...acc, [item._id]: item.name }),
//     {}
//   );
// }

// export function useJobList(search) {
//   // Fetch constant data once
//   const { data, isLoading, isFetching, refetch } = useQuery({
//     queryKey: [jobQueryKey, search],
//     queryFn: () => fetchJobList(),
//     refetchOnWindowFocus: false,
//     staleTime: 30000,
//     keepPreviousData: true,
//     onError: (error) => {
//       console.error("Error fetching job list data:", error);
//     },
//   });

//   return {
//     data,
//     isLoading,
//     isFetching,
//     refetch,
//   };
// }

// //TODO: project List
// async function fetchProjectList(search) {
//   const searchOptions = {
//     limit: 5,
//     page: 1,
//     status: "OPEN",
//   };
//   if (search !== undefined) searchOptions.search = search;
//   const searchQueryParams = new URLSearchParams(searchOptions).toString();

//   // Fetch constant data concurrently
//   const data = await clientAPI("get", `/project?${searchQueryParams}`);

//   // Index data by 'id' to improve lookup performance
//   return data?.items?.reduce(
//     (acc, item) => ({ ...acc, [item._id]: item.name }),
//     {}
//   );
// }

// export function useProjectList(search) {
//   // Fetch constant data once
//   const { data, isLoading, isFetching, refetch } = useQuery({
//     queryKey: [projectQueryKey, search],
//     queryFn: () => fetchProjectList(),
//     refetchOnWindowFocus: false,
//     staleTime: 30000,
//     keepPreviousData: true,
//     onError: (error) => {
//       console.error("Error fetching job list data:", error);
//     },
//   });

//   return {
//     data,
//     isLoading,
//     isFetching,
//     refetch,
//   };
// }

// //TODO: customer type List
// async function fetchCustomerTypeList() {
//   const searchOptions = {
//     limit: 10,
//     page: 1,
//   };
//   const searchQueryParams = new URLSearchParams(searchOptions).toString();

//   // Fetch constant data concurrently
//   const data = await clientAPI("get", `/customerType?${searchQueryParams}`);

//   // Index data by 'id' to improve lookup performance
//   return data?.items?.reduce(
//     (acc, item) => ({ ...acc, [item._id]: item.name }),
//     {}
//   );
// }

// export function useCustomerTypeList() {
//   // Fetch constant data once
//   const { data, isLoading, isFetching, refetch } = useQuery({
//     queryKey: [customerTypeQueryKey],
//     queryFn: () => fetchCustomerTypeList(),
//     refetchOnWindowFocus: false,
//     staleTime: 30000,
//     keepPreviousData: true,
//     onError: (error) => {
//       console.error("Error fetching job list data:", error);
//     },
//   });

//   return {
//     data,
//     isLoading,
//     isFetching,
//     refetch,
//   };
// }

// TODO: list
async function fetchAllData(
  search,
  fetchOptions = {
    job: false,
    employee: false,
    project: false,
    customerType: false,
  }
) {
  const searchOptions = {
    limit: 5,
    page: 1,
  };

  if (search !== undefined) searchOptions.search = search;

  const promises = [];

  if (fetchOptions.job) {
    const options = {
      ...searchOptions,
    };
    const searchQueryParams = new URLSearchParams(options).toString();
    promises.push(
      clientAPI("get", `/job?${searchQueryParams}`).then((data) =>
        data?.items?.reduce(
          (acc, item) => ({ ...acc, [item._id]: item.name }),
          {}
        )
      )
    );
  } else {
    promises.push(Promise.resolve(null));
  }

  if (fetchOptions.employee) {
    const options = {
      ...searchOptions,
    };
    const searchQueryParams = new URLSearchParams(options).toString();
    promises.push(
      clientAPI("get", `/employee/search?${searchQueryParams}`).then((data) =>
        data?.items?.reduce(
          (acc, item) => ({ ...acc, [item._id]: item.fullName }),
          {}
        )
      )
    );
  } else {
    promises.push(Promise.resolve(null));
  }

  if (fetchOptions.project) {
    const options = {
      ...searchOptions,
    };
    options.status = "OPEN";
    const searchQueryParams = new URLSearchParams(options).toString();
    promises.push(
      clientAPI("get", `/project?${searchQueryParams}`).then((data) =>
        data?.items?.reduce(
          (acc, item) => ({ ...acc, [item._id]: item.name }),
          {}
        )
      )
    );
  } else {
    promises.push(Promise.resolve(null));
  }

  if (fetchOptions.customerType) {
    const options = {
      limit: 100,
      page: 1,
    };
    const searchQueryParams = new URLSearchParams(options).toString();
    promises.push(
      clientAPI("get", `/customerType?${searchQueryParams}`).then((data) =>
        data?.items?.reduce(
          (acc, item) => ({ ...acc, [item._id]: item.name }),
          {}
        )
      )
    );
  } else {
    promises.push(Promise.resolve(null));
  }

  const [jobList, employeeList, projectList, customerTypeList] =
    await Promise.all(promises);

  return {
    jobList,
    employeeList,
    projectList,
    customerTypeList,
  };
}

export function useConditionalLists(search, fetchOptions) {
  const { data, isLoading, isFetching, refetch } = useQuery({
    queryKey: ["conditionalLists", search, fetchOptions],
    queryFn: () => fetchAllData(search, fetchOptions),
    refetchOnWindowFocus: false,
    staleTime: 30000,
    keepPreviousData: true,
    onError: (error) => {
      console.error("Error fetching conditional lists data:", error);
    },
  });

  return {
    jobList: data?.jobList,
    employeeList: data?.employeeList,
    projectList: data?.projectList,
    customerTypeList: data?.customerTypeList,
    isLoading,
    isFetching,
    refetch,
  };
}

//TODO: customer List
const initData = {
  data: [],
  totalpages: 0,
  entries: 0,
  startEntry: 0,
  endEntry: 0,
  exportData: [],
};
async function fetchCustomerList(currentPage, search, wishlist, employeeId) {
  try {
    const options = {
      limit: NUMBER_NFT_PER_PAGE,
      page: currentPage,
    };

    if (search !== undefined) options.search = search;
    if (wishlist) options.wishlist = wishlist;
    if (employeeId) options.employeeId = employeeId;

    const queryParams = new URLSearchParams(options).toString();
    let data = await clientAPI("get", `/customer/?${queryParams}`);
    const totalPages = Math.ceil(data?.pagination?.total / NUMBER_NFT_PER_PAGE);
    const entries = data?.pagination?.total;
    const startEntry =
      (data?.pagination?.page - 1) * data?.pagination?.limit + 1;
    const endEntry = Math.min(
      data?.pagination?.page * data?.pagination?.limit,
      entries
    );

    const newData = data?.items.map((item, index) => {
      return {
        name: item?.name || "-",
        contact: {
          phone: item?.phone || "-",
          email: item?.email || "-",
        },
        identificationNumber: item?.identificationNumber || "-",
        identificationInfo: {
          dateOfIdentification: item?.dateOfIdentification || "-",
          placeOfIdentification: item?.placeOfIdentification || "-",
        },
        permanentAddress: item?.permanentAddress || "-",
        currentAddress: item?.currentAddress || "-",
        career: item?.job?.name || "-",
        project: item?.wishlistDetail
          ? item?.wishlistDetail?.map((item) => item?.name)
          : [],
        type: item?.customerType?.name || "-",
        employee: item?.employee?.fullName || "-",
        dateCreate: item?.createdAt ? formatDateDb(item?.createdAt) : "-",
        edit: {
          ...(item?._id && { id: item._id }),
          ...(item?.name && { name: item.name }),
          ...(item?.phone && { phone: item.phone }),
          ...(item?.email && { email: item.email }),
          ...(item?.identificationNumber && {
            identificationNumber: item.identificationNumber,
          }),
          ...(item?.dateOfIdentification && {
            dateOfIdentification: formatDateForInput(
              formatDate(item.dateOfIdentification)
            ),
          }),
          ...(item?.placeOfIdentification && {
            placeOfIdentification: item.placeOfIdentification,
          }),
          ...(item?.permanentAddress && {
            permanentAddress: item.permanentAddress,
          }),
          ...(item?.currentAddress && { currentAddress: item.currentAddress }),
          ...(item?.jobId && {
            jobId: item.jobId,
          }),
          ...(item?.customerTypeId && { customerTypeId: item.customerTypeId }),
          ...(item?.wishlist && { wishlist: item.wishlist }),
        },
        detail: {
          ...(item?._id && { id: item._id }),
          ...(item?.name && { name: item.name }),
          ...(item?.phone && { phone: item.phone }),
          ...(item?.email && { email: item.email }),
          ...(item?.identificationNumber && {
            identificationNumber: item.identificationNumber,
          }),
          ...(item?.dateOfIdentification && {
            dateOfIdentification: item.dateOfIdentification,
          }),
          ...(item?.placeOfIdentification && {
            placeOfIdentification: item.placeOfIdentification,
          }),
          ...(item?.permanentAddress && {
            permanentAddress: item.permanentAddress,
          }),
          ...(item?.currentAddress && { currentAddress: item.currentAddress }),
          ...(item?.job && {
            job: item.job?.name,
          }),
          ...(item?.customerType && { customerType: item.customerType?.name }),
          ...(item?.wishlistDetail && { wishlistDetail: item.wishlistDetail }),
        },
      };
    });

    const exportData = data?.items.map((item, index) => {
      return {
        name: item?.name || "-",
        phone: item?.phone || "-",
        email: item?.email || "-",
        identificationNumber: item?.identificationNumber || "-",
        dateOfIdentification: item?.dateOfIdentification || "-",
        placeOfIdentification: item?.placeOfIdentification || "-",
        permanentAddress: item?.permanentAddress || "-",
        currentAddress: item?.currentAddress || "-",
        career: item?.job?.name || "-",
        project: item?.wishlistDetail
          ? item?.wishlistDetail
              ?.map((item) => item?.name)
              .filter(Boolean)
              .join(", ")
          : "-",
        type: item?.customerType?.name || "-",
        employee: item?.employee?.fullName || "-",
        dateCreate: item?.createdAt ? formatDateDb(item?.createdAt) : "-",
      };
    });
    return {
      data: newData,
      totalPages,
      entries,
      startEntry,
      endEntry,
      exportData,
    };
  } catch (error) {
    console.error("Error fetching onLeave data:", error);
    return initData;
  }
}

export function useCustomerList(search, wishlist, employeeId) {
  const [currentPage, setCurrentPage] = useState(1);
  const { data, refetch, isLoading, isRefetching } = useQuery({
    queryKey: [queryKey, currentPage, search, wishlist, employeeId],
    queryFn: () => fetchCustomerList(currentPage, search, wishlist, employeeId),
    refetchOnWindowFocus: false,
    staleTime: 30000,
    keepPreviousData: true,
    onError: (error) => {
      console.error("Error fetching customer list data:", error);
    },
  });

  const nextPage = () => {
    if (currentPage < data?.totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return {
    customerListData: data?.data || initData.data,
    customerListExport: data?.exportData || initData.exportData,
    totalPages: data?.totalPages || initData.totalpages,
    refetch,
    isLoading,
    isRefetching,
    prevPage,
    nextPage,
    setCurrentPage,
    currentPage,
    entries: data?.entries || initData.entries,
    startEntry: data?.startEntry || initData.startEntry,
    endEntry: data?.endEntry || initData.endEntry,
  };
}
