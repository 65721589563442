import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  Image,
  Button,
} from "@chakra-ui/react";
import CloseIconBlack from "../../../assets/images/booking/x-circle.svg";
import {
  InputControl,
  SelectControl,
} from "../../../components/form/formControls";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { isEndDateGreater } from "../../../utils/utils";

const schema = Yup.object().shape({
  nameBooking: Yup.string().required("Tên căn hộ là bắt buộc"),
  priceBooking: Yup.string().required("Dữ liệu là bắt buộc"),
  dateStart: Yup.string().required("Vui lòng nhập ngày bắt đầu"),
  dateEnd: Yup.string()
    .required("Vui lòng nhập ngày kết thúc")
    .test(
      "is-end-greater",
      "Ngày kết thúc phải lớn hơn ngày bắt đầu",
      function (value) {
        const { dateStart } = this.parent;
        return isEndDateGreater(dateStart, value);
      }
    ),
});

const AddBooking = ({
  isOpen,
  onClose,
  defaultValues,
  isEdit,
  onSubmit,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: defaultValues || {},
  });

  const typeApartments = [
    {
      label: "a",
      value: "0",
    },
    {
      label: "b",
      value: "1",
    },
  ];

  useEffect(() => {
    if (isOpen) {
      reset(defaultValues);
    }
  }, [isOpen, defaultValues, reset]);

  const [focusStates, setFocusStates] = useState({});
  const [inputColors, setInputColors] = useState({
    dateStart: "var(--text-gray-form)",
    dateEnd: "var(--text-gray-form)",
  });

  const handleFocus = (field) => {
    setFocusStates((prev) => ({ ...prev, [field]: true }));
  };

  const handleBlur = (field) => {
    setFocusStates((prev) => ({ ...prev, [field]: false }));
  };

  const onchangeProject = (option) => {
    setValue("project", option);
  };

  const handleDateChange = (e, inputName) => {
    const selectedDate = e.target.value;

    setInputColors((prevColors) => ({
      ...prevColors,
      [inputName]: selectedDate
        ? "var(--color-info-employee)"
        : "var(--color-text-unclick)",
    }));
  };

  const submitHandler = (data) => {
    onSubmit(data);
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        backgroundColor="var(--color-main)"
        borderRadius={"16px"}
        h={"625px"}
        w={"100%"}
        maxW={"625px"}
        overflowY={"auto"}
        sx={{
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-thumb": {
            width: "4px",
            borderRadius: "50px",
            background: "var(--color-background)",
          },
          "&::-moz-scrollbar": {
            width: "4px",
          },
          "&::-moz-scrollbar-thumb": {
            width: "4px",
            borderRadius: "50px",
            background: "var(--color-background)",
          },
        }}
      >
        <form onSubmit={handleSubmit(submitHandler)}>
          <Flex minH={"625px"} flexDirection={"column"}>
            <Flex
              justifyContent={"space-between"}
              alignItems={"center"}
              gap={"10px"}
              padding={"25px 24px"}
              borderBottom={"1px solid var(--bg-line-employee)"}
            >
              <Text
                color="var(--color-header-employee)"
                fontSize={"18px"}
                fontWeight={"600"}
              >
                {isEdit
                  ? "Chỉnh sửa đợt nhận booking"
                  : "Tạo đợt nhận booking mới"}
              </Text>
              <Box cursor={"pointer"} onClick={onClose}>
                <Image src={CloseIconBlack} />
              </Box>
            </Flex>
            <Box flex={1} w={"545px"} m={"24px auto"}>
              <Flex
                justifyContent={"space-between"}
                alignItems={"flex-start"}
                gap={"27px"}
                mb={"16px"}
              >
                <InputControl
                  label="Tên đợt nhận booking"
                  placeholder="Nhập tên đợt nhận booking"
                  errors={errors.nameBooking}
                  register={register("nameBooking")}
                  onFocus={() => handleFocus("nameBooking")}
                  onBlur={() => handleBlur("nameBooking")}
                  state={focusStates["nameBooking"]}
                  mwidth={"100%"}
                />
              </Flex>
              <Flex
                justifyContent={"space-between"}
                alignItems={"flex-start"}
                gap={"27px"}
                mb={"16px"}
              >
                <SelectControl
                  label="Dự án"
                  dataOption={typeApartments}
                  placeholder="Chọn dự án"
                  selectedMain={watch("project")}
                  onChange={onchangeProject}
                  onFocus={() => handleFocus("project")}
                  onBlur={() => handleBlur("project")}
                  state={focusStates["project"]}
                />
                <InputControl
                  label="Số tiền booking"
                  placeholder="Ví dụ: 1 tỷ 950 triệu "
                  errors={errors.priceBooking}
                  register={register("priceBooking")}
                  onFocus={() => handleFocus("priceBooking")}
                  onBlur={() => handleBlur("priceBooking")}
                  state={focusStates["priceBooking"]}
                  mwidth={"100%"}
                />
              </Flex>
              <Flex
                justifyContent={"space-between"}
                alignItems={"flex-start"}
                gap={"27px"}
                mb={"16px"}
              >
                <InputControl
                  label={"Từ ngày"}
                  type="date"
                  colorIp={inputColors.dateStart}
                  state={focusStates["dateStart"]}
                  onFocus={() => handleFocus("dateStart")}
                  onBlur={() => handleBlur("dateStart")}
                  onChange={(e) => handleDateChange(e, "dateStart")}
                  valueMain={watch("dateStart")}
                  errors={errors?.dateStart}
                  register={register("dateStart")}
                />
                <InputControl
                  label={"đến ngày"}
                  type="date"
                  colorIp={inputColors.dateEnd}
                  state={focusStates["dateEnd"]}
                  onFocus={() => handleFocus("dateEnd")}
                  onBlur={() => handleBlur("dateEnd")}
                  onChange={(e) => handleDateChange(e, "dateEnd")}
                  valueMain={watch("dateEnd")}
                  errors={errors?.dateEnd}
                  register={register("dateEnd")}
                />
              </Flex>
            </Box>
            <Flex p={"24px"} justifyContent={"flex-end"}>
              {isEdit ? (
                <Flex gap={"16px"}>
                  <Button
                    p={"13px 16px 13px 20px"}
                    fontWeight={600}
                    fontSize={"15px"}
                    lineHeight={"18px"}
                    color={"var(--color-option-employee-hover)"}
                    border={"1px solid var(--color-option-employee-hover)"}
                    bg={"var(--color-main)"}
                    onClick={onClose}
                    _hover={{
                      filter: "brightness(90%)",
                    }}
                  >
                    Hủy
                  </Button>
                  <Button
                    type="submit"
                    p={"13px 16px 13px 20px"}
                    fontWeight={600}
                    fontSize={"15px"}
                    lineHeight={"18px"}
                    color={"var(--color-main)"}
                    bg={"var(--color-button)"}
                    _hover={{
                      filter: "brightness(90%)",
                    }}
                  >
                    Lưu
                  </Button>
                </Flex>
              ) : (
                <Button
                  type="submit"
                  p={"13px 16px 13px 20px"}
                  fontWeight={600}
                  fontSize={"15px"}
                  lineHeight={"18px"}
                  color={"var(--color-main)"}
                  bg={"var(--color-button)"}
                  _hover={{
                    filter: "brightness(90%)",
                  }}
                >
                  {defaultValues ? "Sửa đợt Booking" : "Thêm đợt nhận booking"}
                </Button>
              )}
            </Flex>
          </Flex>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default AddBooking;
