import React, { useCallback, useState } from "react";
import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
  HStack,
  Img,
  Button,
  Image,
  MenuItem,
  MenuList,
  MenuButton,
  Menu,
  useDisclosure,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { formatTableValue } from "./formatTable";
import chevronLeft from "../../assets/images/employee/chevron-left.svg";
import chevronRight from "../../assets/images/employee/chevron-right.svg";
import useDebounce from "../../hooks/useDebounce";
import ReactPaginate from "react-paginate";
import calendarIcon from "../../assets/images/onleave/calendar.svg";
import iconEdit from "../../assets/images/product/edit.svg";
import iconAdd from "../../assets/images/booking/plus-square.svg";
import icontrash from "../../assets/images/product/trash.svg";
import { AiOutlineMore } from "react-icons/ai";
import DeleteDialog from "../../components/dialog/delete";
import { useForm } from "react-hook-form";
import AddBooking from "./formBooking/addBooking";
import AddProduct from "./formBooking/addProduct";
import SearchableSelect from "../../components/selectForm";
import DatePicker from "react-datepicker";

const TableApartments = ({ tableData }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      project: "",
      typeApartment: "",
    },
  });
  // table
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [search, setSearch] = useState("");

  const [popupDelete, setPopupDelete] = useState(false);
  const [isAddBooking, setIsAddBooking] = useState(false);
  const [project, setProject] = useState({});

  const debouncedSearchTerm = useDebounce(search, 500);
  const onChangeSearch = useCallback((e) => {
    const { value } = e.target;
    setSearch(value);
  });

  const saleAreas = [
    { key: "0", value: "Đang làm việc" },
    { key: "1", value: "Dừng làm việc" },
  ];

  const handlePopupDelete = () => {
    setPopupDelete(true);
    onOpen();
  };

  const handlePopup = (type) => {
    setIsAddBooking(type);
    setPopupDelete(false);
    onOpen();
  };

  return (
    <>
      <Flex flexDirection={"column"} gap={{ base: "16px" }}>
        <Flex
          backgroundColor="var(--color-main)"
          borderRadius={{ base: "12px" }}
          flexDirection={"column"}
        >
          <Flex
            alignItems={"center"}
            justifyContent={"space-between"}
            gap={{ base: "32px" }}
            p={"16px 16px 12px 16px"}
          >
            <Flex
              alignItems={"center"}
              gap={{ base: "32px" }}
              flex={"1"}
              flexDirection={{ base: "column", xl: "row" }}
            >
              <InputGroup
                maxW="506px"
                borderRadius={{ base: "8px" }}
                w={{ base: "100%" }}
              >
                <Input
                  placeholder="Nhập Mã ĐNBK,tên,..."
                  type="text"
                  borderRadius={{ base: "8px" }}
                  border={{ base: "1px solid var(--color-boder)" }}
                  onChange={onChangeSearch}
                />
                <InputRightElement
                  borderTopRightRadius={"8px"}
                  borderBottomRightRadius={"8px"}
                  pointerEvents="none"
                  bg="var(--color-boder)"
                  cursor={"pointer"}
                >
                  <SearchIcon color="var(--color-black)" />
                </InputRightElement>
              </InputGroup>
              <Flex alignItems={"center"} gap={{ base: "32px", lg: "24px" }}>
              <InputGroup w={"100%"} maxW={"255px"}>
                  <DatePicker
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(update) => {
                      setDateRange(update);
                    }}
                    isClearable={true}
                    placeholderText="Từ ngày - Đến ngày"
                    className="datePickerStyle"
                  />
                  {!startDate && !endDate && (
                    <InputRightElement pointerEvents="none" h={"44px"}>
                      <Image src={calendarIcon} />
                    </InputRightElement>
                  )}
                </InputGroup>
                <SearchableSelect
                  isShowSearch={false}
                  options={saleAreas}
                  initialSelectedOption={{ key: 0, value: "Chọn dự án" }}
                  setValue={setProject}
                />
              </Flex>
            </Flex>
          </Flex>
          <Box width={"100%"} height={"100%"}>
            <TableContainer w={"100%"}>
              <Table w={"100%"} variant="unstyled">
                <Thead
                  w={"100%"}
                  h={"41px"}
                  color="white"
                  fontWeight="400"
                  backgroundColor="#F9FAFD"
                >
                  <Tr h={{ base: "41px" }}>
                    {tableData.headers.map((e, index) => {
                      let width = "auto";
                      let textAlign = "auto";
                      if (e.key === "") {
                        width = "2%";
                        textAlign = "center";
                      } else if (e.key === "code") {
                        width = "5%";
                        textAlign = "start";
                      } else if (e.key === "nameBooking") {
                        width = "10%";
                        textAlign = "start";
                      } else if (e.key === "nameProject") {
                        width = "25%";
                        textAlign = "start";
                      } else if (e.key === "product") {
                        width = "10%";
                        textAlign = "start";
                      } else {
                        width = "20%";
                        textAlign = "end";
                      }
                      return (
                        <Td
                          p={"16px 16px 8px 16px"}
                          key={index}
                          border={"none"}
                          color={"#51607B"}
                          fontSize={{ base: "14px" }}
                          w={width}
                        >
                          <Box textAlign={textAlign}>{e.label}</Box>
                        </Td>
                      );
                    })}
                  </Tr>
                </Thead>
                <Tbody w={"100%"} h={"100%"}>
                  {tableData.data?.length > 0 ? (
                    tableData.data?.map((e, rowIndex) => {
                      const keyValues = Object.keys(e);
                      return (
                        <Tr key={rowIndex} h={"72px"}>
                          <Td p={"16px 16px 8px 16px"} w={"2%"}>
                            <Menu>
                              <MenuButton
                                _hover={{ backgroundColor: "none" }}
                                _active={{ backgroundColor: "none" }}
                                background="none"
                                as={Button}
                              >
                                <AiOutlineMore fontSize={"20px"} />
                              </MenuButton>
                              <MenuList
                                fontSize={{ base: "14px" }}
                                fontWeight={500}
                                lineHeight={"140%"}
                                color={"var(--color-info-employee)"}
                              >
                                <MenuItem
                                  gap={"16px"}
                                  onClick={() => handlePopup(true)}
                                >
                                  <Image src={iconEdit} /> Chỉnh sửa
                                </MenuItem>
                                <MenuItem
                                  gap={"16px"}
                                  onClick={() => handlePopup(false)}
                                >
                                  <Image src={iconAdd} /> Thêm sản phẩm
                                </MenuItem>
                                <MenuItem
                                  gap={"16px"}
                                  onClick={handlePopupDelete}
                                >
                                  <Image src={icontrash} /> Xóa ĐN booking
                                </MenuItem>
                              </MenuList>
                            </Menu>
                          </Td>
                          {keyValues.map((keyvalue, index) => {
                            let width = "auto";
                            let widthText = "100%";
                            let textAlign = "auto";
                            let whiteSpace = "inherit";
                            if (keyvalue === "") {
                              width = "2%";
                              textAlign = "center";
                            } else if (keyvalue === "code") {
                              width = "5%";
                              textAlign = "start";
                            } else if (keyvalue === "nameBooking") {
                              width = "10%";
                              textAlign = "start";
                            } else if (keyvalue === "nameProject") {
                              width = "25%";
                              textAlign = "start";
                              whiteSpace = "normal";
                              widthText = "118px";
                            } else if (keyvalue === "product") {
                              width = "10%";
                              textAlign = "start";
                            } else {
                              width = "20%";
                              textAlign = "end";
                              whiteSpace = "inherit";
                            }
                            return (
                              <Td
                                p={"16px 16px 8px 16px"}
                                w={width}
                                key={index}
                              >
                                <Box
                                  fontSize={{ base: "14px" }}
                                  lineHeight={"19.6px"}
                                  textAlign={textAlign}
                                  fontWeight={500}
                                  color={"var(--color-info-employee)"}
                                  w={widthText}
                                  whiteSpace={whiteSpace}
                                  overflow="hidden"
                                  textOverflow="ellipsis"
                                  display="-webkit-box"
                                  sx={{
                                    WebkitLineClamp: "2",
                                    WebkitBoxOrient: "vertical",
                                  }}
                                >
                                  {formatTableValue(e[keyvalue], keyvalue)}
                                </Box>
                              </Td>
                            );
                          })}
                        </Tr>
                      );
                    })
                  ) : (
                    <Tr>
                      <Td
                        colSpan={tableData.headers.length}
                        textAlign="center"
                        padding={"40px 0"}
                      >
                        Không có dữ liệu
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </TableContainer>

            <Box mt={10} mr={5} mb={5} ml={5}>
              <Flex alignItems={"center"} justifyContent={"space-between"}>
                <Text>Showing 1 to 10 of 12 entries</Text>
                <HStack spacing={2} justify="flex-end">
                  <ReactPaginate
                    className="pagination-custom"
                    pageCount={8}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={1}
                    onPageChange={8}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                    breakClassName={"ellipsis"}
                    breakLabel={"..."}
                    previousLabel={
                      <Img
                        w={"16px"}
                        minW={"16px"}
                        h={"16px"}
                        src={chevronLeft}
                      />
                    }
                    nextLabel={
                      <Img
                        w={"16px"}
                        minW={"16px"}
                        h={"16px"}
                        src={chevronRight}
                      />
                    }
                    renderOnZeroPageCount={null}
                    initialPage={8 - 1}
                  />
                </HStack>
              </Flex>
            </Box>
          </Box>
        </Flex>
      </Flex>
      {!popupDelete ? (
        isAddBooking ? (
          <AddBooking
            isOpen={isOpen}
            onClose={onClose}
            isEdit={true}
            defaultValues={{
              nameBooking: "1",
              dateStart: "22/09/2024",
              dateEnd: "22/09/2024",
              priceBooking: "1000",
            }}
            onSubmit={() => {
              console.log("dat");
            }}
          />
        ) : (
          <AddProduct
            isOpen={isOpen}
            onClose={onClose}
            isEdit={true}
            onSubmit={() => {
              console.log("dat");
            }}
          />
        )
      ) : (
        <DeleteDialog
          isOpen={isOpen}
          onClose={onClose}
          action={() => {
            console.log("oke");
          }}
          title="Xóa sản phẩm và căn hộ"
          description={
            <Text display={"inline"}>
              "Bạn có chắc chắn muốn xóa đợt nhận booking{" "}
              <Text fontWeight={"600"} display={"inline"}>
                Đợt nhận booking 1
              </Text>{" "}
              này không?"
            </Text>
          }
        />
      )}
    </>
  );
};

export default TableApartments;
